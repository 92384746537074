import React, { useState, useRef, useMemo } from 'react';

import { Box, Image, ModalBody as ChakraModalBody } from '@chakra-ui/react';
import { useSize, useThrottleFn } from 'ahooks';
import { AnimatePresence, motion } from 'framer-motion';

const ModalBody: React.FC<{
  children: React.ReactNode;
  showFooter?: boolean;
  scrollBehavior?: 'outside' | 'inside';
  bottomOffset?: string;
}> = ({
  showFooter,
  children,
  scrollBehavior = 'inside',
  bottomOffset = showFooter ? '74px' : '0px',
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [showScrollTip, setShowScrollTip] = useState(true);
  const contentRef = useRef(null);
  const contentSize = useSize(contentRef);

  const bodyRef = useRef(null);
  const bodySize = useSize(bodyRef);

  // Determine if the current container is scrollable
  const isScroll = useMemo(() => {
    if (
      contentSize?.height &&
      bodySize?.height &&
      contentSize!.height > bodySize!.height + 20
    ) {
      return true;
    }
    return false;
  }, [contentSize?.height, bodySize?.height]);

  const { run: handleScroll } = useThrottleFn(
    (e: any) => {
      if (isScroll) {
        // Triggered when scrolling to 28px from the bottom
        if (
          e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight <=
          28
        ) {
          setShowScrollTip(false);
          // Triggered when scrolling up
        } else if (e.target.scrollTop < scrollTop) {
          setShowScrollTip(true);
        }
      }
      setScrollTop(e.target.scrollTop);
    },
    { wait: 200, leading: true }
  );

  return (
    <>
      <ChakraModalBody
        maxHeight={scrollBehavior === 'inside' ? '596px' : 'auto'}
        p="24px"
        ref={bodyRef}
        onScroll={handleScroll}
      >
        <Box ref={contentRef}>{children}</Box>
      </ChakraModalBody>
      <AnimatePresence>
        {isScroll && showScrollTip && (
          <motion.div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: bottomOffset,
              left: '0',
              backgroundImage: isScroll
                ? 'linear-gradient(to bottom,rgba(255,255,255,0.6),rgba(255,255,255,1))'
                : '',
              width: '100%',
            }}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10, background: 'none' }}
            transition={{ duration: 0.2 }}
          >
            <Image src="/icons/scroll-tip.svg" />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default ModalBody;
