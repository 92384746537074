import { useMemo } from 'react';

import { useRouter } from 'next/router';
import _ from 'lodash';

import useAccess from 'hooks/useAccess';
import route, { RouteProps } from 'helper/route';

const useRouteGuard = () => {
  const router = useRouter();
  const { pathname } = router;
  const { accessData, isLoading } = useAccess();

  //   Flattening the routing object
  const flatRoute = (routeMap: RouteProps): RouteProps =>
    _.flatMapDeep(routeMap, (item) => [item, ...flatRoute(item.child || [])]);

  const isAllow = useMemo(() => {
    const routeAccess = flatRoute(route).find(
      (item) => item.path === pathname
    )?.access;

    return routeAccess
      ? routeAccess?.find((item) => {
          if (_.get(accessData, item)) {
            return true;
          }
          return false;
        })
      : true;
  }, [accessData, pathname, route]);
  return { isLoading, isAllow };
};

export default useRouteGuard;
