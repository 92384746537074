import { memo } from 'react';

import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Flex,
} from '@chakra-ui/react';

import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type CheckboxProps = DefaultProps & ChakraCheckboxProps;
const Checkbox = ({ children, ...rest }: CheckboxProps) => {
  const { name, boxOption } = rest;
  const { formField, isInvalid, formLabel, restProps, customFormLabel } =
    useFormItem({
      ...rest,
      labelOption: {
        m: 0,
      },
    });

  return (
    <Flex mb="32px" align="center" {...boxOption}>
      <ChakraCheckbox
        id={name}
        colorScheme="A7Black"
        isInvalid={isInvalid}
        {...formField}
        {...restProps}
      >
        {customFormLabel || <Flex>{formLabel}</Flex>}
      </ChakraCheckbox>
    </Flex>
  );
};
export default memo(Checkbox);
