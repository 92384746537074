import { Flex, Box, Divider } from '@chakra-ui/react';

const Page403: React.FC = () => (
  <Flex
    mt="35vh"
    h="full"
    w="full"
    align="center"
    justify="center"
    className="page-403"
  >
    <Box fontWeight="bold" fontSize="2xl">
      403
    </Box>
    <Divider
      orientation="vertical"
      h="50px"
      mx="16px"
      borderColor="blackAlpha.400"
    />
    <Box fontWeight="medium">Access Denied</Box>
  </Flex>
);
export default Page403;
