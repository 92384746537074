const LockIcon = ({
  width = 16,
  height = 16,
  color = 'black',
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="lock-icon"
  >
    <path
      d="M12.667 6.666h.666a.667.667 0 0 1 .667.667V14a.666.666 0 0 1-.667.666H2.667A.667.667 0 0 1 2 14V7.333a.667.667 0 0 1 .667-.667h.666V6a4.667 4.667 0 0 1 9.334 0v.666zm-1.334 0V6a3.333 3.333 0 0 0-6.666 0v.666h6.666zm-4 2.667V12h1.334V9.333H7.333z"
      fill={`var(--chakra-colors-${color})`}
    />
  </svg>
);
export default LockIcon;
