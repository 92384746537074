import { Box, BoxProps } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

export const Highlight: React.FC<
  {
    query: string;
    children: string;
  } & BoxProps
> = ({ query, children, ...rest }) => {
  const escapedQuery = _.escapeRegExp(query);
  const parts = children.split(new RegExp(`(${escapedQuery})`, 'gi'));

  return (
    <Box as="span" {...rest} noOfLines={1}>
      {parts.map((part, i) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <Box as="span" color="A7Blue.500" textDecor="underline" key={i}>
            {part}
          </Box>
        ) : (
          <React.Fragment key={i}>{part}</React.Fragment>
        )
      )}
    </Box>
  );
};
