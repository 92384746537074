import { Box, Flex, Text } from '@chakra-ui/react';
import { memo } from 'react';

const TableEmpty: React.FC<{ description: string }> = ({
  description = '',
}) => (
  <Box height="full">
    <Flex
      height="full"
      width="100%"
      backgroundImage="/table-no-data.svg"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      alignItems="center"
      justifyContent="center"
    >
      <Text lineHeight="6" fontWeight="medium" fontSize="xl" color="A7Gray.600">
        {description}
      </Text>
    </Flex>
  </Box>
);

export default memo(TableEmpty);
