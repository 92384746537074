import { DeepPartial, Theme } from '@chakra-ui/react';

const Button: DeepPartial<Theme['components']['Button']> = {
  baseStyle: {
    borderRadius: '2px',
  },
  sizes: {
    sm: {
      fontWeight: '500',
      fontSize: '12px',
    },
    md: {
      fontWeight: '500',
      fontSize: '16px',
    },
  },
};

export default Button;
