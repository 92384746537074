import Text from './Text';
import Number from './Number';
import Select from './Select';
import MultiSelect from './MultiSelect';
import NMultiSelect from './NMultiSelect';
import Textarea from './Textarea';
import Checkbox from './Checkbox';
import Password from './Password';
import LabelCreate from './LabelCreate';
import CodeEditor from './CodeEditor';
import Switch from './Switch';
import Radio from './Radio';

const A7FormItem = {
  Number,
  Select,
  MultiSelect,
  Text,
  Textarea,
  Checkbox,
  Password,
  LabelCreate,
  CodeEditor,
  Switch,
  Radio,
  NMultiSelect,
};

export default A7FormItem;
