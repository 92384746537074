import React, { ReactNode } from 'react';

import { Flex, Text, Box, FlexProps } from '@chakra-ui/react';

type A7HeaderProps = { title: string; description?: ReactNode } & FlexProps;

const Header: React.FC<A7HeaderProps> = ({
  title,
  description = '',
  ...rest
}) => (
  <Flex flexDirection="column" py="20px" {...rest}>
    <Text
      color="#0B0D10"
      fontSize={rest?.fontSize ? 'unset' : { base: '20px', sm: '24px' }}
      lineHeight="8"
      fontWeight={rest?.fontWeight || 'medium'}
    >
      {title}
    </Text>
    {description && <Box mt="10px">{description}</Box>}
  </Flex>
);

export default Header;
