const getUserNameOrEmail = (userData?: {
  first_name?: string;
  last_name?: string;
  email: string;
}) => {
  if (userData) {
    if (userData?.first_name || userData?.last_name) {
      return `${userData?.first_name} ${userData?.last_name}`;
    }
    return userData?.email;
  }
  return '';
};
export default getUserNameOrEmail;
