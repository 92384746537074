import { ComponentProps, memo } from 'react';

import { Box } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import A7MultiSelect, { Options } from '../api7-multi-select';
import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type SelectProps = DefaultProps &
  Omit<ComponentProps<typeof A7MultiSelect>, 'onChange'> & {
    isClearable?: boolean;
    minW?: string;
    customOnChange?: (e: Options) => void;
    defaultValue?: Exclude<Options, null>['value'];
  };

const Select = ({ children, ...rest }: SelectProps) => {
  const { control } = useFormContext();

  const { name, isDisabled, isClearable, option, defaultValue, boxOption } =
    rest;
  const { formHelperText, formLabel, restProps } =
    useFormItem<SelectProps>(rest);

  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value = null } }) => (
          <A7MultiSelect
            name={name}
            placeholder=""
            isDisabled={isDisabled}
            isClearable={isClearable}
            boxOption={{
              mb: 'auto',
              minW: '100px',
            }}
            {...restProps}
            option={option}
            value={value?.map((item: string) => ({
              label: option.find((optionItem) => optionItem?.value === item)
                ?.label,
              value: item,
            }))}
            onChange={(e: { label: string; value: string }[]) => {
              onChange(e.map((item) => item.value));
            }}
          />
        )}
      />
      {formHelperText}
    </Box>
  );
};

export default memo(Select);
