import { useState } from 'react';

import {
  Button,
  Center,
  Box,
  Input,
  FormLabel,
  FormHelperText,
  FormControl,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { initializeOrg } from 'helper/service';

const REQUIRED_RULE = {
  required: { value: true, message: 'This field is required' },
};

type FormProps = {
  name: string;
};

const InitializeOrg: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>();

  const onConfirm = (data: FormProps) => {
    setLoading(true);
    initializeOrg(data.name)
      .then(() => {
        setLoading(false);
        router.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Center width="full" height="80vh" className="init-org">
      <Box
        width="528px"
        boxShadow="0px 0px 3px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.06)"
        height="226px"
        borderRadius="4px"
        padding="20px"
      >
        <form onSubmit={handleSubmit(onConfirm)}>
          <Text colorScheme="A7Gray.700" fontWeight="medium" fontSize="lg">
            Create your first organization before you proceed
          </Text>
          <Box mt="20px" height="93px">
            <FormControl>
              <FormLabel>Organization Name</FormLabel>
              <Input
                mt="8px"
                h="32px"
                borderRadius="2px"
                {...register('name', {
                  ...REQUIRED_RULE,
                  maxLength: {
                    value: 32,
                    message: 'The max length is 32',
                  },
                })}
                isInvalid={Boolean(errors.name)}
                width="full"
              />
              {errors.name && (
                <FormHelperText color="#E53E3E">
                  {errors.name.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          <Flex justifyContent="flex-end">
            <Button
              type="submit"
              fontWeight="medium"
              lineHeight="6"
              width="85px"
              height="40px"
              colorScheme="A7Black"
              borderRadius="2px"
              isLoading={loading}
            >
              Create
            </Button>
          </Flex>
        </form>
      </Box>
    </Center>
  );
};

export default InitializeOrg;
