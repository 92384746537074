import { useState, useEffect } from 'react';

import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useMultiStyleConfig,
  Image,
} from '@chakra-ui/react';
import {
  endOfMonth,
  format,
  getDay,
  isToday,
  startOfMonth,
  isBefore,
} from 'date-fns';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import { useLilius } from 'use-lilius';
import moment from 'moment';

type CalendarProps = {
  value: string;
  onlyFuture?: boolean;
  onChange: (value: string) => void;
};

export const Calendar: React.FC<CalendarProps> = ({
  value,
  onChange,
  onlyFuture = false,
}) => {
  const {
    calendar,
    inRange,
    isSelected,
    selected,
    setViewing,
    setSelected,
    toggle,
    viewing,
    viewNextMonth,
    viewPreviousMonth,
  } = useLilius();

  const styles = useMultiStyleConfig('Calendar', {});

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onChange(selected.length > 0 ? format(selected[0], 'MM/dd/yyyy') : value);
    setViewing(
      selected.length > 0 ? selected[0] : moment(value || undefined).toDate()
    );
  }, [onChange, selected, setViewing]);

  useEffect(() => {
    if (value) {
      setSelected([moment(value || undefined).toDate()]);
    }
  }, []);

  return (
    <Box id="calendar">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <InputGroup>
            <Input
              height="32px"
              borderRadius="2px"
              onClick={() => setIsOpen(true)}
              placeholder="Select a Date"
              value={value}
            />

            <InputRightElement height="32px">
              <IconButton
                aria-label="Open Calendar"
                colorScheme={isOpen ? 'black' : 'gray'}
                icon={<Image src="/icons/calendar.svg" />}
                minWidth="auto"
                onClick={() => setIsOpen(!isOpen)}
                variant="link"
                _focus={{
                  outline: 'none',
                }}
              />
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>

        <PopoverContent sx={styles.popContent}>
          <PopoverBody sx={styles.popBody}>
            <Box sx={styles.navigationContainer}>
              <IconButton
                aria-label="Previous Month"
                icon={<IoChevronBackSharp />}
                onClick={viewPreviousMonth}
                size="sm"
                sx={styles.navigationButton}
              />

              <Text sx={styles.navigationLabel}>
                {format(viewing, 'MMMM yyyy')}
              </Text>

              <IconButton
                aria-label="Next Month"
                icon={<IoChevronForwardSharp />}
                onClick={viewNextMonth}
                size="sm"
                sx={styles.navigationButton}
              />
            </Box>

            <Box sx={styles.calendarContainer}>
              <Box sx={styles.dayLabelContainer}>
                {calendar[0][0].map((day) => (
                  <Box key={`${day}`} sx={styles.dayLabel}>
                    {['S', 'M', 'T', 'W', 'T', 'F', 'S'][getDay(day)]}
                  </Box>
                ))}
              </Box>

              {calendar[0].map((week) => (
                <Box
                  key={`week-${week[0]}`}
                  sx={styles.calendarMatrixContainer}
                >
                  {week.map((day) => (
                    <Box
                      as="button"
                      data-in-range={inRange(
                        day,
                        startOfMonth(viewing),
                        endOfMonth(viewing)
                      )}
                      data-selected={isSelected(day)}
                      data-isTody={isToday(day)}
                      key={`${day}`}
                      onClick={() => {
                        toggle(day, true);
                        setIsOpen(false);
                      }}
                      sx={styles.calendarMatrixDay}
                      disabled={onlyFuture && isBefore(day, new Date())}
                    >
                      <Text>{format(day, 'dd')}</Text>
                      {isToday(day) && <Text sx={styles.todaySymbol} />}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default Calendar;
