import { ClusterStatus } from '@/helper/constant';
import { ClusterData } from '@/types/Cluster';
import axios from 'axios';
import useSWR, { SWRConfiguration } from 'swr';

import useUser from './useUser';

const useCluster = (option?: SWRConfiguration) => {
  const { orgId, isLoading: isUserLoading } = useUser(option);

  const {
    data: clusterData,
    error,
    isLoading,
  } = useSWR<ListRes<ClusterData>>(
    orgId ? `/orgs/${orgId}/clusters` : null,
    (url: string) => axios.get(url).then((res) => res.data.payload),
    option
  );

  const clusterId = clusterData?.list[0].id;
  const clusterStatus = clusterData?.list[0].status;

  return {
    clusterId,
    clusterStatus,
    data: clusterData,
    isClusterReady:
      !!clusterId && clusterStatus === ClusterStatus.ClusterNormal,
    isLoading: isLoading || isUserLoading,
    isError: error,
  };
};

export default useCluster;
