import React, { useContext } from 'react';

import { TooltipRenderProps } from 'react-joyride';
import { Box, Flex, Text, Button, BoxProps } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import _ from 'lodash';

import { TourContext } from 'hooks/useTour';

// Need to hide action buttons in some steps
// [focusIndex, stepIndex]
const hideActionBarList = [
  [1, 2],
  [2, 1],
  [3, 1],
  [3, 4],
  [4, 1],
  [4, 2],
  [4, 4],
  [6, 1],
  [6, 2],
  [6, 4],
];

const Tooltip: React.FC<TooltipRenderProps> = ({
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}) => {
  const { stepIndex, focusIndex } = useContext(TourContext);

  return (
    <Box
      {...tooltipProps}
      {...(step.styles?.tooltip as BoxProps)}
      bgColor="white"
      p="20px"
      data-cy="tour-modal"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="bold" fontSize="22px" color="A7Gray.900">
          {step.title}
        </Text>
        <Box {...closeProps} cursor="pointer">
          <CloseIcon />
        </Box>
      </Flex>
      <Box w="full" mt="16px">
        <Text fontWeight="medium" lineHeight="5" color="A7Gray.900">
          {step.content}
        </Text>
      </Box>
      <Flex mt="24px">
        {index > 0 && (
          <Button
            {...backProps}
            w="98px"
            h="40px"
            bgColor="#ffffff"
            color="A7Gray.800"
            border="1px solid #F0F0F0"
            mr="16px"
            _hover={{ bgColor: '#ffffff', color: 'A7Gray.800' }}
          >
            Previous
          </Button>
        )}
        {!hideActionBarList.find((item) =>
          _.isEqual(item, [focusIndex, stepIndex])
        ) && (
          <>
            {index === 0 && size !== 1 && (
              <Button
                {...closeProps}
                bgColor="#ffffff"
                w="76px"
                h="40px"
                border="1px solid #F0F0F0"
                color="A7Gray.800"
                mr="16px"
                _hover={{ bgColor: '#ffffff', color: 'A7Gray.800' }}
              >
                Close
              </Button>
            )}
            {index === size - 1 ? (
              <Button
                {...primaryProps}
                border="1px solid #F0F0F0"
                color="A7Gray.800"
                bgColor="#ffffff"
                w="68px"
                h="40px"
                borderRadius="2px"
                _hover={{ bgColor: '#ffffff', color: 'A7Gray.800' }}
              >
                Close
              </Button>
            ) : (
              <Button
                bgColor="A7Indigo.500"
                color="#ffffff"
                w="68px"
                h="40px"
                borderRadius="2px"
                onClick={primaryProps.onClick}
                _hover={{ bgColor: 'A7Indigo.500', color: '#ffffff' }}
              >
                {index === 0 ? 'Start' : 'Next'}
              </Button>
            )}
          </>
        )}
      </Flex>
      {size > 1 && (
        <Flex mt="16px" w="full" justifyContent="center">
          {new Array(size).fill('').map((v, index) => (
            <Box
              key={index}
              w="8px"
              h="8px"
              borderRadius="50%"
              bgColor={index === stepIndex ? 'A7Indigo.500' : '#D9D9D9'}
              mr="5px"
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default Tooltip;
