import useSafePush from '@/hooks/useSafePush';
import { Box, Link as ChakraLink, LinkProps } from '@chakra-ui/react';

type A7LinkProps = {
  href: string;
} & LinkProps;

const A7Link: React.FC<A7LinkProps> = ({ href, children, ...rest }) => {
  const { safePush } = useSafePush();
  return (
    <Box
      display="inline-block"
      onClick={() => {
        safePush(href);
      }}
    >
      <ChakraLink color="A7Blue.600" {...rest}>
        {children}
      </ChakraLink>
    </Box>
  );
};

export default A7Link;
