import createInfiniteHook from '@/helper/utils/createInfiniteHook';
import useCluster from '@/hooks/useCluster';
import { CertificateResponses } from '@/types/Certificate';
import { useMemo } from 'react';

const useSSLInfinite = createInfiniteHook<CertificateResponses>(() => {
  const { clusterId, isClusterReady } = useCluster();

  return useMemo(
    () => (isClusterReady ? `/clusters/${clusterId}/certificates` : null),
    [isClusterReady]
  );
});

export default useSSLInfinite;
