import { memo, useState } from 'react';

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  Input,
  InputRightElement,
  Box,
  InputProps,
} from '@chakra-ui/react';

type A7ViewPassWordProps = InputProps & {
  text: string;
};

const A7InputPassword: React.FC<A7ViewPassWordProps> = ({ text, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <InputGroup size="md" justifyContent="flex-start">
      <Input
        h="22px"
        border="none"
        borderRadius="none"
        p="0"
        type={show ? 'text' : 'password'}
        isReadOnly
        value={text}
        cursor="text"
        overflowX="scroll"
        {...rest}
      />
      <InputRightElement
        h="22px"
        onClick={() => {
          setShow((prevShow) => !prevShow);
        }}
      >
        <Box cursor="pointer">{show ? <ViewOffIcon /> : <ViewIcon />}</Box>
      </InputRightElement>
    </InputGroup>
  );
};

export default memo(A7InputPassword);
