const LeftArrowIcon = ({
  width = 16,
  height = 16,
  color = 'black',
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 5 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75575 7.35392C4.75572 7.50452 4.71007 7.65175 4.62454 7.77711C4.539 7.90246 4.41739 8.00035 4.275 8.05847C4.13261 8.11659 3.97579 8.13234 3.82425 8.10375C3.67271 8.07517 3.53321 8.00351 3.42329 7.89779L0.291754 4.88514C0.143943 4.74291 0.0600815 4.54923 0.0586134 4.34668C0.0571453 4.14413 0.13819 3.9493 0.283925 3.80505L3.41546 0.706851C3.48719 0.633573 3.57315 0.574953 3.66833 0.534406C3.7635 0.493858 3.866 0.472193 3.96984 0.470672C4.07369 0.469151 4.1768 0.487806 4.27319 0.525548C4.36957 0.56329 4.45729 0.619364 4.53125 0.690508C4.6052 0.761652 4.66391 0.846442 4.70395 0.939939C4.74399 1.03344 4.76457 1.13377 4.76448 1.2351C4.76439 1.33643 4.74363 1.43674 4.70343 1.53017C4.66322 1.6236 4.60436 1.70829 4.53028 1.7793L1.94833 4.33517L4.52245 6.81159C4.59642 6.88225 4.65517 6.96663 4.69523 7.05977C4.7353 7.1529 4.75587 7.25292 4.75575 7.35392Z"
      fill={`var(--chakra-colors-${color})`}
    />
  </svg>
);

export default LeftArrowIcon;
