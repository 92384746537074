// This hook provides the status and functions related to
// storing the horizontal collapse of the side menu bar

import { createContext } from 'react';

import { useBoolean } from 'ahooks';
import { Flex, Tooltip } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import {
  LeftArrowIcon,
  RightArrowIcon,
} from 'components/api7-components/api7-icon';

export const MenuContext = createContext<{
  isMenuCollapse: boolean;
}>({ isMenuCollapse: false });

const useMenuCollapse = () => {
  const [isMenuCollapse, { toggle: toggleMenuCollapse }] = useBoolean(false);

  const [isMenuCollapseBtnHover, { toggle: toggleMenuCollapseBtnHover }] =
    useBoolean(false);
  const [
    isMenuCollapseBtnShow,
    { setTrue: showMenuCollapseBtn, setFalse: hideMenuCollapseBtn },
  ] = useBoolean(false);

  const menuIcon = (
    <AnimatePresence>
      {isMenuCollapseBtnShow && (
        <motion.div
          style={{
            position: 'absolute',
            top: '58px',
            right: '-10px',
            cursor: 'pointer',
            zIndex: '1',
          }}
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.6 }}
          transition={{ duration: 0.2 }}
        >
          <Tooltip
            label={isMenuCollapse ? 'Expand' : 'Collapse'}
            hasArrow
            placement="right"
          >
            <Flex
              id={`menu-${isMenuCollapse ? 'expand' : 'collapse'}-btn`}
              align="center"
              justify="center"
              w="20px"
              h="20px"
              bgColor={isMenuCollapseBtnHover ? 'white' : 'black'}
              border={isMenuCollapseBtnHover ? 'black solid 1px' : undefined}
              borderRadius="50%"
              onClick={() => toggleMenuCollapse()}
              onMouseEnter={() => toggleMenuCollapseBtnHover()}
              onMouseLeave={() => {
                toggleMenuCollapseBtnHover();
              }}
            >
              {isMenuCollapse ? (
                <RightArrowIcon
                  color={isMenuCollapseBtnHover ? 'black' : 'white'}
                  width={10}
                  height={10}
                />
              ) : (
                <LeftArrowIcon
                  color={isMenuCollapseBtnHover ? 'black' : 'white'}
                  width={10}
                  height={10}
                />
              )}
            </Flex>
          </Tooltip>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return {
    isMenuCollapse,
    isMenuCollapseBtnShow,
    toggleMenuCollapse,
    showMenuCollapseBtn,
    hideMenuCollapseBtn,
    menuIcon,
  };
};

export default useMenuCollapse;
