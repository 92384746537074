import React from 'react';

import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Image, useClipboard, Flex } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

type Props = {
  code: string;
  language?: string;
  copyCode?: string;
  width?: string;
};

const CodeBlock: React.FC<Props> = ({
  language = 'shell',
  code = '',
  copyCode = '',
  width = '680px',
}) => {
  const { hasCopied, onCopy } = useClipboard(copyCode || code);

  if (code === '') return null;

  return (
    <Flex>
      <Box width={width} p="0">
        <SyntaxHighlighter
          language={language}
          wrapLines
          showLineNumbers
          customStyle={{ backgroundColor: '#F4F4F4', fontSize: '12px' }}
        >
          {code}
        </SyntaxHighlighter>
      </Box>
      <Box backgroundColor="#F4F4F4" m="6px 0 6px 0">
        <Button onClick={onCopy} padding="0" backgroundColor="#F4F4F4">
          {hasCopied ? <CheckIcon /> : <Image src="/icons/copy.svg" w="14px" />}
        </Button>
      </Box>
    </Flex>
  );
};

export default CodeBlock;
