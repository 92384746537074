/* eslint-disable */
/* tslint:disable */
import { setupWorker, rest } from 'msw';
import { API_BASE_URL } from 'helper/config';

let i = 0;
const next = () => {
  if (i === Number.MAX_SAFE_INTEGER - 1) {
    i = 0;
  }
  return i++;
};

export const handlers = [
  rest.get(`${API_BASE_URL}/clusters/:id/apps`, (req, res, ctx) => {
    const resultArray = [[ctx.status(200), ctx.json([])]];
    return res(...resultArray[next() % resultArray.length]);
  }),
];

// This configures a Service Worker with the given request handlers.
export const startWorker = () => {
  if (typeof window === 'undefined') {
    const { setupServer } = require('msw/node');
    const server = setupServer(...handlers);
    server.listen();
  } else {
    const worker = setupWorker(...handlers);
    worker.start();
  }
};
