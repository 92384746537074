export const SEARCH_PAGE_SIZE = 5;
export const MAX_RECENT_SEARCH_COUNT = 10;
export const QUERY_PARAM = 'query' as const;
export const RESOURCE_PARAM = 'resource' as const;
export const RESOURCE_LIST = [
  'All',
  'Gateway Instances',
  'Services',
  'Routes',
  'Consumers',
  'SSL',
  'Log Collection Plans',
  'Setting',
] as const;
