import { Dispatch, memo, SetStateAction, useCallback } from 'react';

import { Flex, Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { RouteProps } from 'helper/route';
import useRouterChange from '@/hooks/useSafePush';

type SideMenuChildItemProps = {
  menuList: RouteProps;
  isHidden: (item: RouteProps[number]) => string | true | undefined;
  setSelectedMenu: Dispatch<SetStateAction<string | undefined>>;
  preventTooltipFocus: () => void;
  isMenuCollapse: boolean;
};
const SideMenuChildItem: React.FC<SideMenuChildItemProps> = ({
  menuList,
  isHidden,
  setSelectedMenu,
  preventTooltipFocus,
  isMenuCollapse,
}) => {
  const { pathname } = useRouter();
  const { safePush } = useRouterChange();

  const isSelected = useCallback(
    (path: string) => pathname.indexOf(path) !== -1,
    [pathname]
  );

  return (
    <Flex direction="column">
      {menuList?.map(
        (item) =>
          isHidden(item) && (
            <Box
              {...(isMenuCollapse ? {} : { pl: '46px', pr: '24px' })}
              _hover={{ bg: isSelected(item.path) ? '' : 'A7Gray.100' }}
              bgColor={isSelected(item.path) ? 'A7Gray.100' : ''}
              onClick={(event) => {
                event.stopPropagation();
                safePush(item.path);
                setSelectedMenu(undefined);
                preventTooltipFocus();
              }}
              key={item.path}
            >
              <Box
                className="left-menu-child-item"
                color={isSelected(item.path) ? 'A7Gray.800' : 'A7Gray.600'}
                height="36px"
                fontSize="sm"
                lineHeight="5"
                cursor="pointer"
                whiteSpace="nowrap"
                borderRadius="4px"
                padding="8px 12px"
              >
                {item.name}
              </Box>
            </Box>
          )
      )}
    </Flex>
  );
};

export default memo(SideMenuChildItem);
