export enum ClusterStatus {
  ClusterBuildInProgress = 1,
  ClusterCreating = 2,
  ClusterNormal = 3,
  ClusterCreateFailed = 4,
  ClusterDeleting = 5,
  ClusterDeleted = 6,
}

// eslint-disable-next-line no-useless-escape
export const HOST_AND_PORT_REGEX = /\*?[0-9a-zA-Z-._\[\]:]:[0-9]+$/;
