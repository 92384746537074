import { useContext } from 'react';

import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Skeleton from 'react-loading-skeleton';

import { BreadcrumbContext } from 'hooks/useBreadcrumb';

const Breadcrumb = () => {
  const router = useRouter();
  const { breadcrumb } = useContext(BreadcrumbContext);

  return breadcrumb.length > 1 ? (
    <ChakraBreadcrumb
      w="full"
      fontSize="sm"
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
      position="sticky"
      top="72px"
      p="16px"
      bg="A7Gray.50"
      zIndex="2"
    >
      {breadcrumb.map(({ name, path }, index) => (
        <BreadcrumbItem key={path}>
          {name ? (
            <BreadcrumbLink
              onClick={() => router.push(path)}
              color={index === breadcrumb.length - 1 ? 'gray.800' : 'gray.600'}
            >
              {name}
            </BreadcrumbLink>
          ) : (
            <Skeleton height="21px" width="50px" />
          )}
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  ) : null;
};

export default Breadcrumb;
