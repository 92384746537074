import styled from '@emotion/styled';

const Styles = styled.div`
  position: relative;
  .table {
    border: 1px #f0f0f0 solid;

    table {
      table-layout: auto;
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [role='columnheader'][data-sticky-td] {
        background: var(--chakra-colors-A7Gray-50);
        position: sticky;
      }

      [role='cell'][data-sticky-td] {
        background: white;
        position: sticky;
      }

      [data-sticky-last-left-td] {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: calc(-1.5rem + 4px);
          bottom: 0;
          width: 10px;
          transform: translateX(-100%);
          box-shadow: inset 10px 0 10px -10px rgb(0 0 0 / 8%);
          transition: all 0.3s;
          opacity: 0;
        }

        &[data-is-pinned='true']::after {
          opacity: 1;
        }
      }

      [data-sticky-first-right-td] {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 10px;
          transform: translateX(-100%);
          box-shadow: inset -10px 0 10px -10px rgb(0 0 0 / 8%);
          transition: all 0.3s;
          opacity: 0;
        }

        &[data-is-pinned='true']::after {
          opacity: 1;
        }
      }
    }
  }
`;

export default Styles;
