import { useEffect } from 'react';

import { Text, useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { A7Modal } from 'components/api7-components';
import useOrgs from 'hooks/useOrgs';

const OrgRemovedModal: React.FC = () => {
  const { isError } = useOrgs();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const router = useRouter();

  useEffect(() => {
    // when code equal 10, it mean that organization has been removed.
    if (isError?.code === 10) {
      onOpen();
    }
  }, [isError]);

  const onOKClick = () => {
    onClose();
    router.reload();
  };

  return (
    <A7Modal
      size="lg"
      isOpen={isOpen}
      title=""
      okText="OK"
      cancelText=""
      okTheme="dark"
      showDivider={false}
      loading={false}
      showCloseIcon={false}
      onOk={onOKClick}
      closeOnOverlayClick={false}
      showCancel={false}
      onClose={() => {}}
    >
      <Text fontSize="14px" lineHeight="20px">
        You were removed from the current organization because it was deleted.
      </Text>
    </A7Modal>
  );
};

export default OrgRemovedModal;
