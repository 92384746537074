import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';

import { UseFormReturn } from 'react-hook-form';
import { useRouter } from 'next/router';
import { CallBackProps, ACTIONS, STATUS, EVENTS } from 'react-joyride';
import { useLocalStorageState } from 'ahooks';

import { ApplicationData } from '@/types/Service';

import useUser from './useUser';
import { updateUserTour } from '../helper/service';
import useCluster from './useCluster';

type Suspend = {
  isSuspend: boolean;
  index: number;
};

type TaskStatus = 'todo' | 'inProgress' | 'done';

type TaskListType = {
  name: string;
  status: TaskStatus;
}[];

export const TourContext = createContext<{
  /** the run props of Joyride component, used to interrupt and continue the tour */
  isStepRun: boolean;
  setIsStepRun: Dispatch<SetStateAction<boolean>>;
  /** The current user uses the tour program enabled */
  tourIsOpen: boolean;
  /** Update the state of a single task by index */
  updateTourStatus: (status: boolean) => void;
  /** Calculated number of progress made by the current tour 0-100 */
  progress: number;
  suspend: Suspend;
  setSuspend: Dispatch<SetStateAction<Suspend>>;
  /** the steps props of Joyride component, set it +1 or -1 to control forward or backward within a task */
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  tasks: TaskListType;
  updateTaskStatus: (index: number, status: TaskStatus) => void;
  /** The number of the task currently in progress, 1-6; 0 means there is currently no task */
  focusIndex: number;
  setFocusIndex: Dispatch<SetStateAction<number>>;
  defaultHandleCallback: (data: CallBackProps) => void;
  formInstance: UseFormReturn<any, object> | undefined;
  setFormInstance: Dispatch<
    SetStateAction<UseFormReturn<any, object> | undefined>
  >;
  isCardOpen: string;
  onCardToggle: () => void;
}>({} as any);

const useTour = () => {
  const [tourIsOpen, setTourIsOpen] = useState(false);
  const { data: userData } = useUser();
  const [tasks, setTasks] = useState<TaskListType>([]);
  // 0 means there is currently no task
  // Start with 1 for each task
  const [focusIndex, setFocusIndex] = useState<number>(0);
  const [stepIndex, setStepIndex] = useState(0);
  const [isStepRun, setIsStepRun] = useState(true);
  const [suspend, setSuspend] = useState({
    isSuspend: false,
    index: 0,
  });
  const [formInstance, setFormInstance] =
    useState<UseFormReturn<ApplicationData<'form'>>>();
  const route = useRouter();
  const { isClusterReady } = useCluster();

  // Control the task panel alternating with the hoverball
  const [isCardOpen, setCardOpen] = useLocalStorageState<string>('cardStatus', {
    defaultValue: 'open',
  });

  const onCardToggle = () => {
    if (isCardOpen === 'open') {
      setCardOpen('close');
    } else {
      setCardOpen('open');
    }
  };

  useEffect(() => {
    if (!userData) {
      return;
    }
    // Not enabled when cluster not ready
    if (!isClusterReady) {
      return;
    }
    if (!userData.product_tour.dismiss) {
      setTourIsOpen(true);
    }
    setTasks(
      userData.product_tour.checklist.map((item) => ({
        name: item.title,
        status: item.done ? 'done' : 'todo',
      }))
    );
  }, [userData, isClusterReady]);

  const updateTourStatus = useCallback(
    (status: boolean) => {
      setTourIsOpen(status);
      updateUserTour({
        // status for ui: true -> show, false -> not show
        // dismiss for api: true -> not show, false -> show
        dismiss: !status,
        checklist: userData?.product_tour.checklist || [],
      });
    },
    [userData]
  );

  const updateTaskStatus = useCallback(
    (index: number, status: TaskStatus) => {
      const current = [...(tasks || [])];
      current[index] = {
        name: current[index].name,
        status,
      };
      setTasks(current);
      if (status === 'done') {
        updateUserTour({
          dismiss: false,
          checklist: current.map((item) => ({
            title: item.name,
            done: item.status === 'done',
          })),
        });
      }
    },
    [tasks]
  );

  const progress = useMemo(() => {
    if (!tasks) {
      return 0;
    }
    const list = tasks.filter((item) => item.status === 'done');

    return Number(((list.length / tasks.length) * 100).toFixed(0));
  }, [tasks]);

  const defaultHandleCallback = (data: CallBackProps) => {
    const { type, index, status, action, lifecycle } = data;
    if (action === ACTIONS.CLOSE) {
      setIsStepRun(false);
      setStepIndex(0);
    }
    if (
      action === ACTIONS.NEXT &&
      stepIndex === 0 &&
      [1, 2].includes(focusIndex)
    ) {
      if (route.pathname !== '/overview') {
        setIsStepRun(false);
        route.push('/overview');
      }
    }
    if (
      action === ACTIONS.PREV &&
      stepIndex === 3 &&
      focusIndex === 1 &&
      lifecycle === 'complete'
    ) {
      setIsStepRun(false);
      setStepIndex(2);
      route.push('/overview');
    }
    if (
      action === ACTIONS.PREV &&
      stepIndex === 2 &&
      focusIndex === 2 &&
      lifecycle === 'complete'
    ) {
      setIsStepRun(false);
      setStepIndex(1);
      route.push('/overview');
    }
    if (
      action === ACTIONS.NEXT &&
      stepIndex === 0 &&
      lifecycle === 'complete' &&
      [3, 4, 6].includes(focusIndex)
    ) {
      if (route.pathname !== '/api-management/services') {
        setIsStepRun(false);
        route.push('/api-management/services');
      }
    }
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // User finished or skip current Task, need set Task status to done and clear stepIndex
      setStepIndex(0);
      setIsStepRun(false);
      setFocusIndex(0);
      onCardToggle();
      updateTaskStatus(focusIndex - 1, 'done');
    } else if (([EVENTS.STEP_AFTER] as string[]).includes(type)) {
      // User click the next or prev button
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (action === ACTIONS.PREV) {
        setIsStepRun(false);
        setStepIndex(nextStepIndex);
        setTimeout(() => {
          setIsStepRun(true);
        });
      } else {
        setStepIndex(nextStepIndex);
      }
    }
  };

  return {
    isStepRun,
    setIsStepRun,
    tourIsOpen,
    updateTourStatus,
    progress,
    suspend,
    setSuspend,
    stepIndex,
    setStepIndex,
    tasks,
    updateTaskStatus,
    focusIndex,
    setFocusIndex,
    defaultHandleCallback,
    formInstance,
    setFormInstance,
    isCardOpen,
    onCardToggle,
  };
};

export default useTour;
