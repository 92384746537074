const RightArrowIcon = ({
  width = 16,
  height = 16,
  color = 'black',
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 5 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.244249 1.64608C0.244276 1.49548 0.289927 1.34825 0.375465 1.22289C0.461002 1.09754 0.582608 0.999649 0.724998 0.94153C0.86739 0.883411 1.02421 0.867656 1.17575 0.896245C1.32729 0.924834 1.46679 0.996491 1.57671 1.10221L4.70825 4.11486C4.85606 4.25709 4.93992 4.45077 4.94139 4.65332C4.94285 4.85587 4.86181 5.0507 4.71607 5.19495L1.58454 8.29315C1.51281 8.36643 1.42685 8.42505 1.33167 8.46559C1.2365 8.50614 1.134 8.52781 1.03016 8.52933C0.926312 8.53085 0.823195 8.51219 0.726812 8.47445C0.630429 8.43671 0.542707 8.38064 0.468752 8.30949C0.394798 8.23835 0.336091 8.15356 0.296049 8.06006C0.256008 7.96656 0.235433 7.86623 0.235523 7.7649C0.235613 7.66356 0.256365 7.56326 0.296573 7.46983C0.336779 7.3764 0.395638 7.29171 0.469718 7.2207L3.05167 4.66483L0.477548 2.18841C0.403576 2.11775 0.344831 2.03337 0.304766 1.94023C0.264701 1.8471 0.244126 1.74708 0.244249 1.64608Z"
      fill={`var(--chakra-colors-${color})`}
    />
  </svg>
);

export default RightArrowIcon;
