import { useCallback, useState } from 'react';

const useMenuTooltip = (currentExpandMenuItem: string | undefined) => {
  const [tooltipDisable, setTooltipDisable] = useState(false);

  // Prevent tooltip auto focus when menu close
  const preventTooltipFocus = useCallback(() => {
    setTooltipDisable(true);
    setTimeout(() => {
      setTooltipDisable(false);
    }, 100);
  }, []);

  const getTooltipProps = useCallback(
    ({
      isClusterNotReady,
      isMenuCollapse,
      menuItemName,
    }: {
      isClusterNotReady: boolean;
      isMenuCollapse: boolean;
      menuItemName: string;
    }) => {
      const tipText = 'Cluster is not ready';
      // Disable menu and show tooltip when cluster is not ready
      if (isClusterNotReady) {
        return {
          label: isMenuCollapse
            ? `${`${menuItemName}`} (${tipText}).`
            : `${tipText}.`,
          isDisabled: tooltipDisable || !isClusterNotReady,
        };
      }
      //   When the menu bar is collapsed horizontally, the menu name tooltip is displayed when hovering
      return {
        label: menuItemName,
        isDisabled:
          !isMenuCollapse ||
          tooltipDisable ||
          menuItemName === currentExpandMenuItem,
      };
    },
    [currentExpandMenuItem]
  );

  return { preventTooltipFocus, getTooltipProps };
};

export default useMenuTooltip;
