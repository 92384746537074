import createInfiniteHook from '@/helper/utils/createInfiniteHook';
import useCluster from '@/hooks/useCluster';
import { InstanceDetails } from '@/types/Instance';
import { useMemo } from 'react';

const useGatewayInfinite = createInfiniteHook<InstanceDetails>(() => {
  const { clusterId, isClusterReady } = useCluster({ dedupingInterval: 30000 });

  return useMemo(
    () => (isClusterReady ? `/clusters/${clusterId}/instances` : null),
    [isClusterReady]
  );
});

export default useGatewayInfinite;
