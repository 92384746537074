import { memo } from 'react';

import {
  InputGroup,
  InputLeftAddon,
  Input,
  InputProps,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type TextProps = DefaultProps &
  InputProps & {
    leftAddon?: string;
  };

const Text: React.FC<TextProps> = ({ children, ...rest }) => {
  const { setValue } = useFormContext();
  const { name, leftAddon, boxOption } = rest;
  const {
    isInvalid,
    formField,
    formHelperText,
    formUnit,
    formLabel,
    restProps,
  } = useFormItem<TextProps>({
    ...rest,
    registerOption: {
      onBlur: (e) => {
        setValue(name, _.trim(e.target.value));
      },
      ...(rest.registerOption || {}),
    },
  });

  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <Flex align="center" width="100%">
        {leftAddon ? (
          <InputGroup backgroundColor="white">
            <InputLeftAddon>{leftAddon}</InputLeftAddon>
            <Input
              id={name}
              isInvalid={isInvalid}
              {...formField}
              {...restProps}
            />
          </InputGroup>
        ) : (
          <Input
            backgroundColor="white"
            id={name}
            isInvalid={isInvalid}
            {...formField}
            {...restProps}
          />
        )}
        {formUnit}
      </Flex>
      {formHelperText}
    </Box>
  );
};

export default memo(Text);
