import React, { useMemo } from 'react';

import { Text } from '@chakra-ui/react';
import { StylesConfig, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';

import { colorMap } from '@/styles/customTheme/colors';

import A7Label, { getRandomColor, colorMap as A7ColorMap } from '../api7-label';
import { CreateLabelProps } from './types';

const getNativeColor = (v: string) => {
  const [color, size] = v.split('.');
  const themeObj = colorMap[color as keyof typeof colorMap];
  return themeObj[Number(size) as keyof typeof themeObj];
};

const customStyle: StylesConfig = {
  multiValue: (provided, state) => {
    const text = state.children;
    if (!text) return provided;
    const { bgColor, color } = A7ColorMap[getRandomColor(text as string)];
    const bgColorValue = getNativeColor(bgColor);
    const colorValue = getNativeColor(color);
    return {
      ...provided,
      backgroundColor: bgColorValue,
      color: colorValue,
      borderRadius: '20px',
      padding: '0 8px',
      fontSize: '14px',
      fontWeight: '400',
      height: '24px',
    };
  },
  option: (provided, state) => {
    const fetchBackgroundColor = () => {
      let backgroundColor;
      if (state.isSelected) {
        backgroundColor = '#E0E0E0';
      } else if (state.isFocused) {
        backgroundColor = '#F0F0F0';
      } else {
        backgroundColor = '#F7F7F7';
      }
      return backgroundColor;
    };
    return {
      ...provided,
      backgroundColor: fetchBackgroundColor(),
    };
  },
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '32px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,

    padding: '0 8px',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '32px',
    borderRadius: '2px',
    borderColor: 'A7Gray.200',
    padding: '0',
    boxSizing: 'content-box',
    '&:hover': {
      borderColor: 'A7Gray.200',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A0AEC0',
    fontSize: '14px',
  }),
};

const Option = (state: any) => {
  const { value, children } = state;
  const color = getRandomColor(value as string);
  return (
    <components.Option {...state}>
      <A7Label color={color}>{value}</A7Label>
      <Text as="span" ml="4px" color="A7Gray.700" fontSize="sm">
        {children.indexOf('Create') !== -1 ? '(New label)' : null}
      </Text>
    </components.Option>
  );
};
const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ChevronDownIcon w="20px" h="20px" color="black" />
  </components.DropdownIndicator>
);

const ClearIndicator = (props: any) => (
  <components.ClearIndicator {...props}>
    <CloseIcon
      w="10px"
      h="10px"
      color="A7Gray.400"
      cursor="pointer"
      _hover={{
        color: 'black',
        transition: '0.4s all',
      }}
    />
  </components.ClearIndicator>
);

const CreateLabel: React.FC<CreateLabelProps> = ({
  value,
  onChange,
  labelList,
  resource,
}) => {
  const options = useMemo(
    () =>
      labelList?.map((item) => ({
        label: item,
        value: item,
      })),
    [labelList]
  );

  return (
    <CreatableSelect
      className={`${resource}-labels`}
      styles={customStyle}
      closeMenuOnSelect={false}
      isMulti
      isClearable
      options={options || []}
      value={value || []}
      onChange={onChange as any}
      components={{ Option, DropdownIndicator, ClearIndicator }}
      noOptionsMessage={() => 'No Labels'}
    />
  );
};

export default CreateLabel;
