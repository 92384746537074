import { extendTheme } from '@chakra-ui/react';

import breakpoints from './breakpoints';
import colors from './colors';
import Button from './components/button';
import Calendar from './components/calendar';
import FormItem from './components/formItem';
import Alert from './components/alert';
import fonts from './fonts';

const customTheme = extendTheme({
  fonts,
  colors,
  breakpoints,
  components: {
    Button,
    Calendar,
    Alert,
    ...FormItem,
  },
});

export default customTheme;
