import { useRef, useState } from 'react';

import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Button } from '@chakra-ui/react';
import Select, { components, StylesConfig } from 'react-select';

export type Options = {
  label: string;
  value: string | number;
} | null;

type Props = {
  name?: string;
  option: readonly Options[];
  boxOption?: BoxProps;
  onChange: (data: Options) => void;
  loading?: boolean;
  placeholder?: string;
  value?: { label: string; value: string | number } | null;
  isClearable?: boolean;
  isDisabled?: boolean;
};

const DropdownIndicator = (props: any) => {
  const { isDisabled } = props;
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        w="20px"
        h="20px"
        color={isDisabled ? 'gray' : 'black'}
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => (
  <components.ClearIndicator {...props}>
    <CloseIcon
      className="clear-icon"
      w="10px"
      h="10px"
      color="A7Gray.400"
      cursor="pointer"
      _hover={{
        color: 'black',
        transition: '0.4s all',
      }}
    />
  </components.ClearIndicator>
);

const Option = ({ innerProps, isDisabled, ...props }: any) => {
  const isCustomFiled = Object.keys(props.data.a7Extra || {}).length > 0;
  return (
    <Button
      {...innerProps}
      display="block"
      w="full"
      minHeight={isCustomFiled ? '55px' : '40px'}
      padding="10px 16px 10px 16px"
      textAlign="left"
      backgroundColor={props.isSelected ? 'A7Gray.300' : 'A7Gray.100'}
      _hover={{
        backgroundColor: 'A7Gray.200',
      }}
      disabled={isDisabled}
      data-cy={`select-option-${props.label}`}
    >
      <Box width="full">
        <Box fontSize="sm" lineHeight="5">
          {props.label}
        </Box>
        {Boolean(isCustomFiled) && (
          <Box
            fontSize="10px"
            fontWeight={400}
            width="full"
            whiteSpace="normal"
            wordBreak="break-word"
          >
            {props.data.a7Extra.desc}
          </Box>
        )}
      </Box>
    </Button>
  );
};
const A7Select: React.FC<Props> = ({
  name,
  option = [],
  boxOption,
  onChange,
  loading = false,
  placeholder = '',
  isClearable = true,
  isDisabled = false,
  value,
  ...rest
}) => {
  const selectRef = useRef<any>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inputIsClear, setInputIsClear] = useState(false);

  const customStyles: StylesConfig<Options> = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#F7F7F7',
      borderRadius: '2px',
      fontSize: '14px',
      minWidth: '100%',
      width: 'max-content',
    }),
    option: (provided, state) => {
      const fetchBackgroundColor = () => {
        let backgroundColor;
        if (state.isSelected) {
          backgroundColor = '#E0E0E0';
        } else if (state.isFocused) {
          backgroundColor = '#F0F0F0';
        } else {
          backgroundColor = '#F7F7F7';
        }
        return backgroundColor;
      };
      return {
        ...provided,
        backgroundColor: fetchBackgroundColor(),
        color: '#2C2C2C',
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      borderRadius: '0px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#484848',
      height: '32px',
      padding: '0 8px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '32px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    control: (provided) => ({
      ...provided,
      height: '32px',
      minHeight: '32px',
      borderRadius: '2px',
      borderColor: 'A7Gray.200',
      padding: '0',
      '&:hover': {
        borderColor: 'A7Gray.200',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0',
      fontSize: '14px',
    }),
  };

  return (
    <Box h="32px" data-cy={name} {...boxOption}>
      <Select<Options>
        ref={selectRef}
        components={{ ClearIndicator, DropdownIndicator, Option }}
        styles={customStyles}
        isClearable={isClearable}
        key={option.map((item) => item?.value).join('')}
        menuPlacement="auto"
        menuPosition="fixed"
        menuShouldBlockScroll
        options={option}
        isLoading={loading}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        blurInputOnSelect
        onChange={(data) => {
          onChange(data);
          if (!data) {
            setInputIsClear(true);
          } else {
            // fix https://api7ai.atlassian.net/browse/CLOUD-1498
            setMenuIsOpen(false);
          }
        }}
        onFocus={() => {
          // Delayed rendering of menus to ensure correct menu placement
          setTimeout(() => {
            if (inputIsClear) {
              selectRef.current.blur();
              setInputIsClear(false);
            } else {
              setMenuIsOpen(true);
            }
          });
        }}
        onBlur={() => {
          setMenuIsOpen(false);
        }}
        menuIsOpen={menuIsOpen}
        {...rest}
      />
    </Box>
  );
};

export default A7Select;
