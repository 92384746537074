import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, BoxProps } from '@chakra-ui/react';
import Select, { components, StylesConfig } from 'react-select';

export type Options = {
  label: string;
  value: string | number;
} | null;

type ResourceSelectProps = {
  name?: string;
  option: readonly Options[];
  boxOption?: BoxProps;
  onChange: (data: Options) => void;
  value?: { label: string; value: string | number } | null;
  defaultValue: ResourceSelectProps['value'];
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <TriangleDownIcon w="8px" h="8px" color="black" />
  </components.DropdownIndicator>
);

const ResourceSelect: React.FC<ResourceSelectProps> = ({
  option = [],
  onChange,
  value,
  defaultValue,
}) => {
  const customStyles: StylesConfig<Options> = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#F7F7F7',
      borderRadius: '2px',
      fontSize: '14px',
      minWidth: '100%',
      width: 'max-content',
    }),
    option: (provided, state) => {
      let backgroundColor;
      if (state.isSelected) {
        backgroundColor = '#E0E0E0';
      } else if (state.isFocused) {
        backgroundColor = '#F0F0F0';
      } else {
        backgroundColor = '#F7F7F7';
      }
      return {
        ...provided,
        backgroundColor,
        color: '#2C2C2C',
        fontWeight: state.isSelected ? '700' : '500',
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      borderRadius: '0px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#484848',

      padding: '0 8px',
      border: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    control: (provided) => ({
      ...provided,
      height: '50px',
      minHeight: '32px',
      borderRadius: '2px',
      border: 'none',
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0',
      fontSize: '14px',
    }),
  };

  return (
    <Box h="50px" w="130px" mx="8px">
      <Select<Options>
        components={{ DropdownIndicator }}
        styles={customStyles}
        isClearable={false}
        key={option.map((item) => item?.value).join('')}
        menuPlacement="auto"
        menuPosition="fixed"
        isSearchable={false}
        menuShouldBlockScroll
        options={option}
        value={value}
        blurInputOnSelect
        autoFocus={false}
        openMenuOnClick
        onChange={(data) => {
          onChange(data);
        }}
        defaultValue={defaultValue}
      />
    </Box>
  );
};

export default ResourceSelect;
