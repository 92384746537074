import useClusterDetail from '@/hooks/useClusterDetail';
import { ClusterSettings } from '@/types/Cluster';
import _ from 'lodash';

type ClusterSettingsPath = keyof Flat<ClusterSettings>;

const settingTypeMap: Record<keyof ClusterSettings, string> = {
  client_settings: 'Client Settings',
  observability_settings: 'Observability Settings',
  api_proxy_settings: 'API Proxy Settings',
};

const settingMap: { [Key in ClusterSettingsPath]: string } = {
  'client_settings.maximum_request_body_size': 'Maximum Request Body Size',
  'client_settings.client_real_ip.enabled': 'Client Real IP Replacement',
  'client_settings.client_real_ip.replace_from.name': 'Replace From Name',
  'client_settings.client_real_ip.replace_from.position':
    'Replace From Position',
  'client_settings.client_real_ip.trusted_addresses': 'Trusted Addresses',

  'observability_settings.metrics.enabled': 'Upload Prometheus Metrics',
  'observability_settings.show_upstream_status_in_response_header':
    'Show All Upstream Status in Response Header',
  'observability_settings.access_log_rotate.enabled': 'Rotate Logs',
  'observability_settings.access_log_rotate.interval': 'Rotate Interval',
  'observability_settings.access_log_rotate.maximum_kept_log_entries':
    'Maximum Kept Log Entries',
  'observability_settings.access_log_rotate.enable_compression':
    'Gzip Compression',

  'api_proxy_settings.enable_request_buffering': 'Enable Request Buffering',
  'api_proxy_settings.server_header_customization.mode':
    'Server Header Customization',
  'api_proxy_settings.server_header_customization.new_server_header':
    'New Server Header',
  'api_proxy_settings.url_handling_options': 'URI Handling',
};

const useSetting = (search: string) => {
  const { data, isValidating } = useClusterDetail();

  const filterList = Object.entries(settingMap)
    ?.filter(
      ([key, value]) =>
        new RegExp(_.escapeRegExp(search), 'ig').test(value) &&
        !_.isUndefined(_.get(data?.settings, key as ClusterSettingsPath))
    )
    .map(([key, value]) => ({
      id: key,
      name: value,
      type: settingTypeMap[key.split('.')[0] as keyof ClusterSettings],
    }));

  const list = {
    list: filterList,
    count: filterList.length,
  };

  return {
    list,
    isValidating,
  };
};

export default useSetting;
