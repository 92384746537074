import React, { useContext, memo } from 'react';

import Joyride, { Step, CallBackProps, ACTIONS } from 'react-joyride';
import { useRouter } from 'next/router';

import { TourContext } from 'hooks/useTour';
import { A7CodeBlock, A7Code } from 'components/api7-components';

import { StepStyle } from '../constant';
import Tooltip from '../Tooltip';

const code = `for ((i=0; i<5; i++)); do
curl http://127.0.0.1:9080/v1/get -H 'Host: api7-cloud.httpbin.org' -i
done`;

const Task6: React.FC = () => {
  const {
    isStepRun,
    stepIndex,
    defaultHandleCallback,
    focusIndex,
    setIsStepRun,
    setStepIndex,
    formInstance,
  } = useContext(TourContext);
  const route = useRouter();

  const steps: Step[] = [
    {
      target: 'body',
      title: 'Welcome!',
      disableBeacon: true,
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
      content:
        "Now let's add the Limit Count Plugin for the Service you just created. Limit Count Plugin imposes the request limit countation for your Service, exceeded requests will be rejected by the API Gateway.",
    },
    {
      target: '#table-first-child', // Todo: select the app after create
      title: 'Step 1',
      content: 'Click on My HTTPBIN to enter its detail page.',
      spotlightClicks: true,
    },
    {
      target: '#add-app-plugin',
      title: 'Step 2',
      content: 'Click here to add a new Plugin.',
      spotlightClicks: true,
      placement: 'bottom',
    },
    {
      target: '#chakra-modal-app-plugin-form',
      title: 'Step 3',
      spotlightClicks: true,
      placement: 'left',
      styles: {
        tooltip: {
          width: '350px',
        },
      },
      content: (
        <>
          Select <A7Code>Limit Count</A7Code> Plugin and fill in the throttle
          quota and time period. Try to set Count to <A7Code>3</A7Code>, Period
          to <A7Code>10</A7Code>.
        </>
      ),
    },
    {
      target: '[data-cy="modal-ok-button"]',
      title: 'Step 4',
      content: 'Now you can click this button to add plugin !',
    },
    {
      target: 'body',
      title: 'Step 5',
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
      content: (
        <div>
          <p style={{ marginBottom: '30px' }}>
            {`It's time to send some API requests to this Service and
            verify if the Limit Count Plugin works.`}
          </p>
          <A7CodeBlock width="535px" code={code} copyCode={code} />
        </div>
      ),
    },
    {
      target: 'body',
      title: 'Summary',
      content:
        "Thanks for your participation! Now you've known how to add the Limit Count Plugin.",
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
  ];

  const handleCallback = async (data: CallBackProps) => {
    const { action, lifecycle } = data;
    if (action === ACTIONS.NEXT && stepIndex === 3 && focusIndex === 6) {
      const result = await formInstance?.trigger('plugins.limit-count');

      if (!result || !formInstance?.getValues('pluginType')) {
        setIsStepRun(false);
        setStepIndex(3);
        setIsStepRun(true);
        return;
      }
    }

    if (
      action === ACTIONS.PREV &&
      stepIndex === 2 &&
      focusIndex === 6 &&
      lifecycle === 'complete'
    ) {
      route.back();
      setStepIndex(1);
      setIsStepRun(false);
      return;
    }
    if (
      action === ACTIONS.PREV &&
      stepIndex === 3 &&
      focusIndex === 6 &&
      lifecycle === 'complete'
    ) {
      (window as any).closePluginForm();
      setIsStepRun(false);
      setStepIndex(2);
      setTimeout(() => {
        setIsStepRun(true);
        document.getElementById('add-app-plugin')?.scrollIntoView({
          block: 'center',
        });
      }, 400);
      return;
    }

    defaultHandleCallback(data);
  };

  return (
    <Joyride
      callback={handleCallback}
      run={isStepRun}
      steps={steps}
      stepIndex={stepIndex}
      continuous
      disableOverlayClose
      disableScrolling
      styles={StepStyle}
      tooltipComponent={Tooltip}
      floaterProps={{
        styles: {
          floater: {
            zIndex: 2000,
          },
        },
      }}
    />
  );
};

export default memo(Task6);
