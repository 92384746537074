import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Flex,
  Center,
  ModalBody,
  Box,
  SimpleGrid,
  Text,
  Divider,
} from '@chakra-ui/react';

export type PreviewDataType = Record<
  string,
  string | boolean | string[] | React.ReactNode
>;

const PluginView: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  data: PreviewDataType;
  label?: React.ReactNode;
  keyMap?: Record<string, string>;
}> = ({ isOpen, onClose, data, title, label = null, keyMap = {} }) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton />

      <ModalHeader color="A7Gray.700" fontWeight="medium" fontSize="lg">
        <Flex align="center">
          {title}
          <Center ml="10px">{label}</Center>
        </Flex>
      </ModalHeader>
      <Divider />
      <ModalBody pt="16px" pb="20px">
        <Box>
          <SimpleGrid minChildWidth="240px" spacing="40px">
            {Object.keys(data || {}).map((key) => {
              const computerValue = () => {
                if (typeof data?.[key] === 'boolean') {
                  return data?.[key] ? 'YES' : 'NO';
                }
                if (Array.isArray(data?.[key])) {
                  return (data?.[key] as string[]).join(', ');
                }
                return data?.[key];
              };
              return (
                <Flex
                  key={key}
                  min-height="40px"
                  direction="column"
                  justify="space-between"
                  mt="10px"
                >
                  <Box>
                    <Text
                      fontWeight="semibold"
                      fontSize="xs"
                      color="A7Gray.500"
                    >
                      {keyMap[key.toString()] || key.toString()}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="medium" fontSize="sm" color="A7Gray.800">
                      {computerValue()}
                    </Text>
                  </Box>
                </Flex>
              );
            })}
          </SimpleGrid>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default PluginView;
