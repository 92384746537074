import React, { useContext, memo } from 'react';

import Joyride, { Step } from 'react-joyride';

import { TourContext } from 'hooks/useTour';

import { StepStyle } from '../constant';
import Tooltip from '../Tooltip';

const Task1: React.FC = () => {
  const { isStepRun, stepIndex, defaultHandleCallback } =
    useContext(TourContext);

  const steps: Step[] = [
    {
      target: 'body',
      disableBeacon: true,
      placement: 'center',
      content: "Now let's learn the API7 Cloud basics.",
      title: 'Welcome!',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
    {
      target: '#gateway-instances',
      disableBeacon: true,
      content:
        'Routes you created will be deployed to your API Gateway instances (aka Data Plane). You can see their details here.',
      title: 'Gateway Instances',
    },
    {
      target: '#menu-item-Monitoring',
      content:
        'You can see the running status of all API Gateway instances on the Monitoring page. Try to click on it!',
      title: 'Monitoring',
      spotlightClicks: true,
      disableBeacon: true,
    },
    {
      target: 'body',
      placement: 'center',
      content:
        "You can see some valuable metrics on this page. They're helpful for API analyzing and troubleshooting (There is no data if you don't deploy any API Gateway instance).",
      title: 'Metrics',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
    {
      target: '#menu-item-APIManagement',
      content: 'This is the place to manage everything about your Routes.',
      title: 'API Management',
    },
    {
      target: '#menu-item-Organization',
      content: 'Manage members and permissions here.',
      title: 'Organization',
    },
    {
      target: 'body',
      placement: 'center',
      content:
        "Thanks for your participation! Now you've learned the API7 Cloud basics.",
      title: 'Summary',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
  ];

  return (
    <Joyride
      callback={defaultHandleCallback}
      run={isStepRun}
      steps={steps}
      stepIndex={stepIndex}
      continuous
      disableOverlayClose
      scrollToFirstStep
      styles={StepStyle}
      tooltipComponent={Tooltip}
    />
  );
};

export default memo(Task1);
