/* eslint-disable react/require-default-props */
import { forwardRef, useMemo } from 'react';

import { Button, ButtonProps, Flex, Image, ImageProps } from '@chakra-ui/react';
import { useDebounceFn } from 'ahooks';

type ButtonTheme =
  | 'light'
  | 'dark'
  | 'ghost'
  | 'red'
  | 'yellow'
  | 'green'
  | 'indigo';
const buttonTheme: Record<ButtonTheme, ButtonProps> = {
  light: {
    bgColor: 'white',
    color: 'A7Gray.800',
    border: '1px solid #F0F0F0',
    _hover: {
      bgColor: 'A7Gray.100',
    },
    _active: {
      bgColor: 'A7Gray.100',
    },
  },
  dark: {
    bgColor: 'A7Gray.800',
    color: 'white',
    _hover: {
      bgColor: 'A7Gray.700',
    },
    _active: {
      bgColor: 'A7Gray.700',
    },
    border: 'none',
  },
  ghost: {
    bgColor: 'white',
    color: 'A7Gray.800',
    _hover: {
      bgColor: 'A7Gray.200',
    },
    _active: {
      bgColor: 'A7Gray.200',
    },
    border: 'none',
  },
  red: {
    bgColor: 'A7Red.500',
    color: 'white',
    _hover: {
      bgColor: 'A7Red.600',
    },
    _active: {
      bgColor: 'A7Red.600',
    },
    border: 'none',
  },
  yellow: {
    bgColor: 'A7Yellow.300',
    color: 'A7Yellow.800',
    _hover: {
      bgColor: 'A7Yellow.400',
    },
    _active: {
      bgColor: 'A7Yellow.400',
    },
    border: 'none',
  },
  green: {
    bgColor: 'A7Green.500',
    color: 'white',
    _hover: {
      bgColor: 'A7Green.600',
    },
    _active: {
      bgColor: 'A7Green.600',
    },
    border: 'none',
  },
  indigo: {
    bgColor: 'A7Indigo.500',
    color: 'white',
    _hover: {
      bgColor: 'A7Indigo.800',
    },
    border: 'none',
  },
} as const;

export type A7ButtonProps = Omit<ButtonProps, 'type'> & {
  type?:
    | 'normal'
    | 'add'
    | 'edit'
    | 'delete'
    | 're-invite'
    | 'duplicate'
    | 'draft'
    | 'publish'
    | 'start'
    | 'pause'
    | 'finish'
    | 'navigation'
    | 'refresh'
    | 'reset'
    | 'text'
    | 'enter'
    | 'close';
  theme?: keyof typeof buttonTheme;
  iconOption?: ImageProps;
};

const A7Button = forwardRef<
  HTMLButtonElement | null,
  A7ButtonProps & { isDebounce?: boolean }
>(
  (
    {
      type = 'normal',
      theme = 'light',
      children = '',
      isDebounce = false,
      onClick,
      iconOption = {},
      ...rest
    },
    ref
  ) => {
    const { run: onDebounceClick } = useDebounceFn(
      (e) => (onClick ? onClick!(e) : () => {}),
      {
        wait: 100,
      }
    );

    const icon = useMemo(() => {
      if (type !== 'normal') {
        if (type === 'text') {
          return null;
        }

        let iconPath;
        if (theme === 'light' || theme === 'ghost') {
          iconPath = `/icons/${type}.svg`;
        } else {
          iconPath = `/icons/${type}-white.svg`;
        }

        return (
          <Image
            width="14px"
            height="14px"
            marginRight={children ? '8px' : '0'}
            src={iconPath}
            className={`${type}-icon`}
            {...iconOption}
          />
        );
      }
      return null;
    }, [type, theme]);

    return (
      <Button
        {...(onClick
          ? { onClick: isDebounce ? onDebounceClick : onClick }
          : {})}
        ref={ref}
        size="sm"
        px={children || type === 'normal' ? '12px' : '0'}
        {...buttonTheme[theme]}
        {...rest}
        {...(type === 'text'
          ? { border: 'unset', fontSize: '14px', color: 'A7Blue.600' }
          : {})}
        isDisabled={rest.isDisabled || rest.isLoading}
      >
        <Flex align="center">
          {icon}
          {children}
        </Flex>
      </Button>
    );
  }
);

export default A7Button;
