import { DeepPartial, Theme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const Alert: DeepPartial<Theme['components']['Alert']> = {
  variants: {
    subtle: (props: StyleFunctionProps) => {
      const { colorScheme } = props;
      return {
        container: {
          bg: `${colorScheme}.50`,
          'border-radius': '2px !important',
        },
      };
    },
  },
};

export default Alert;
