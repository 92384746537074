import { Center, Spinner } from '@chakra-ui/react';
import { useDebounce } from 'ahooks';
import { memo } from 'react';

const TableLoading: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  // To prevent the loading animation from flickering frequently, it will only be displayed if the loading time exceeds 500 ms
  const debouncedLoading = useDebounce(isOpen, {
    wait: 500,
  });

  return debouncedLoading ? (
    <Center
      w="full"
      h="full"
      position="absolute"
      background="#00000007"
      zIndex="5"
    >
      <Spinner />
    </Center>
  ) : null;
};

export default memo(TableLoading);
