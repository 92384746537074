import { useState, useMemo } from 'react';

import { Box, Input } from '@chakra-ui/react';

import A7Modal from '../api7-modal';

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  confirmText: string;
  onConfirm: () => void;
  mode?: 'delete' | 'confirm';
  customContent?: React.ReactNode;
  okText?: string;
};
const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  confirmText,
  isLoading = false,
  onConfirm,
  mode = 'delete',
  customContent = null,
  okText,
}) => {
  const [inputText, setInputText] = useState('');
  const okTextValue = useMemo(() => {
    if (okText) {
      return okText;
    }
    return mode === 'delete' ? 'Delete' : 'Next';
  }, [okText, mode]);

  return (
    <A7Modal
      isOpen={isOpen}
      onClose={() => {
        setInputText('');
        onClose();
      }}
      isCentered
      title={title}
      okText={okTextValue}
      loading={isLoading}
      onOk={onConfirm}
      okTheme={mode === 'delete' ? 'red' : 'dark'}
      okDisabled={inputText.trim() !== confirmText.trim() || isLoading}
      size="md"
    >
      {customContent}
      <Box fontWeight="medium" fontSize="sm" lineHeight="5" color="A7Gray.700">
        Input the <span style={{ fontWeight: '800' }}>{confirmText}</span>{' '}
        before you go ahead
      </Box>
      <Input
        mt="8px"
        h="32px"
        borderRadius="2px"
        width="400px"
        autoFocus
        onChange={(e) => setInputText(e.currentTarget.value)}
        isInvalid={inputText.trim() !== confirmText.trim()}
      />
    </A7Modal>
  );
};

export default ConfirmModal;
