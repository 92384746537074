import _ from 'lodash';

import { Role } from 'types/User';

import useUser from './useUser';

export type Path = keyof Flat<Role['permissions']>;

export type AuthPage =
  | 'api_management.app'
  | 'api_management.api'
  | 'api_management.consumer'
  | 'cluster.cluster';

const useAccess = ({
  scope,
  scopeRefId,
}: { scope?: string; scopeRefId?: string } = {}) => {
  const { data, isLoading, isError } = useUser();

  // Combine all permissions the user has to obtain the highest permissions
  const accessData = _.cloneDeep(data)
    ?.members?.[0]?.roles.filter(
      (role) => role.scope !== scope && role.scope_ref_id !== scopeRefId
    )
    .reduce(
      (pre, cur) =>
        _.mergeWith(pre, cur, (obj, src) => {
          if (_.isBoolean(obj) && _.isBoolean(src)) {
            return obj || src;
          }
        }),
      {} as any
    ).permissions;

  const getAccess = (path: Path): boolean => {
    const tmpAccess = _.get(accessData, path, false);
    if (scope && scopeRefId && !tmpAccess) {
      let returnVal = false;
      _.cloneDeep(data)?.members?.[0]?.roles.forEach((role) => {
        if (role.scope === scope && role.scope_ref_id === scopeRefId) {
          returnVal = true;
        }
      });
      return returnVal;
    }
    return tmpAccess;
  };

  return {
    accessData,
    getAccess,
    isLoading,
    isError,
  };
};

export default useAccess;
