import { memo, useCallback, useContext } from 'react';

import {
  Flex,
  Box,
  Collapse,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Image,
} from '@chakra-ui/react';
import _ from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';

import useAccess from 'hooks/useAccess';
import { TourContext } from 'hooks/useTour';

import useCluster from 'hooks/useCluster';
import GlobalSearch from '@/components/GlobalSearch';
import useMenuCollapse from './hooks/useMenuCollapse';
import useMenuItem from './hooks/useMenuItem';
import useMenuTooltip from './hooks/useTooltip';
import { RouteProps } from '../../../helper/route';
import SideMenuChild from './SideMenuChild';

const SideMenu: React.FC = () => {
  const {
    menuList,
    onMenuItemClick,
    isMenuSelected,
    currentExpandMenuItem,
    setCurrentExpandMenuItem,
  } = useMenuItem();

  const { isMenuCollapse, showMenuCollapseBtn, hideMenuCollapseBtn, menuIcon } =
    useMenuCollapse();

  const { preventTooltipFocus, getTooltipProps } = useMenuTooltip(
    currentExpandMenuItem
  );
  const { accessData } = useAccess();

  const { setIsStepRun, setSuspend, tourIsOpen, stepIndex } =
    useContext(TourContext);

  const { isClusterReady } = useCluster();

  // Judging role permissions to determine whether to render menu items
  const isHidden = useCallback(
    (item: RouteProps[number]) => {
      if (!item.access) {
        return true;
      }
      return item.access.find((access) => {
        if (_.get(accessData, access)) {
          return true;
        }
        return false;
      });
    },
    [accessData]
  );

  const isClusterNotReady = useCallback(
    (item: RouteProps[number]) => {
      if (item.clusterDependent && !isClusterReady) {
        return true;
      }
      return false;
    },
    [isClusterReady]
  );

  const sideMenuChild = (menuList: RouteProps) => (
    <SideMenuChild
      isHidden={isHidden}
      menuList={menuList}
      setSelectedMenu={setCurrentExpandMenuItem}
      preventTooltipFocus={preventTooltipFocus}
      isMenuCollapse={isMenuCollapse}
    />
  );

  return (
    <>
      <Box
        h="calc(100vh - 72px)"
        position="fixed"
        left="0"
        top="72px"
        zIndex="5"
        onMouseEnter={() => showMenuCollapseBtn()}
        onMouseLeave={() => hideMenuCollapseBtn()}
      >
        <Flex
          className="left-menu"
          color="A7Gray.800"
          pt="20px"
          flexDirection="column"
          position="relative"
          width={isMenuCollapse ? '76px' : '256px'}
          h="full"
          transition="width 0.2s ease-in-out"
          bg="white"
        >
          <GlobalSearch isMenuCollapse={isMenuCollapse} />
          {menuList.map(
            (item) =>
              isHidden(item) && (
                <Tooltip
                  hasArrow
                  placement="right"
                  offset={[0, 16]}
                  key={item.path}
                  {...getTooltipProps({
                    isClusterNotReady: isClusterNotReady(item),
                    menuItemName: item.name,
                    isMenuCollapse,
                  })}
                >
                  <Box
                    as="a"
                    _hover={item.expand ? {} : { bgColor: 'A7Gray.100' }}
                    className="left-menu-item"
                    id={`menu-item-${item.name}`.replace(/\s/g, '')}
                    mb="8px"
                    color={
                      item.path === isMenuSelected ? 'A7Gray.800' : 'A7Gray.600'
                    }
                    opacity={isClusterNotReady(item) ? 0.5 : 1}
                    cursor={isClusterNotReady(item) ? 'not-allowed' : 'pointer'}
                    onClick={() => {
                      if (isClusterNotReady(item)) {
                        return;
                      }
                      if (item.name === 'Monitoring') {
                        if (tourIsOpen && stepIndex === 2) {
                          setIsStepRun(false);
                          setSuspend({
                            isSuspend: true,
                            index: 3,
                          });
                        }
                      }
                      onMenuItemClick(item);
                    }}
                  >
                    <Flex py="8px" px="26px" position="relative">
                      <Box
                        backgroundColor={
                          item.path === isMenuSelected ? '#E53E3E' : 'none'
                        }
                        width="4px"
                        height="24px"
                        position="absolute"
                        left="0"
                      />
                      <Menu
                        matchWidth
                        offset={[-8, 32]}
                        isOpen={currentExpandMenuItem === item.name}
                        placement="right-start"
                        onClose={() => {
                          setCurrentExpandMenuItem(undefined);
                          preventTooltipFocus();
                        }}
                      >
                        <MenuButton
                          w="20px"
                          h="24px"
                          flexShrink={0}
                          cursor="inherit"
                        >
                          <Image
                            w="20px"
                            h="20px"
                            src={`/icons/menu${item.path}${
                              item.path === isMenuSelected ? '-active' : ''
                            }.svg`}
                          />
                        </MenuButton>

                        {item.child && isMenuCollapse && (
                          <MenuList
                            py="0"
                            borderRadius="4px"
                            minW="0"
                            w="159px"
                          >
                            {sideMenuChild(item.child)}
                          </MenuList>
                        )}
                      </Menu>
                      <AnimatePresence>
                        {!isMenuCollapse && (
                          <motion.div
                            style={{ overflowX: 'hidden' }}
                            initial={{ width: '0px' }}
                            animate={{ width: '200px' }}
                            exit={{ width: '0px' }}
                            transition={{
                              duration: 0.2,
                              ease: 'easeInOut',
                            }}
                          >
                            <Text
                              id="menu-item-text"
                              as="a"
                              ml="8px"
                              fontSize="sm"
                              whiteSpace="nowrap"
                              fontWeight={
                                item.path === isMenuSelected ? '500' : '400'
                              }
                              transition="none"
                            >
                              {item.name}
                            </Text>
                          </motion.div>
                        )}
                      </AnimatePresence>
                      {item.child && !isMenuCollapse && (
                        <Image
                          src={`/icons/arrow-${
                            item.expand ? 'up' : 'down'
                          }-line.svg`}
                        />
                      )}
                    </Flex>
                    <AnimatePresence>
                      {item.child && !isMenuCollapse && (
                        <motion.div
                          style={{ overflow: 'hidden' }}
                          initial={{ height: '0px' }}
                          animate={{ height: 'auto' }}
                          exit={{ height: '0px' }}
                          transition={{ duration: 0.2, ease: 'easeInOut' }}
                        >
                          <Collapse in={item.expand} animateOpacity>
                            <Box>{sideMenuChild(item.child)}</Box>
                          </Collapse>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Box>
                </Tooltip>
              )
          )}
          {menuIcon}
        </Flex>
      </Box>
      {/* placeholder container */}
      <Box width={isMenuCollapse ? '76px' : '256px'} />
    </>
  );
};

export default memo(SideMenu);
