import { ReactNode, useEffect } from 'react';

import {
  Flex,
  Box,
  Button,
  FormHelperText,
  Image,
  FormLabel,
  Center,
} from '@chakra-ui/react';
import { useFieldArray, UseFieldArrayProps } from 'react-hook-form';

import A7Button from '../api7-button';

export type A7FormListProps = {
  instanceParams: UseFieldArrayProps<any>;
  helperText?: string | ReactNode;
  render: ({
    item,
    index,
  }: {
    item: Record<'id', string>;
    index: number;
    fields: Record<'id', string>[];
  }) => JSX.Element;
  addText?: string;
  label: string;
  // params isGroup control the style of the field item container
  isGroup?: boolean;
  // params required control whether the first field can be delete
  required?: boolean;
  appendValue?: string | {};
  minLength?: number;
  subTitle?: string;
};

const A7FormList: React.FC<A7FormListProps> = ({
  instanceParams,
  helperText = '',
  render,
  label,
  addText = '',
  isGroup = false,
  required = false,
  appendValue = {},
  minLength,
  subTitle,
}) => {
  const { fields, append, remove } = useFieldArray({ ...instanceParams });
  const fieldsContainerStyle = isGroup
    ? {
        borderLeft: '2px solid #E1D6FF',
        paddingLeft: '12px',
        py: '4px',
        mb: '24px',
        backgroundColor: 'A7Gray.50',
      }
    : {
        mb: '8px',
      };

  useEffect(() => {
    // if form list is required, it should have at least one field item
    if (required && fields.length === 0) {
      append(appendValue);
    }
  }, [required, fields, minLength]);

  useEffect(() => {
    if (minLength && fields.length < minLength) {
      const diff = minLength - fields.length;
      if (diff > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < diff - 1; i++) {
          // fix auto focus bug
          append(appendValue, { focusName: 'FIELD_NEVER_EXIST' });
        }
      }
    }
  }, [minLength, fields]);

  return (
    <Box>
      <FormLabel htmlFor={instanceParams.name}>{label}</FormLabel>
      {fields.map((item, index) => (
        <>
          {subTitle && (
            <Box
              fontSize="12px"
              mt="24px"
              mb="10px"
              fontWeight="500"
              color="A7Gray.700"
            >
              {subTitle} {index + 1}
            </Box>
          )}
          <Flex
            key={item.id}
            {...fieldsContainerStyle}
            mb={fields.length === index + 1 ? '8px' : '24px'}
            borderRadius="2px 0px 0px 2px"
          >
            <Flex w="full" justifyContent="space-between">
              {render({ item, index, fields })}
              {!(required && fields.length === 1) &&
                fields.length > (minLength || 0) && (
                  <Center mx="10px">
                    <Button
                      p="0"
                      color="white"
                      size="xs"
                      variant="ghost"
                      data-cy={`remove-${instanceParams.name}-${index}`}
                      onClick={() => {
                        if (minLength && fields.length <= minLength) {
                          return;
                        }
                        remove(index);
                      }}
                    >
                      <Image
                        width="16px"
                        height="16px"
                        src="/icons/delete.svg"
                        className="delete-icon"
                      />
                    </Button>
                  </Center>
                )}
            </Flex>
          </Flex>
        </>
      ))}
      {Boolean(helperText) && (
        <FormHelperText mt="10px" fontSize="sm" color="A7Gray.500">
          {helperText}
        </FormHelperText>
      )}
      <A7Button
        key="add"
        type="add"
        theme="light"
        mt="10px"
        data-cy={`add-${instanceParams.name}`}
        onClick={() => {
          append(appendValue);
        }}
      >
        {addText || 'Add'}
      </A7Button>
    </Box>
  );
};

export default A7FormList;
