import axios from 'axios';

type Props = {
  url: string;
} & (
  | {
      savePage?: false;
    }
  | {
      savePage: true;
      pageIndex: number;
      gotoPage: (params: number) => void;
    }
);

const listFetcher = ({ url, ...rest }: Props) =>
  axios.get(url).then((res) => {
    if (rest.savePage) {
      // auto jump to the last page. For example, the last page is 7, but query params page is more than 7, it need to jump to 7.
      const count = res.data.payload.count / 10;
      if (count < rest.pageIndex + 1) {
        rest.gotoPage(Math.ceil(count) - 1 >= 0 ? Math.ceil(count) - 1 : 0);
      }
    }
    return res;
  });

export default listFetcher;
