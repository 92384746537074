import { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import {
  Box,
  Flex,
  FormHelperText,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { DefaultProps } from './types';

const useFormItem = <T extends DefaultProps>({
  label = '',
  customLabel,
  labelOption = {},
  boxOption,
  unit = '',
  name,
  isOptional = false,
  helperText = '',
  labelTooltip = '',
  isRequired = false,
  max,
  min,
  maxLength,
  minLength,
  registerOption = {},
  ...restProps
}: T) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);

  const isInvalid = useMemo(() => Boolean(error), [error]);

  const formTooltip = useMemo(
    () =>
      labelTooltip && (
        <Tooltip
          hasArrow
          label={labelTooltip}
          placement="right"
          bg="A7Gray.900"
        >
          <InfoOutlineIcon
            ml="4px"
            w="14px"
            h="14px"
            data-testid="labelTooltip"
          />
        </Tooltip>
      ),
    [labelTooltip]
  );

  const formLabel = useMemo(
    () =>
      label && (
        <Flex>
          <FormLabel mr="4px" htmlFor={name} {...labelOption}>
            {`${label} ${isOptional ? '(Optional)' : ''}`}
          </FormLabel>
          {formTooltip}
        </Flex>
      ),
    [label, isOptional, formTooltip, labelOption]
  );

  const customFormLabel = useMemo(
    () =>
      customLabel && (
        <Flex>
          <FormLabel mr="4px" htmlFor={name} {...labelOption}>
            {customLabel}
          </FormLabel>
        </Flex>
      ),
    [customLabel]
  );

  const formHelperText = useMemo(
    () => (
      <>
        {helperText && <FormHelperText mt="10px">{helperText}</FormHelperText>}
        {error && (
          <FormHelperText color="#E53E3E">
            <p>{error.message}</p>
          </FormHelperText>
        )}
      </>
    ),
    [error, helperText]
  );

  const formUnit = useMemo(
    () =>
      unit && (
        <Box ml="10px" fontSize="sm">
          {unit}
        </Box>
      ),
    [unit]
  );

  const formField = useMemo(
    () =>
      register(name, {
        ...(isRequired
          ? { required: { value: true, message: 'This field is required' } }
          : {}),
        ...(!_.isUndefined(max)
          ? { max: { value: max, message: `The maximum value is ${max}` } }
          : {}),
        ...(!_.isUndefined(min)
          ? { min: { value: min, message: `The minimum value is ${min}` } }
          : {}),
        ...(maxLength
          ? {
              maxLength: {
                value: maxLength,
                message: `The maximum length is ${maxLength}`,
              },
            }
          : {}),
        ...(minLength
          ? {
              minLength: {
                value: minLength,
                message: `The minimum length is ${minLength}`,
              },
            }
          : {}),
        shouldUnregister: true,
        ...registerOption,
      }),
    [isRequired, max, min, maxLength, minLength, registerOption]
  );

  return {
    isInvalid,
    formField,
    formLabel,
    formTooltip,
    formHelperText,
    formUnit,
    restProps,
    customFormLabel,
  };
};

export default useFormItem;
