import React, { useContext, memo } from 'react';

import Joyride, { Step, CallBackProps, ACTIONS } from 'react-joyride';
import { useRouter } from 'next/router';

import { TourContext } from 'hooks/useTour';
import { A7Code } from 'components/api7-components';
import { ApplicationData } from '@/types/Service';

import { StepStyle } from '../constant';
import Tooltip from '../Tooltip';

const step2TriggerList: Array<keyof ApplicationData<'form'>> = [
  'name',
  'labels',
  'description',
  'protocol',
  'path_prefix',
  'hosts',
];

const Task3: React.FC = () => {
  const {
    isStepRun,
    stepIndex,
    defaultHandleCallback,
    focusIndex,
    setIsStepRun,
    setStepIndex,
    formInstance,
  } = useContext(TourContext);
  const route = useRouter();
  const steps: Step[] = [
    {
      title: 'Welcome!',
      content: "Now let's create your first Service.",
      target: 'body',
      disableBeacon: true,
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
    {
      title: 'Step 1',
      content: 'Click here to create a new Service.',
      target: '#create-service',
      spotlightClicks: true,
    },
    {
      title: 'Step 2',
      placement: 'left',
      content: (
        <>
          <p style={{ marginBottom: '30px' }}>
            An Service contains essential attributes like supported protocols,
            path prefix, host, etc.
          </p>
          <p>
            Try to set Service Name to <A7Code>My HTTPBIN</A7Code>, set Protocol
            to <A7Code>HTTP</A7Code>, set Path Prefix to <A7Code>/v1</A7Code>,
            and set Host to <A7Code>api7-cloud.httpbin.org</A7Code>.
          </p>
        </>
      ),
      target: '#Basics',
      spotlightClicks: true,
      disableBeacon: true,
      styles: {
        tooltip: {
          width: '500px',
        },
      },
    },
    {
      title: 'Step 3',
      content: (
        <>
          <p style={{ marginBottom: '30px' }}>
            An upstream should also be configured for this Service as the
            destination.
          </p>
          <p>
            Try to set the Target URL to <A7Code>https://httpbin.org</A7Code>.
          </p>
        </>
      ),
      target: '#Upstream',
      spotlightClicks: true,
      placement: 'left',
      styles: {
        tooltip: {
          width: '500px',
        },
      },
    },
    {
      title: 'Step 4',
      content: 'Now you can click this button to create Service !',
      target: '#create-app-button',
      spotlightClicks: true,
    },
    {
      title: 'Summary',
      content:
        "Thanks for your participation! Now you've known how to create an Service.",
      target: 'body',
      disableBeacon: true,
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
  ];

  const handleCallback = async (data: CallBackProps) => {
    const { action, lifecycle } = data;
    if (action === ACTIONS.NEXT && stepIndex === 2 && focusIndex === 3) {
      const result = await formInstance?.trigger(step2TriggerList);
      if (!result) {
        setIsStepRun(false);
        setStepIndex(2);
        setIsStepRun(true);
        return;
      }
    }
    if (action === ACTIONS.NEXT && stepIndex === 3 && focusIndex === 3) {
      const result = await formInstance?.trigger('upstream');
      if (!result) {
        setIsStepRun(false);
        setStepIndex(3);
        setIsStepRun(true);
        return;
      }
    }
    if (
      action === ACTIONS.PREV &&
      stepIndex === 2 &&
      focusIndex === 3 &&
      lifecycle === 'complete'
    ) {
      route.back();
      setStepIndex(1);
      setIsStepRun(false);
      return;
    }
    defaultHandleCallback(data);
  };

  return (
    <Joyride
      callback={handleCallback}
      run={isStepRun}
      steps={steps}
      stepIndex={stepIndex}
      continuous
      disableOverlayClose
      styles={StepStyle}
      scrollOffset={300}
      tooltipComponent={Tooltip}
    />
  );
};

export default memo(Task3);
