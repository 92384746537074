import { API_BASE_URL } from '@/helper/config';

const logout = () => {
  const { origin } = window.location;
  const redirectUri = encodeURI(
    `${API_BASE_URL}/user/login?redirect_uri=${origin}`
  );
  localStorage.removeItem('A7_CLOUD_ORGS_ID');
  window.location.href = `${API_BASE_URL}/user/logout?redirect_uri=${redirectUri}`;
};
export default logout;
