import { Center } from '@chakra-ui/react';
import { animated, useSpring } from '@react-spring/web';
import NImage from 'next/image';

export const Loading: React.FC = () => {
  const props = useSpring({
    loop: { reverse: true },
    to: { opacity: 0 },
    from: { opacity: 1 },
    config: {
      duration: 1500,
    },
  });

  return (
    <animated.h1 style={props}>
      <Center width="full" height="100vh">
        <NImage width="279px" height="111px" src="/logo.svg" />
      </Center>
    </animated.h1>
  );
};
