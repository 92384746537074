import { memo } from 'react';

import {
  Box,
  Flex,
  SwitchProps as ChakraSwitchProps,
  Switch as ChakraSwitch,
} from '@chakra-ui/react';
import error from 'next/error';

import { Controller, useFormContext } from 'react-hook-form';
import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type SelectProps = DefaultProps & ChakraSwitchProps;

const Switch: React.FC<SelectProps> = ({ children, ...rest }) => {
  const { name, boxOption } = rest;
  const { control } = useFormContext();
  const { formField, formLabel, restProps } = useFormItem<SelectProps>({
    ...rest,
    labelOption: {
      mb: 0,
    },
  });

  return (
    <Box mb="32px" {...boxOption}>
      <Flex align="center">
        {formLabel}
        <Controller
          {...formField}
          control={control}
          shouldUnregister
          defaultValue={restProps.defaultValue}
          render={({ field: { onChange, value } }) => (
            <ChakraSwitch
              id={name}
              isInvalid={Boolean(error)}
              h="20px"
              w="32px"
              ml="10px"
              colorScheme="A7Green"
              {...formField}
              {...restProps}
              isChecked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
          )}
        />
      </Flex>
    </Box>
  );
};
export default memo(Switch);
