import axios from 'axios';
import useSWR from 'swr';

import useUser from './useUser';

type OrgsProps = {
  created_at: Date;
  id: string;
  name: string;
  owner_id: string;
  plan_expire_time: Date;
  plan_id: string;
  subscription_started_at: Date;
  updated_at: Date;
};

const useOrgs = () => {
  const { orgId, isLoading: isUserLoading } = useUser();

  const { data, error, mutate, isLoading } = useSWR<OrgsProps>(
    orgId ? `orgs/${orgId}` : null,
    (url: string) => axios.get(url).then((res) => res.data.payload)
  );

  return {
    data,
    reload: mutate,
    isLoading: isLoading || isUserLoading,
    isError: error,
  };
};

export default useOrgs;
