import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { useClickAway } from 'ahooks';
import {
  Dispatch,
  memo,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import A7Label, { getRandomColor } from '../api7-label';

export type LabelFilterProps = {
  filterList: (string | number)[];
  setFilter: Dispatch<SetStateAction<LabelFilterProps['filterList']>>;
  labelList: string[];
  tableRef: RefObject<HTMLTableElement>;
};

const LabelFilter: React.FC<LabelFilterProps> = ({
  filterList,
  setFilter,
  labelList,
  tableRef,
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(() => {
    onClose();
  }, containerRef);

  useEffect(() => {
    if (tableRef && tableRef.current)
      if (isOpen) {
        // eslint-disable-next-line no-param-reassign
        tableRef.current.style.overflow = 'hidden';
      } else {
        // eslint-disable-next-line no-param-reassign
        tableRef.current.style.overflow = 'auto';
      }
  }, [isOpen, tableRef]);

  return (
    <Box ref={containerRef}>
      <Popover strategy="fixed" closeOnBlur={false} isOpen={isOpen}>
        <PopoverTrigger>
          <Box textTransform="none">
            <Box
              cursor="pointer"
              data-cy="label-filter"
              py={3}
              px={6}
              onClick={onToggle}
            >
              <Box as="span">LABELS</Box>
              {Boolean(filterList.length) && (
                <Box ml="1px" color="A7Gray.500" as="span">
                  ({filterList.length})
                </Box>
              )}
              <Box ml="8px" display="inline-block">
                <TriangleUpIcon display={isOpen ? 'none' : ''} />
                <TriangleDownIcon display={!isOpen ? 'none' : ''} />
              </Box>
            </Box>
          </Box>
        </PopoverTrigger>
        {isOpen && (
          <PopoverContent border="none" w="auto" textTransform="none">
            <PopoverBody p={0}>
              {labelList && (
                <Flex
                  minW="164px"
                  bgColor="A7Gray.100"
                  direction="column"
                  boxShadow="2xl"
                  maxH="345px"
                  overflowY="auto"
                >
                  <Box
                    p="10px 18px"
                    color="A7Gray.700"
                    fontSize="sm"
                    borderBottom="1px solid #F0F0F0"
                  >
                    Filter By Labels
                  </Box>
                  <CheckboxGroup
                    colorScheme="A7Black"
                    onChange={(v) => {
                      setFilter(v);
                      onClose();
                    }}
                    value={filterList}
                  >
                    {labelList.map((item) => (
                      <Checkbox
                        value={item}
                        key={item}
                        p="10px 18px"
                        bgColor={filterList.includes(item) ? 'A7Gray.300' : ''}
                      >
                        <A7Label ml="10px" color={getRandomColor(item)}>
                          {item}
                        </A7Label>
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Flex>
              )}
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
};

export default memo(LabelFilter);
