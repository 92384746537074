import createInfiniteHook from '@/helper/utils/createInfiniteHook';
import useCluster from '@/hooks/useCluster';
import { ConsumerResponse } from '@/types/Consumer';
import { useMemo } from 'react';

const useConsumersInfinite = createInfiniteHook<ConsumerResponse>(() => {
  const { clusterId, isClusterReady } = useCluster({ dedupingInterval: 30000 });

  return useMemo(
    () => (isClusterReady ? `/clusters/${clusterId}/consumers` : null),
    [isClusterReady]
  );
});

export default useConsumersInfinite;
