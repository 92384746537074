import { memo } from 'react';

import { Box } from '@chakra-ui/react';

import A7InputPassword, { A7InputPasswordProps } from '../api7-input-password';
import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type PasswordProps = DefaultProps & Omit<A7InputPasswordProps, 'register'>;

const Password: React.FC<PasswordProps> = ({ children, ...rest }) => {
  const { boxOption } = rest;
  const { isInvalid, formField, formLabel, formHelperText, restProps } =
    useFormItem<PasswordProps>(rest);
  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <A7InputPassword
        isInvalid={isInvalid}
        {...restProps}
        register={formField}
      />
      {formHelperText}
    </Box>
  );
};
export default memo(Password);
