import useUser from '@/hooks/useUser';
import { useLocalStorageState } from 'ahooks';
import { useEffect } from 'react';
import { MAX_RECENT_SEARCH_COUNT } from '../constants';
import { RecentSearchItem, RecentSearchList } from '../types';

const useRecentSearch = () => {
  const { data: userData } = useUser();
  const userId = userData?.id;
  const [resentSearchList, setResentSearchList] =
    useLocalStorageState<RecentSearchList>('resent-search', {
      defaultValue: {},
    });
  const userSearchList = userId ? resentSearchList[userId] || [] : [];

  const removeRecord = (recordIndex: number) => {
    setResentSearchList({
      ...resentSearchList,
      [userId as string]: userSearchList.filter((item, i) => i !== recordIndex),
    });
  };

  const addRecord = (newRecord: RecentSearchItem) => {
    const isExist =
      userSearchList.findIndex(
        (item) => item.basePath === newRecord.basePath
      ) !== -1;

    if (isExist) {
      return;
    }

    setResentSearchList({
      ...resentSearchList,
      [userId as string]: [
        newRecord,
        ...(userSearchList.length === MAX_RECENT_SEARCH_COUNT
          ? userSearchList.slice(0, 9)
          : userSearchList),
      ],
    });
  };

  const removeAllRecord = () => {
    setResentSearchList({
      ...resentSearchList,
      [userId as string]: [],
    });
  };

  useEffect(() => {
    // Initialize if no recent search list exists for the current user
    if (userId && !resentSearchList[userData.id]) {
      setResentSearchList((currentSearchList) => ({
        ...currentSearchList,
        [userData.id]: [],
      }));
    }
  }, [userData]);

  return { userSearchList, addRecord, removeRecord, removeAllRecord };
};

export default useRecentSearch;
