import {
  ReferencesTableColumn,
  ResourceType,
} from 'pages/cluster/plugin-management/types';

const generateResourceLink = ({
  parent_id,
  resource_id,
}: ReferencesTableColumn): Record<ResourceType, string> => ({
  APPLICATION: `/api-management/services/${resource_id}`,
  API: `/api-management/services/${parent_id}/route/${resource_id}`,
  CONSUMER: `/api-management/consumers/${resource_id}`,
  CLUSTER: `/cluster/settings`,
});

export default generateResourceLink;
