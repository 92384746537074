import _ from 'lodash';

type TimeFormaterProps = {
  value?: number;
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
};

export const timeFormatter = ({ value, unit }: TimeFormaterProps) => {
  if (value === undefined || unit === undefined || Number.isNaN(value)) {
    return '';
  }

  if (value <= 1) {
    return `${value} ${_.startCase(unit)}`;
  }

  return `${value} ${_.startCase(unit)}s`;
};
