import { Box, Flex, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { A7Button } from '../api7-components';
import { RecordIcon } from '../api7-components/api7-icon';
import useKeyBoard from './hooks/useKeyBoard';
import useRecentSearch from './hooks/useRecentSearch';
import SearchItem from './SearchItem';
import { RecentSearchItem } from './types';

const RecentSearch: React.FC<{
  queryText: string;
  isComposing: boolean;
  onClose: () => void;
}> = ({ onClose, isComposing }) => {
  const router = useRouter();
  const [currentSelect, setCurrentSelect] = useState<number>(-1);
  const getSelected = (index: number) => index === currentSelect;

  const showResourceDetail = (resourceItem: RecentSearchItem) => {
    router.push(resourceItem.basePath);
    onClose();
  };

  const { userSearchList, removeAllRecord, removeRecord } = useRecentSearch();

  const { isCursorShow, showCursor } = useKeyBoard({
    isComposing,
    onArrowDown: () => {
      if (currentSelect !== userSearchList.length - 1) {
        setCurrentSelect(currentSelect + 1);
      }
    },
    onArrowUp: () => {
      if (currentSelect !== 0) {
        setCurrentSelect(currentSelect - 1);
      }
    },
    onEnter: () => {
      showResourceDetail(userSearchList[currentSelect]);
    },
  });

  return (
    <Box mt="16px" onMouseMove={showCursor}>
      {userSearchList.length ? (
        <Box>
          <Flex
            justify="space-between"
            align="center"
            textTransform="uppercase"
            mb="12px"
          >
            <Text fontSize="sm">Recent</Text>
            <A7Button
              type="delete"
              theme="ghost"
              bgColor="transparent"
              iconOption={{
                width: '20px',
                height: '20px',
              }}
              onClick={removeAllRecord}
            />
          </Flex>
          {userSearchList.map((item, index) => {
            const isSelected = getSelected(index);
            return (
              <SearchItem
                justify="space-between"
                isSelected={isSelected}
                isCursorShow={isCursorShow}
                key={item.basePath}
                onClick={() => {
                  showResourceDetail(item);
                }}
                onMouseEnter={() => {
                  if (isCursorShow) {
                    setCurrentSelect(index);
                  }
                }}
              >
                <Flex
                  align="center"
                  maxW="400px"
                  noOfLines={1}
                  wordBreak="break-all"
                >
                  <RecordIcon
                    width="24px"
                    height="24px"
                    fill={isSelected ? 'white' : 'A7Gray.800'}
                  />
                  <Text as="span" ml="8px">
                    {item.name}
                  </Text>
                </Flex>

                <Flex align="center">
                  <Text
                    mr="16px"
                    fontSize="12px"
                    color="A7Gray.400"
                    noOfLines={1}
                    wordBreak="break-all"
                  >
                    {item.resourceType}
                  </Text>
                  <A7Button
                    borderRadius="50%"
                    type="close"
                    theme={isSelected ? 'dark' : 'ghost'}
                    backgroundColor="transparent"
                    _active={{
                      backgroundColor: 'rgba(210, 210, 210, 0.2)',
                    }}
                    _hover={{
                      backgroundColor: 'rgba(210, 210, 210, 0.2)',
                    }}
                    color={isSelected ? 'white' : 'A7Gray.800'}
                    minW="26px"
                    h="26px"
                    iconOption={{
                      width: '20px',
                      height: '20px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeRecord(index);
                    }}
                  />
                </Flex>
              </SearchItem>
            );
          })}
        </Box>
      ) : (
        <Flex h="120px" justify="center" align="center" color="A7Gray.600">
          NO RECENT SEARCHES
        </Flex>
      )}
    </Box>
  );
};

export default RecentSearch;
