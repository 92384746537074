import getConfig from 'next/config';

const { publicRuntimeConfig = {} } = getConfig() || {};

export const {
  API_BASE_URL,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  DISABLED_SIGNUP,
  FEED_BACK_URL,
  INTERCOM_APP_ID,
} = publicRuntimeConfig;

export const helperCenterLink =
  'https://docs.api7.ai/cloud/overview/api7-cloud';

export const feedbackLink = FEED_BACK_URL;
