import { memo } from 'react';

import {
  Box,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';

import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type TextareaProps = DefaultProps & ChakraTextareaProps;
const Textarea = ({ children, ...rest }: TextareaProps) => {
  const { name, boxOption } = rest;
  const { isInvalid, formField, formHelperText, formLabel, restProps } =
    useFormItem<TextareaProps>(rest);
  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <ChakraTextarea
        w="100%"
        id={name}
        isInvalid={isInvalid}
        {...formField}
        {...restProps}
      />
      {formHelperText}
    </Box>
  );
};

export default memo(Textarea);
