import { DeepPartial, Theme } from '@chakra-ui/react';

export const colorMap = {
  A7Black: {
    50: '#000',
    100: '#000',
    200: '#000',
    300: '#000',
    400: '#000',
    500: '#000',
    600: '#000',
    700: '#000',
    800: '#000',
    900: '#000',
  },
  A7White: {
    50: '#FFFFFF',
    100: '#FFFFFF',
    200: '#FFFFFF',
    300: '#FFFFFF',
    400: '#FFFFFF',
    500: '#FFFFFF',
    600: '#FFFFFF',
    700: '#FFFFFF',
    800: '#FFFFFF',
    900: '#FFFFFF',
  },
  A7Gray: {
    50: '#FCFCFC',
    100: '#F7F7F7',
    200: '#F0F0F0',
    300: '#E0E0E0',
    400: '#C0C0C0',
    500: '#969696',
    600: '#686868',
    700: '#484848',
    800: '#2C2C2C',
    900: '#232323',
  },
  A7Green: {
    50: '#F7FFF9',
    100: '#E1FAE5',
    200: '#CBF5D1',
    300: '#95EDA3',
    400: '#60E074',
    500: '#32D74B',
    600: '#1FA333',
    700: '#11701F',
    800: '#074210',
    900: '#022408',
  },
  A7Red: {
    50: '#FFF8F7',
    100: '#FFE2E0',
    200: '#FDA19D',
    300: '#FA7069',
    400: '#F84037',
    500: '#F80D05',
    600: '#C40A04',
    700: '#910601',
    800: '#5E0401',
    900: '#2B0100',
  },
  A7Blue: {
    100: '#D6EAFF',
    300: '#70B7FF',
    500: '#0A84FF',
    600: '#086ACC',
    700: '#064f99',
    800: '#043566',
  },
  A7Yellow: {
    100: '#FFF8D6',
    200: '#FFF0A3',
    300: '#FFE770',
    400: '#FFDF3D',
    500: '#CCAB08',
    700: '#998006',
    800: '#665202',
  },
  A7Orange: {
    800: '#664004',
    100: '#FFEFD6',
  },
  A7Cyan: {
    100: '#D6F3FF',
    800: '#0C445C',
  },
  A7Indigo: {
    100: '#E4E4F5',
    500: '#5E5CE6',
    800: '#161466',
  },
  A7Purple: {
    100: '#F4E3FD',
    800: '#460766',
  },
  A7Pink: {
    100: '#FFE0ED',
    800: '#800A39',
  },
  A7NewBlue: {
    50: '#F7FBFF',
    100: '#C4E2FF',
    200: '#ABD5FF',
    300: '#ADD6FF',
    400: '#99CCFF',
    500: '#5EAEFF',
    600: '#4197EE',
    700: '#2A6199',
    800: '#1C4166',
    900: '#0E2033',
  },
  A7NewPurple: {
    50: '#FCFAFF',
    100: '#F5F0FF',
    200: '#EBE0FF',
    300: '#D0B8FF',
    400: '#CCB2FF',
    500: '#B894FF',
    600: '#A070FF',
    700: '#604399',
    800: '#402D66',
    900: '#201633',
  },
  A7NewYellow: {
    500: '#FFC770',
  },
};

/** extend additional color here */
const extendedColors: DeepPartial<
  Record<string, Theme['colors']['blackAlpha']>
> = {
  ...colorMap,
};

/** override chakra colors here */
const overridenChakraColors: DeepPartial<Theme['colors']> = {};

const colors = {
  ...overridenChakraColors,
  ...extendedColors,
};

export default colors;
