import { memo, ReactNode, useEffect, useMemo, useState } from 'react';

import { SWRConfig } from 'swr';
import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { StickyContainer, Sticky } from 'react-sticky';

import { Loading } from 'components/loading';
import { fetchPlanStatus } from 'helper/service';
import useUser from 'hooks/useUser';
import useRouteGuard from 'hooks/useRouteGuard';

import Header, { Plan } from './Header';
import InitializeOrganization from './initialize-organization';
import BodyContent from './BodyContent';
import OrgRemovedModal from './OrgRemovedModal';

type LayoutProps = {
  children: ReactNode;
};

const BILLING_PATH = '/billing-callback';
const USER_PROFILE_PATH = '/user-profile';

const InitOrg = memo(() => <InitializeOrganization />);

const Layout = ({ children }: LayoutProps) => {
  const { pathname } = useRouter();

  // if pathname === '/billing?xxx' sideMenu will be hidden and header will be hidden
  // global loading will be disabled
  const isBillingCallback = [BILLING_PATH].includes(pathname);
  const isUserProfile = [USER_PROFILE_PATH].includes(pathname);
  const [planName, setPlanName] = useState<Plan>();
  const isHiddenHeader = isBillingCallback;
  const [showInitOrg, setInitOrg] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(true);
  const { orgId, isError } = useUser();
  const { isLoading, isAllow } = useRouteGuard();

  useEffect(() => {
    if (!isLoading) {
      if (!orgId && !isError) {
        setInitOrg(true);
        setIsShowLoading(false);
      }
      if (orgId) {
        setInitOrg(false);
        fetchPlanStatus({ orgId }).then((data) => {
          setPlanName(data.plan_name as Plan);
        });
        setIsShowLoading(false);
      }
    }
  }, [orgId, isLoading]);

  const showLoading = useMemo(() => {
    if (isShowLoading && !isHiddenHeader) {
      return true;
    }
    return false;
  }, [isShowLoading, isHiddenHeader]);

  const showContent = useMemo(
    () => !isShowLoading,
    [isShowLoading, isBillingCallback]
  );

  const showHeader = useMemo(
    () => !isHiddenHeader && !showLoading,
    [isHiddenHeader, showLoading]
  );

  const showChildren = useMemo(() => {
    if (isBillingCallback) {
      return true;
    }
    if (isUserProfile) {
      return true;
    }
    return !showInitOrg;
  }, [isBillingCallback, showInitOrg, isUserProfile]);

  const showSideMenu = useMemo(() => {
    if (isBillingCallback) {
      return false;
    }
    return true;
  }, [isBillingCallback]);

  return (
    <StickyContainer>
      <SWRConfig value={{ provider: () => new Map() }}>
        <Box
          margin="0"
          width="fit-content"
          minWidth="full"
          transition="0.5s ease-out"
          height="fit-content"
          backgroundColor="#fff"
          position="relative"
        >
          {showLoading && <Loading />}
          {showContent && (
            <Box width="full">
              <OrgRemovedModal />
              {showHeader && (
                <Sticky>
                  {({ style }) => (
                    <Box width="full" style={style} zIndex="sticky">
                      <Header planName={planName} />
                    </Box>
                  )}
                </Sticky>
              )}
              {showInitOrg && !isUserProfile && <InitOrg />}
              {showChildren && (
                <BodyContent isAllow={isAllow} showSideMenu={showSideMenu}>
                  {children}
                </BodyContent>
              )}
            </Box>
          )}
        </Box>
      </SWRConfig>
    </StickyContainer>
  );
};

export default Layout;
