import { useBoolean } from 'ahooks';
import { useHotkeys } from 'react-hotkeys-hook';

const useKeyBoard = ({
  isComposing,
  onArrowUp,
  onArrowDown,
  onEnter,
}: {
  isComposing: boolean;
  onArrowUp: () => void;
  onArrowDown: () => void;
  onEnter: () => void;
}) => {
  const [isCursorShow, { setFalse: hideCursor, setTrue: showCursor }] =
    useBoolean(true);

  // For binding up and down arrow key operation
  useHotkeys(
    ['down', 'up'],
    ({ key }) => {
      if (key === 'ArrowDown') {
        // When the last item of the current resource is selected
        onArrowDown();
      }
      if (key === 'ArrowUp') {
        onArrowUp();
      }

      setTimeout(() => {
        const currentSelectDom = document.getElementsByClassName('is-selected');
        // If the resource is in modal unseen when you use the keyboard to switch resource items, scroll to the location of the item.
        currentSelectDom[0]?.scrollIntoView({
          block: 'center',
          behavior: 'auto',
        });
      }, 0);

      // When using the keyboard, to prevent the mouse from conflicting with the keyboard's selection event, the mouse will be hidden until the mouse moves again
      hideCursor();
    },
    { enableOnFormTags: ['INPUT'], preventDefault: true }
  );

  // For binding enter key operation
  useHotkeys(
    'enter',
    () => {
      if (!isComposing) {
        onEnter();
      }
    },
    {
      enableOnFormTags: ['INPUT'],
      preventDefault: true,
    }
  );

  return { isCursorShow, showCursor };
};

export default useKeyBoard;
