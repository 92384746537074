import { memo } from 'react';

import {
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  Image,
} from '@chakra-ui/react';

import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type NumberProps = DefaultProps &
  NumberInputProps & { showStep?: boolean; isControlMode?: boolean };
const NumberComponent = ({ children, ...rest }: NumberProps) => {
  const { name, placeholder, boxOption } = rest;
  const {
    isInvalid,
    formField,
    formHelperText,
    formLabel,
    formUnit,
    restProps,
  } = useFormItem<NumberProps>({
    ...rest,
    registerOption: {
      valueAsNumber: true,
      // Fix max not clearing limit when max goes from number to undefine
      ...(!_.isUndefined(rest?.max)
        ? {
            max: {
              value: rest?.max,
              message: `The maximum value is ${rest?.max}`,
            },
          }
        : { max: Number.MAX_SAFE_INTEGER }),
      ...(rest.registerOption || {}),
    },
  });
  const { control } = useFormContext();

  if (rest?.isControlMode) {
    return (
      <Box mb="32px" {...boxOption}>
        {formLabel}
        <Flex align="center">
          <Controller
            control={control}
            name={name}
            defaultValue={rest.defaultValue}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                id={name}
                isInvalid={isInvalid}
                w="100%"
                {...restProps}
                bgColor="#fff"
                min={rest.min !== undefined ? (rest.min as number) : undefined}
                max={rest.max !== undefined ? (rest.max as number) : undefined}
                value={Number.isNaN(value) || !value ? '' : value}
                onChange={onChange}
              >
                <NumberInputField {...formField} placeholder={placeholder} />
                {rest.showStep && (
                  <NumberInputStepper>
                    <NumberIncrementStepper>
                      <Image src="/icons/arrow-up-line.svg" alt="arrow up" />
                    </NumberIncrementStepper>
                    <NumberDecrementStepper>
                      <Image
                        src="/icons/arrow-down-line.svg"
                        alt="arrow down"
                      />
                    </NumberDecrementStepper>
                  </NumberInputStepper>
                )}
              </NumberInput>
            )}
          />
          {formUnit}
        </Flex>
        {formHelperText}
      </Box>
    );
  }

  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <Flex align="center">
        <NumberInput
          id={name}
          isInvalid={isInvalid}
          w="100%"
          {...restProps}
          bgColor="#fff"
          min={rest.min !== undefined ? (rest.min as number) : undefined}
          max={rest.max !== undefined ? (rest.max as number) : undefined}
        >
          <NumberInputField {...formField} placeholder={placeholder} />
          {rest.showStep && (
            <NumberInputStepper>
              <NumberIncrementStepper>
                <Image src="/icons/arrow-up-line.svg" alt="arrow up" />
              </NumberIncrementStepper>
              <NumberDecrementStepper>
                <Image src="/icons/arrow-down-line.svg" alt="arrow down" />
              </NumberDecrementStepper>
            </NumberInputStepper>
          )}
        </NumberInput>
        {formUnit}
      </Flex>

      {formHelperText}
    </Box>
  );
};

export default memo(NumberComponent);
