import React, { useContext } from 'react';

import {
  Image,
  Box,
  useDisclosure,
  ScaleFade,
  Text,
  Progress,
  Flex,
  Button,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import { TourContext } from 'hooks/useTour';
import { A7Modal } from 'components/api7-components';

import Complete from './Complete';

const TipButton = () => (
  <Image
    cursor="pointer"
    position="fixed"
    right="13px"
    bottom="70px"
    borderRadius="full"
    boxSize="64px"
    src="/icons/tour-tip-button.svg"
    zIndex={2000}
    data-cy="tour-tip-button"
  />
);

type TourListCardProps = {
  onCloseClick: () => void;
  onOpen: () => void;
};

const TourListCard: React.FC<TourListCardProps> = ({
  onCloseClick,
  onOpen,
}) => {
  const {
    progress,
    tasks,
    updateTaskStatus,
    setFocusIndex,
    setStepIndex,
    setIsStepRun,
  } = useContext(TourContext);

  const handleClick = (index: number) => {
    onCloseClick();
    setStepIndex(0);
    updateTaskStatus(index, 'inProgress');
    setFocusIndex(index + 1);
    setIsStepRun(true);
  };

  return (
    <Box
      position="fixed"
      data-cy="tour-plane"
      w="398px"
      h="660px"
      bgColor="A7Gray.50"
      borderRadius="12px"
      boxShadow="0px 4px 16px rgba(0, 0, 0, 0.12)"
      right="20px"
      bottom="60px"
      zIndex={2000}
    >
      <Image
        position="absolute"
        src="/icons/tour-head.svg"
        top="-70px"
        right="28px"
      />
      <CloseIcon
        w="14px"
        h="14px"
        color="#ffffff"
        position="absolute"
        top="12px"
        right="12px"
        cursor="pointer"
        onClick={onCloseClick}
        data-cy="close-plane"
      />
      <Box
        w="full"
        h="172px"
        bgColor="A7Indigo.500"
        borderRadius="12px 12px 0 0"
      >
        <Text
          lineHeight="27px"
          color="#ffffff"
          fontSize="22px"
          fontWeight="700"
          w="311px"
          position="relative"
          top="20px"
          left="20px"
        >
          API7 Cloud On Boarding Tour
        </Text>
        <Box w="358px" mx="auto" position="relative" top="25px" minH="48px">
          <Text color="A7Gray.200" fontSize="16px" fontWeight="500">
            Experience the On-Boarding tour to learn how to use API7 Cloud!
          </Text>
        </Box>
        <Progress
          colorScheme="A7NewYellow"
          value={progress}
          w="358px"
          h="17px"
          borderRadius="17px"
          mt="45px"
          mx="auto"
        />
        {/* 330 is the width of Progress component */}
        <Text
          position="absolute"
          data-cy="tour-progress"
          top="118px"
          left={progress === 0 ? '28px' : `${330 * (progress / 100)}px`}
          fontSize="14px"
          fontWeight="500"
        >
          {progress}%
        </Text>
        <Image src="/icons/tour-decorate.svg" />
      </Box>
      <Flex direction="column" mt="20px" w="full">
        {tasks.map((item, index) => (
          <Flex
            key={item.name}
            data-cy="tour-list-item"
            w="358px"
            h="60px"
            borderRadius="8px"
            boxShadow="0px 1px 8px rgba(0, 0, 0, 0.08)"
            mb="12px"
            mx="auto"
            alignItems="center"
            cursor="pointer"
            pl="12px"
            onClick={() => handleClick(index)}
            _hover={{
              bgColor: 'A7Gray.200',
              '&>p': {
                color: 'A7Gray.900',
              },
            }}
          >
            <Flex alignItems="center" justify="center">
              {item.status === 'todo' && (
                <>
                  <Image
                    data-cy="tour-list-item-image"
                    src="/icons/tour-todo.svg"
                  />
                  <Text
                    zIndex={2001}
                    mt="1px"
                    fontSize="11px"
                    color="#ffffff"
                    fontWeight="500"
                    position="absolute"
                  >
                    {index + 1}
                  </Text>
                </>
              )}
              {item.status === 'inProgress' && (
                <Image
                  data-cy="tour-list-item-image"
                  src="/icons/tour-inprogress.svg"
                />
              )}
              {item.status === 'done' && (
                <Image
                  data-cy="tour-list-item-image"
                  src="/icons/tour-done.svg"
                />
              )}
            </Flex>
            <Text
              ml="12px"
              fontSize="16px"
              color={item.status === 'done' ? 'A7Gray.400' : 'A7Gray.900'}
              fontWeight="500"
            >
              {item.name}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Button
        size="sm"
        bgColor="#ffffff"
        _hover={{ bgColor: '#ffffff' }}
        _active={{ bgColor: '#ffffff' }}
        color="A7Gray.600"
        fontSize="14px"
        position="absolute"
        right="23px"
        onClick={() => {
          onCloseClick();
          onOpen();
        }}
      >
        Dismiss
      </Button>
    </Box>
  );
};

const Plane = () => {
  const { progress, updateTourStatus, isCardOpen, onCardToggle } =
    useContext(TourContext);
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();

  return (
    <Box>
      <A7Modal
        size="lg"
        isOpen={isModalOpen}
        title="Dismiss"
        okText="Keep it"
        cancelText="Dismiss it"
        okTheme="indigo"
        showDivider={false}
        showCloseIcon={false}
        onOk={onModalClose}
        closeOnOverlayClick={false}
        onClose={() => {
          updateTourStatus(false);
          onModalClose();
        }}
      >
        Are you sure you want to dismiss this guide?
      </A7Modal>
      <Box onClick={onCardToggle} cursor="pointer">
        <TipButton />
      </Box>

      <ScaleFade in={isCardOpen === 'open'}>
        {progress === 100 ? (
          <Complete onOpen={onModalOpen} onCloseClick={onCardToggle} />
        ) : (
          <TourListCard onCloseClick={onCardToggle} onOpen={onModalOpen} />
        )}
      </ScaleFade>
    </Box>
  );
};

export default Plane;
