import React, { useContext, memo } from 'react';

import Joyride, { Step } from 'react-joyride';

import { TourContext } from 'hooks/useTour';
import CodeBlock from 'components/api7-components/api7-codeblock';

import { StepStyle } from '../constant';
import Tooltip from '../Tooltip';

const code = `curl http://127.0.0.1:9080/v1/get -H 'Host: api7-cloud.httpbin.org' -i`;

const Task5: React.FC = () => {
  const { isStepRun, stepIndex, defaultHandleCallback } =
    useContext(TourContext);

  const steps: Step[] = [
    {
      target: 'body',
      title: 'Test Your Route!',
      disableBeacon: true,
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
      content: (
        <>
          <p style={{ marginBottom: '30px' }}>
            {`Since you've deployed the API Gateway instance and created your
            first Service and Route, it's time to test it. You can use
            cURL or other utilities to send API requests to your API Gateway and
            see if the response is expected.`}
          </p>
          <CodeBlock width="538px" code={code} copyCode={code} />
        </>
      ),
    },
  ];

  return (
    <Joyride
      callback={defaultHandleCallback}
      run={isStepRun}
      steps={steps}
      stepIndex={stepIndex}
      continuous
      scrollToFirstStep
      disableOverlayClose
      styles={StepStyle}
      tooltipComponent={Tooltip}
    />
  );
};

export default memo(Task5);
