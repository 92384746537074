import React, { useMemo } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Text, Tooltip } from '@chakra-ui/react';
import Select, { StylesConfig, components, OptionProps } from 'react-select';

import A7Label, { getRandomColor, LabelColor } from '../api7-label';

const style: StylesConfig = {
  clearIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'flex-start',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    border: 'none',
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    maxWidth: '200px',
    overflowX: 'auto',
    padding: 'unset',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: '#F7F7F7',
  }),
  menu: (provided) => ({
    ...provided,
    width: 'unset',
    minWidth: '230px',
    alignItems: 'end',
    margin: 0,
    borderRadius: '2px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

const Input: React.FC<any> = ({ ...props }) => (
  <components.Input {...props} readOnly />
);

const MultiValueRemove = () => null;
const IndicatorSeparator = () => null;

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <A7Label color="gray">
      <ChevronDownIcon />
    </A7Label>
  </components.DropdownIndicator>
);

type LabelListProps = {
  color?: LabelColor;
  limitCount: number;
  data: string[];
  asLabel?: boolean;
};

const A7LabelList: React.FC<LabelListProps> = ({
  color,
  limitCount,
  data,
  asLabel = true,
}) => {
  const labelData = useMemo(
    () =>
      data.slice(0, limitCount).map((item) => ({
        label: item,
        value: item,
      })),
    [data, limitCount]
  );
  const listData = useMemo(
    () =>
      data.slice(limitCount, data.length).map((item) => ({
        label: item,
        value: item,
      })),
    [data, limitCount]
  );
  // eslint-disable-next-line react/no-unstable-nested-components, react/require-default-props
  const MultiValueContainer: React.FC<any> = (state) => {
    const { data: childData } = state;
    const defaultColor = getRandomColor(childData.label as string);
    return (
      <A7Label color={color || defaultColor} {...state} mr="4px">
        <Tooltip
          bg="A7Gray.900"
          placement="top"
          hasArrow
          label={childData.label}
        >
          {childData.label}
        </Tooltip>
      </A7Label>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Option: React.FC<OptionProps> = ({ children, ...rest }) => {
    const labelColor = getRandomColor(children as string);
    if (asLabel) {
      return (
        <components.Option {...rest}>
          <A7Label color={color || labelColor}>{children}</A7Label>
        </components.Option>
      );
    }
    return (
      <Text
        minH="40px"
        bgColor="AGray.100"
        lineHeight="5"
        p="10px 16px"
        fontSize="sm"
        color="A7Gray.700"
        userSelect="all"
      >
        {children}
      </Text>
    );
  };

  return listData.length ? (
    <Select
      isMulti
      value={labelData}
      options={listData}
      menuPosition="fixed"
      styles={style}
      components={{
        MultiValueContainer,
        Input,
        MultiValueRemove,
        IndicatorSeparator,
        DropdownIndicator,
        Option,
      }}
    />
  ) : (
    <>
      {labelData.map((item) => (
        <A7Label
          color={color || getRandomColor(item.label)}
          mr="4px"
          key={item.value}
        >
          {item.label}
        </A7Label>
      ))}
    </>
  );
};

export default A7LabelList;
