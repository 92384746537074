import { memo } from 'react';

import {
  Box,
  RadioGroup,
  Stack,
  Radio as ChakraRadio,
  Text,
} from '@chakra-ui/react';

import { Controller, useFormContext } from 'react-hook-form';
import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type RadioProps = DefaultProps & {
  options: Array<{ label: string; value: string; disable?: boolean }>;
};

const Radio: React.FC<RadioProps> = ({ children, ...rest }) => {
  const { name, boxOption } = rest;
  const { control } = useFormContext();
  const { formField, formLabel } = useFormItem<RadioProps>({
    ...rest,
    labelOption: {
      mb: 0,
    },
  });

  return (
    <Box mb="32px" {...boxOption}>
      <Box>
        {formLabel}
        <Controller
          {...formField}
          control={control}
          shouldUnregister
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              colorScheme="A7Black"
              name={name}
              value={value}
              defaultValue={rest.options[0].value}
              onChange={(e) => {
                onChange(e);
              }}
              mt="9px"
            >
              <Stack spacing={4} direction="row">
                {rest.options.map((item) => (
                  <ChakraRadio
                    value={item.value}
                    isDisabled={item.disable}
                    fontSize="14px"
                    fontWeight={400}
                    key={item.value}
                  >
                    <Text fontSize="14px">{item.label}</Text>
                  </ChakraRadio>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </Box>
    </Box>
  );
};
export default memo(Radio);
