import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
} from 'react';

type BreadcrumbType = {
  name?: string;
  path: string;
}[];

export const BreadcrumbContext = createContext<{
  breadcrumb: BreadcrumbType;
  setBreadcrumb: Dispatch<SetStateAction<BreadcrumbType>>;
}>({ breadcrumb: [], setBreadcrumb: () => {} });

const useBreadcrumb = () => {
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbType>([]);

  const breadcrumbProvide = useMemo(
    () => ({ breadcrumb, setBreadcrumb }),
    [breadcrumb]
  );

  return { breadcrumbProvide };
};

export default useBreadcrumb;
