import { ComponentStyleConfig } from '@chakra-ui/react';

const baseStyle: ComponentStyleConfig['baseStyle'] = {
  borderRadius: '2px',
  height: '32px',
};

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: '5',
  },
};

const FormHelperText: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
  },
};

const Input: ComponentStyleConfig = {
  baseStyle: {
    field: baseStyle,
  },
  sizes: {
    sm: {
      field: baseStyle,
    },
  },
  defaultProps: {
    size: 'sm',
  },
};

const Textarea: ComponentStyleConfig = {
  baseStyle: {
    field: baseStyle,
  },
  sizes: {
    sm: {
      field: baseStyle,
    },
  },
  defaultProps: {
    size: 'sm',
  },
};

const Select: ComponentStyleConfig = {
  baseStyle: {
    field: baseStyle,
  },
  sizes: {
    sm: {
      field: baseStyle,
    },
  },
  defaultProps: {
    size: 'sm',
  },
};

const NumberInput: ComponentStyleConfig = {
  baseStyle: {
    field: baseStyle,
  },
  sizes: {
    sm: {
      field: baseStyle,
    },
  },
  defaultProps: {
    size: 'sm',
  },
};

export default {
  Input,
  Textarea,
  Select,
  NumberInput,
  FormLabel,
  FormHelperText,
};
