export type RouteProps = {
  name: string;
  path: string;
  access?: string[];
  hidden?: boolean;
  child?: RouteProps;
  clusterDependent?: boolean;
}[];

const Route: RouteProps = [
  {
    name: 'Overview',
    path: '/overview',
  },
  {
    name: 'Cluster',
    path: '/cluster',
    clusterDependent: true,
    child: [
      {
        name: 'Settings',
        path: '/cluster/settings',
      },
      {
        name: 'Plugin Management',
        path: '/cluster/plugin-management',
      },
    ],
  },
  {
    name: 'Monitoring',
    path: '/monitoring',
    clusterDependent: true,
  },
  {
    name: 'API Management',
    path: '/api-management',
    clusterDependent: true,
    child: [
      {
        name: 'Services',
        path: '/api-management/services',
        access: ['api_management.app.get'],
        child: [
          {
            name: 'Service Detail',
            path: '/api-management/services/[id]',
            access: ['api_management.app.get'],
            child: [
              {
                name: 'Route',
                path: '/api-management/services/[id]/route/[routeId]',
                access: ['api_management.api.get'],
              },
              {
                name: 'Create Route',
                path: '/api-management/services/[id]/route/create',
                access: ['api_management.api.post'],
              },
            ],
          },
          {
            name: 'Create Service',
            path: '/api-management/services/create',
            access: ['api_management.app.post'],
          },
        ],
      },
      {
        name: 'Consumers',
        path: '/api-management/consumers',
        access: ['api_management.consumer.get'],
        child: [
          {
            name: 'Consumer Detail',
            path: '/api-management/consumers/[id]',
            access: ['api_management.consumer.get'],
          },
          {
            name: 'Create Consumer',
            path: '/api-management/consumers/create',
            access: ['api_management.consumer.post'],
          },
        ],
      },
      {
        name: 'SSL',
        path: '/api-management/ssl',
        access: ['api_management.certificate.get'],
        child: [
          {
            name: 'SSL Detail',
            path: '/api-management/ssl/[id]',
            access: ['api_management.certificate.get'],
          },
        ],
      },
      {
        name: 'Log Collection Plans',
        path: '/api-management/log-collection',
        access: ['api_management.log_collection.get'],
      },
    ],
  },
  {
    name: 'Billing & Plan',
    path: '/billing',
    access: ['billing.billing.get'],
  },
  {
    name: 'Organization',
    path: '/organization',
    access: ['organization.member.get'],
  },
  {
    name: 'Alert Management',
    path: '/alert',
    access: ['alert_management.alert_policy.get'],
    child: [
      {
        name: 'Alert Policies',
        path: '/alert/policies',
        access: ['alert_management.alert_policy.get'],
      },
      {
        name: 'Alert History',
        path: '/alert/history',
        access: ['alert_management.alert_policy.get'],
      },
    ],
  },
];

export default Route;
