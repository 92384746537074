import createInfiniteHook from '@/helper/utils/createInfiniteHook';
import useCluster from '@/hooks/useCluster';
import { ApplicationResponse } from '@/types/Service';
import { useMemo } from 'react';

const useServicesInfinite = createInfiniteHook<ApplicationResponse>(() => {
  const { clusterId, isClusterReady } = useCluster({
    dedupingInterval: 30000,
  });

  return useMemo(
    () => (isClusterReady ? `/clusters/${clusterId}/apps` : null),
    [isClusterReady]
  );
});

export default useServicesInfinite;
