import { ClusterDetail } from '@/types/Cluster';
import axios from 'axios';
import _ from 'lodash';
import useSWR from 'swr';

import useCluster from './useCluster';

const useClusterDetail = () => {
  const {
    clusterId,
    isClusterReady,
    isLoading: isClusterLoading,
  } = useCluster();

  const {
    data: clusterData,
    error,
    mutate,
    isValidating,
    isLoading,
  } = useSWR<ClusterDetail>(
    isClusterReady ? `/clusters/${clusterId}` : null,
    (url: string) => axios.get(url).then((res) => res.data.payload)
  );

  if (clusterData?.plugins) {
    let plugins = _.cloneDeep(clusterData?.plugins);
    if (Object.keys(plugins?.vanilla || {}).length) {
      plugins = { ...plugins, ...plugins?.vanilla };
      delete plugins.vanilla;
    }
    clusterData.plugins = plugins;
  }

  return {
    data: clusterData,
    reload: mutate,
    isLoading: isLoading || isClusterLoading,
    isValidating,
    isError: error,
  };
};

export default useClusterDetail;
