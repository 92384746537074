import { ComponentProps, memo } from 'react';

import { Box } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import _ from 'lodash';
import A7Select, { Options } from '../api7-select';
import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type SelectProps = DefaultProps &
  Omit<ComponentProps<typeof A7Select>, 'onChange'> & {
    isClearable?: boolean;
    minW?: string;
    customOnChange?: (e: Options) => void;
    defaultValue?: Exclude<Options, null>['value'];
  };

const Select = ({ children, ...rest }: SelectProps) => {
  const { control } = useFormContext();

  const {
    name,
    isDisabled,
    isClearable,
    customOnChange,
    option,
    defaultValue,
    boxOption,
  } = rest;
  const { formHelperText, formLabel, restProps } =
    useFormItem<SelectProps>(rest);

  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value = null } }) => {
          let returnValue = null;
          if (!_.isNil(value)) {
            returnValue = option.find((item) => item?.value === value);
          }
          return (
            <A7Select
              name={name}
              placeholder=""
              value={returnValue}
              isDisabled={isDisabled}
              isClearable={isClearable}
              boxOption={{
                h: '32px',
                mb: 'auto',
                minW: '100px',
              }}
              {...restProps}
              option={option}
              onChange={(e) => {
                if (customOnChange) {
                  customOnChange(e || null);
                }
                onChange(e ? e.value : null);
              }}
            />
          );
        }}
      />
      {formHelperText}
    </Box>
  );
};

export default memo(Select);
