import { Box, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

const TableSkeleton = () => (
  <Box mt="20px" data-cy="table-skeleton">
    <Box mt="20px">
      <Table variant="simple" border="1px #F0F0F0 solid">
        <Thead height="40px" backgroundColor="A7Gray.50">
          <Tr fontWeight="semibold" fontSize="xs" letterSpacing="5%">
            {new Array(5).fill('').map((v, index) => (
              <Th key={index} color="A7Gray.800" borderBottom="none">
                <Skeleton width="30px" />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {new Array(4).fill('').map((v, index) => (
            <Tr key={index} height="56px" borderBottom="1px #F0F0F0 solid">
              {new Array(5).fill('').map((v, index) => (
                <Td key={index} borderBottom="none">
                  <Skeleton />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  </Box>
);

export default memo(TableSkeleton);
