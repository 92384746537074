import axios from 'axios';
import qs from 'qs';
import { useCallback } from 'react';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';

type UseInfiniteHookOptions<ResType> = {
  skipRequest?: boolean;
} & (
  | {
      needManualPaging: true;
      searchField: keyof ResType;
    }
  | { needManualPaging?: false }
);

function createInfiniteHook<ResType extends Record<string, unknown>>(
  getPath: () => string | null
) {
  const useInfiniteHook = (
    { page_size, search }: { page_size: number; search: string } & TableParams,
    options?: UseInfiniteHookOptions<ResType>
  ) => {
    const path = getPath();
    const getKey = useCallback<
      SWRInfiniteKeyLoader<ResType, { index: number; path: typeof path } | null>
    >(
      (index) =>
        options?.skipRequest
          ? null
          : {
              index,
              path,
            },
      [options?.skipRequest]
    );

    const fetcher = ({
      index,
      path,
    }: Exclude<ReturnType<typeof getKey>, null>) => {
      const page = index + 1;

      const url = `${path}?${qs.stringify(
        options?.needManualPaging ? {} : { page, page_size, search }
      )}`;

      return axios.get(url).then((res) => {
        if (options?.needManualPaging) {
          const filterList = res.data.payload.list?.filter((item: ResType) =>
            new RegExp(search, 'i').test(item?.[options.searchField] as string)
          );

          res.data.payload = {
            list: filterList.slice(
              index * page_size!,
              index * page_size! + page_size!
            ),
            count: filterList.length,
          };
        }

        return res.data.payload;
      });
    };

    const {
      data: list,
      size,
      setSize,
      isValidating,
      isLoading,
      mutate,
    } = useSWRInfinite<ListRes<ResType>>(getKey, fetcher, {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    });

    return {
      list,
      isLoading,
      isValidating,
      mutate,
      size,
      setSize,
    };
  };

  return useInfiniteHook;
}

export default createInfiniteHook;
