import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Skeleton from 'react-loading-skeleton';

import useUser from 'hooks/useUser';
import { logout } from 'helper/utils';
import { useState } from 'react';

const User: React.FC = () => {
  const { data, isLoading } = useUser();
  const router = useRouter();
  const [isLogout, setIsLogout] = useState(false);

  return (
    <Box pl="16px" mr="22px" w="full" className="user-content">
      <Menu>
        <MenuButton
          as={Button}
          color="A7Gray.800"
          backgroundColor="#ffffff"
          rightIcon={<TriangleDownIcon width="8px" />}
          m="0"
          p="0"
        >
          <Text
            color="#282A2F"
            fontSize="sm"
            fontWeight="normal"
            lineHeight="14px"
          >
            {isLoading ? <Skeleton width="140px" /> : data?.email}
          </Text>
        </MenuButton>
        <MenuList>
          {Boolean(data?.org_ids.length) && (
            <MenuItem
              rounded="md"
              onClick={() => {
                router.push('/tokens');
              }}
            >
              Personal Access Token
            </MenuItem>
          )}
          <MenuItem
            rounded="md"
            onClick={() => {
              router.push('/user-profile');
            }}
          >
            User Profile
          </MenuItem>
          <MenuItem
            rounded="md"
            onClick={() => {
              setIsLogout(true);
              logout();
            }}
            isDisabled={isLogout}
          >
            Logout {isLogout && <Spinner w="16px" h="16px" ml="4px" />}
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default User;
