import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Divider,
  Spinner,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useBoolean, useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { RESOURCE_LIST } from './constants';
import RecentSearchList from './RecentSearchList';
import ResourceSelect from './ResourceSelect';
import SearchList from './SearchList';
import SearchModalFooter from './SearchModalFooter';
import { ResourceSelectOptions, ResourceType } from './types';

const SearchModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ onClose, isOpen }) => {
  const [currentResourceType, setCurrentResourceType] =
    useState<ResourceSelectOptions>('All');
  const [queryText, setQueryText] = useState('');
  const debouncedQueryText = useDebounce(queryText, { wait: 300 });
  const [isLoading, { setFalse: onLoaded, setTrue: onLoading }] =
    useBoolean(false);

  useEffect(() => {
    // Initial data when modal closed
    if (!isOpen) {
      setQueryText('');
      setCurrentResourceType('All');
    }
  }, [isOpen]);

  const [isComposing, { setFalse: compositionEnd, setTrue: compositionStart }] =
    useBoolean(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      scrollBehavior="inside"
      closeOnEsc
    >
      <ModalOverlay />
      <ModalContent bg="A7Gray.100" className="global-search-modal">
        <ModalBody position="relative" p="0">
          <Box p="24px" position="sticky" top="0" zIndex="1" bg="A7Gray.100">
            <InputGroup h="52px" bg="white">
              <InputLeftElement
                w="150px"
                h="52px"
                display="flex"
                alignItems="center"
              >
                <ResourceSelect
                  defaultValue={{ label: 'All', value: 'All' }}
                  option={RESOURCE_LIST.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(data) => {
                    setCurrentResourceType(data?.value as ResourceType);
                  }}
                />
                <Divider orientation="vertical" height="16px" size="2px" />
                <Flex align="center" ml="8px">
                  {isLoading ? (
                    <Spinner size="sm" color="A7Gray.500" />
                  ) : (
                    <Image w="22x" h="22px" src="/icons/search.svg" />
                  )}
                </Flex>
              </InputLeftElement>
              <Input
                className="global-search-input"
                autoFocus
                placeholder="Search"
                h="52px"
                pl="158px"
                fontSize="16px"
                border="1px"
                borderColor="A7Gray.800"
                borderRadius="2px"
                focusBorderColor="A7Gray.800"
                value={queryText}
                onCompositionStart={compositionStart}
                onCompositionEnd={compositionEnd}
                onChange={(e) => {
                  setQueryText(e.target.value);
                }}
              />
            </InputGroup>
          </Box>

          <Box p="24px" pt="0">
            {debouncedQueryText ? (
              <SearchList
                queryText={debouncedQueryText}
                currentResourceType={currentResourceType}
                isLoading={isLoading}
                onLoaded={onLoaded}
                onLoading={onLoading}
                onClose={onClose}
                isComposing={isComposing}
              />
            ) : (
              <RecentSearchList
                queryText={debouncedQueryText}
                onClose={onClose}
                isComposing={isComposing}
              />
            )}
          </Box>
        </ModalBody>

        <SearchModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
