import {
  PLUGIN_NAME_MAPPER,
  CLOUD_PLUGINS,
  SECURITY_PLUGINS,
  PRODUCT_PLUGINS,
  TRAFFIC_MANAGEMENT_PLUGINS,
  SERVERLESS_PLUGINS,
} from '@/constants/plugins';

const getPluginDocLink = (pluginName: keyof typeof PLUGIN_NAME_MAPPER) => {
  if (pluginName === 'advanced-acl') {
    return 'https://docs.api7.ai/hub/acl/';
  }

  if (pluginName === 'degraphql') {
    return 'https://apisix.apache.org/docs/apisix/plugins/degraphql/';
  }

  let pluginType;
  let pluginPath: keyof typeof PLUGIN_NAME_MAPPER | 'serverless' | '' =
    pluginName;
  if (pluginName in CLOUD_PLUGINS) {
    if (pluginName in SECURITY_PLUGINS) {
      pluginType = 'security';
    } else if (pluginName in PRODUCT_PLUGINS) {
      pluginType = 'product';
    } else if (pluginName in TRAFFIC_MANAGEMENT_PLUGINS) {
      pluginType = 'traffic-management';
    } else if (pluginName === 'logging') {
      pluginType = 'observability';
      pluginPath = '';
    }
    return `https://docs.api7.ai/cloud/guides/${pluginType}/${pluginPath}`;
  }

  if (pluginName in SERVERLESS_PLUGINS) {
    pluginPath = 'serverless';
  }
  return `https://apisix.apache.org/docs/apisix/2.15/plugins/${pluginPath}`;
};

export default getPluginDocLink;
