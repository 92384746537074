import { useEffect, useMemo, useRef, useState } from 'react';

import animateScrollTo from 'animated-scroll-to';
import { Flex, Box } from '@chakra-ui/react';
import { useThrottleFn } from 'ahooks';

export type A7AnchorProps = {
  anchorData: {
    label: string;
    level: number;
  }[];
};

const OFFSET_TOP = 150;

const A7Anchor: React.FC<A7AnchorProps> = ({ anchorData }) => {
  const scrollTimer = useRef<NodeJS.Timeout>();
  const [current, setCurrent] = useState<{ label: string; index: number }>({
    label: '',
    index: 0,
  });

  const { run: handleScroll } = useThrottleFn(
    () => {
      const domArray: { label: string; top: number }[] = [];
      if (
        document.documentElement.clientHeight + window.scrollY ===
        document.body.scrollHeight
      ) {
        setCurrent({
          label: anchorData[anchorData.length - 1].label,
          index: anchorData.length - 1,
        });
      } else {
        anchorData.forEach((item) => {
          const dom = document.getElementById(item.label);
          if (dom && dom.getBoundingClientRect().top > 0) {
            domArray.push({
              label: item.label,
              top: Math.abs(dom?.getBoundingClientRect().top) - OFFSET_TOP,
            });
          }
        });
        if (domArray.length) {
          domArray.sort((a, b) => Math.abs(a.top) - Math.abs(b.top));
          const { label } = domArray[0];
          const index = anchorData.findIndex((item) => item.label === label);
          setCurrent({ label, index });
        }
      }
    },
    { wait: 150, leading: true }
  );

  // The distance the left slider is offset
  const sliderTransform = useMemo(
    () => `${current.index * 100}%`,
    [current.index, anchorData.length]
  );

  // Height of the left slider
  const sliderHeight = useMemo(
    () => (anchorData.length ? ((1 / anchorData.length) * 100).toFixed(2) : 0),
    [anchorData.length]
  );

  // Scroll to an anchor point
  const scrollAnchor = (label: string) => {
    window?.removeEventListener('scroll', handleScroll);
    setCurrent({
      label,
      index: anchorData.findIndex((item) => item.label === label),
    });
    animateScrollTo(
      document.getElementById(label)!.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        OFFSET_TOP,
      { speed: 100 }
    ).then(() => {
      scrollTimer.current = setTimeout(() => {
        window?.addEventListener('scroll', handleScroll);
      }, 50);
    });
  };

  useEffect(() => {
    window?.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      clearTimeout(scrollTimer.current);
      window?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [anchorData]);

  return (
    <Flex>
      <Box w="2px" bg="A7Gray.200">
        <Box
          h={`${sliderHeight}%`}
          transition="transform 0.2s ease-in-out"
          transform="auto-gpu"
          translateY={sliderTransform}
          bg="black"
        />
      </Box>
      <Box>
        {anchorData.map((item, index) => (
          <Box
            key={item.label}
            onClick={() => scrollAnchor(item.label)}
            cursor="pointer"
            color={current.label === item.label ? 'black' : 'A7Gray.400'}
            _hover={{
              color: 'black',
            }}
            transition="all 0.1s ease-in-out"
            ml={`${item.level * 16}px`}
            mt={index ? '8px' : 0}
            fontWeight="medium"
            lineHeight="6"
            as="p"
          >
            {item.label}
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default A7Anchor;
