import { useRef, useMemo, useEffect, useCallback } from 'react';

import { useScroll, useSize, useThrottleFn } from 'ahooks';

import useWindowSize from 'hooks/useWindowSize';

const useTableScroll = (dataNumber: number) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const tableSize = useSize(tableRef);
  const containerRef = useRef(null);
  const containerSize = useSize(containerRef);
  const scrollData = useScroll(containerRef);
  const leftStickyCellDomList = useRef<Element[]>([]);
  const rightStickyCellDomList = useRef<Element[]>([]);

  const { width } = useWindowSize();

  // Determine if the current container is scrollable
  const isScroll = useMemo(() => {
    if (
      containerSize?.width &&
      tableSize?.width &&
      tableSize.width > containerSize.width
    ) {
      return true;
    }
    return false;
  }, [containerSize?.width, tableSize?.width]);

  // Iterate through the nodes to add the isPinned attribute
  const setPinned = useCallback((domList: Element[], isPinned: boolean) => {
    domList.forEach((e) => {
      e.setAttribute('data-is-pinned', isPinned.toString());
    });
  }, []);

  const { run: handleScroll } = useThrottleFn(
    (scrollLeft) => {
      if (isScroll) {
        // Determine if the table starts scrolling
        if (scrollLeft > 0) {
          setPinned(leftStickyCellDomList.current, true);
          // Determine if the table is scrolled to the end
          if (
            Math.abs(scrollLeft - (tableSize!.width - containerSize!.width)) <=
            1
          ) {
            setPinned(rightStickyCellDomList.current, false);
          } else {
            setPinned(rightStickyCellDomList.current, true);
          }
        } else {
          setPinned(rightStickyCellDomList.current, true);
          setPinned(leftStickyCellDomList.current, false);
        }
      } else {
        // Pinned is not set when the table cannot be scrolled
        setPinned(leftStickyCellDomList.current, false);
        setPinned(rightStickyCellDomList.current, false);
      }
    },
    {
      wait: 150,
      leading: true,
    }
  );

  // Listening to window changes and table scrolling
  useEffect(() => {
    handleScroll(scrollData?.left);
  }, [scrollData?.left, width, isScroll]);

  // Get the column cells to be sticky when the table is rendered and cache the dom nodes
  useEffect(() => {
    if (tableRef.current) {
      leftStickyCellDomList.current = Array.from(
        tableRef.current.querySelectorAll('[data-sticky-last-left-td=true]')
      );
      rightStickyCellDomList.current = Array.from(
        tableRef.current.querySelectorAll('[data-sticky-first-right-td=true]')
      );
      handleScroll(scrollData?.left);
    }
  }, [tableRef.current, isScroll, tableSize?.height, dataNumber]);

  return { tableRef, containerRef };
};

export default useTableScroll;
