import { Flex, FlexProps } from '@chakra-ui/react';
import { memo } from 'react';

const SearchItem: React.FC<
  {
    isSelected: boolean;
    isCursorShow: boolean;
  } & FlexProps
> = ({ isSelected, isCursorShow, children, ...rest }) => (
  <Flex
    className={isSelected ? 'is-selected' : ''}
    bg={isSelected ? 'black' : 'white'}
    color={isSelected ? 'white' : 'A7Gray.800'}
    cursor={isCursorShow ? 'pointer' : 'none'}
    mb="8px"
    p="18px"
    borderRadius="2px"
    {...rest}
  >
    {children}
  </Flex>
);

export default memo(SearchItem);
