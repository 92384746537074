import { useEffect, useState } from 'react';

import { Flex, Box, Text, Image } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';

import styles from './style.module.css';

const A7Pagination = ({
  itemsPerPage = 1,
  total = 0,
  onPageChange,
  pageIndex,
  itemOffset,
}: {
  itemsPerPage: number;
  total: number;
  onPageChange: (selected: number, newOffset: number) => void;
  pageIndex: number;
  itemOffset: number;
}) => {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(total / itemsPerPage));
  }, [itemOffset, itemsPerPage, total]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % total;
    onPageChange(event.selected, newOffset);
  };

  return (
    <Flex height="44px" p="12px 22px 12px 22px" justifyContent="space-between">
      <Flex lineHeight="5">
        <Text color="A7Gray.500" fontSize="sm">
          Results:
        </Text>{' '}
        <Text color="#212121" ml="10px" data-cy="result-text">
          {itemOffset + 1 > total ? 1 : itemOffset + 1}-
          {itemOffset + itemsPerPage > total
            ? total
            : itemOffset + itemsPerPage}{' '}
          of {total}
        </Text>
      </Flex>
      <ReactPaginate
        pageClassName={styles.page}
        className={styles.container}
        nextClassName={styles.next}
        activeClassName={styles.active}
        breakClassName={styles.break}
        forcePage={pageIndex}
        nextLabel={
          <Box
            {...(pageIndex === pageCount - 1 && {
              cursor: 'not-allowed',
              opacity: '0.5',
            })}
          >
            <Flex
              border="1px #F0F0F0 solid"
              borderRadius="2px"
              w="24px"
              h="24px"
              justifyContent="center"
              alignItems="center"
            >
              <Image w="4.28px" h="7.5px" src="/icons/arrow.svg" />
            </Flex>
          </Box>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={
          <Box
            {...(pageIndex === 0 && {
              cursor: 'not-allowed',
              opacity: '0.5',
            })}
          >
            <Flex
              border="1px #F0F0F0 solid"
              borderRadius="2px"
              w="24px"
              h="24px"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                transform="rotate(180deg)"
                w="4.28px"
                h="7.5px"
                src="/icons/arrow.svg"
              />
            </Flex>
          </Box>
        }
      />
    </Flex>
  );
};

export default A7Pagination;
