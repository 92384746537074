import React from 'react';

import { Code } from '@chakra-ui/react';

const A7Code: React.FC = ({ children }) => (
  <Code bgColor="#F4F4F4" fontSize="12px" color="A7Gray.700">
    {children}
  </Code>
);

export default A7Code;
