import {
  Box,
  Flex,
  Image,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useHotkeys } from 'react-hotkeys-hook';
import Key from './Key';
import SearchModal from './SearchModal';

const GlobalSearch: React.FC<{
  isMenuCollapse: boolean;
}> = ({ isMenuCollapse }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useHotkeys(
    ['ctrl+k', 'meta+k'],
    () => {
      if (isOpen) {
        onClose();
      } else {
        onOpen();
      }
    },
    { enableOnFormTags: ['INPUT'], preventDefault: true },
    [isOpen]
  );

  return (
    <Box className="global-search-btn">
      {isMenuCollapse ? (
        <Tooltip hasArrow placement="right" offset={[0, 16]} label="Search">
          <Flex
            p="8px"
            justify="center"
            h="40px"
            align="center"
            _hover={{ bgColor: 'A7Gray.100' }}
            mb="8px"
            cursor="pointer"
            onClick={onOpen}
          >
            <Image src="/icons/search.svg" w="20px" h="20px" />
          </Flex>
        </Tooltip>
      ) : (
        <Flex
          bgColor="A7Gray.100"
          border="1px"
          borderColor="A7Gray.200"
          align="center"
          justify="space-between"
          mx="16px"
          mb="16px"
          p="8px"
          borderRadius="2px"
          cursor="pointer"
          onClick={onOpen}
        >
          <Flex align="center">
            <Image src="/icons/search.svg" w="18px" h="18px" />
            <Text ml="8px" color="A7Gray.600" fontSize="sm">
              Search
            </Text>
          </Flex>

          <Flex align="center">
            <Key mr="4px">CMD</Key>
            <Key>K</Key>
          </Flex>
        </Flex>
      )}

      <SearchModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default GlobalSearch;
