import React, { memo, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  Flex,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  Center,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import NImage from 'next/image';
import { Row, useSortBy, TableInstance, useTable } from 'react-table';
import { useRouter } from 'next/router';

import { useSticky } from 'react-table-sticky';
import TableEmpty from './TableEmpty';
import A7Pagination from '../api7-paginate';
import A7Header from '../api7-header';
import useTableScroll from './useTableScroll';
import Styles from './styles';
import TableSkeleton from './TableSkeleton';
import TableLoading from './TableLoading';
import LabelFilter, { LabelFilterProps } from './LabelFilter';
import { A7TableProps } from './types';
import A7Button from '../api7-button';

const A7Table: React.FC<A7TableProps> = ({
  columns,
  data = [],
  isLoading,
  isValidating = false,
  title = '',
  titleOption = {},
  toolBar = [],
  extra = '',
  helperText = '',
  nameSearch = false,
  noDataText = 'No Data',
  shouldReset = false,
  onParamsChange,
  hidePagination,
  pagination,
  serverSort = false,
  reload = () => {},
  ...rest
}) => {
  const { tableRef, containerRef } = useTableScroll(data?.length);
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    // Get the state from the instance
    state: { sortBy },
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: serverSort,
      disableMultiSort: true,
      manualPagination: true,
      initialState: { pageSize: 10 },
      manualSorting: true,
      autoResetSortBy: false,
    } as any,
    useSticky,
    useSortBy
  ) as TableInstance<Record<string, unknown>> & {
    state: {
      sortBy: { id: string; desc: boolean }[];
    };
    setSortBy: (sortBy: []) => void;
  };
  const router = useRouter();
  const [searchValue, setSearchValue] = useState<string>(
    (router.query.search as string) || ''
  );
  const [filterList, setFilterList] = useState<LabelFilterProps['filterList']>(
    []
  );
  // from api7-paginate component
  // Since component re-rendering will lose state, put it here
  const [itemOffset, setItemOffset] = useState(0);

  // auto compute result base on pageIndex
  const pageIndex = !hidePagination ? pagination.pageIndex : 0;
  useEffect(() => {
    setItemOffset(pageIndex * 10);
  }, [pageIndex]);

  useEffect(() => {
    // Reload table data when updating parameters and return to the first page
    if (onParamsChange) {
      let direction: 'DESC' | 'ASC' | undefined;
      if (serverSort) {
        if (sortBy.length !== 0) {
          if (sortBy[0].desc) {
            direction = 'DESC';
          } else {
            direction = 'ASC';
          }
        }
      }
      onParamsChange({
        search: searchValue,
        labels: filterList,
        order_by: serverSort ? sortBy[0]?.id : undefined,
        direction,
      });
      reload();
    }
  }, [filterList, sortBy]);

  const isResetDisable = useMemo(() => {
    let filterCheck = true;
    if (
      (columns as any).findIndex(
        (item: { canFilter: any }) => item.canFilter
      ) !== -1 &&
      filterList.length !== 0
    ) {
      filterCheck = false;
    }

    let nameSearchCheck = true;
    if (nameSearch && searchValue.length) {
      nameSearchCheck = false;
    }

    let paginationCheck = true;
    if (!hidePagination && pagination.pageIndex !== 0) {
      paginationCheck = false;
    }

    // need reset sort state
    let sortCheck = true;
    if (
      (columns as any).findIndex(
        (item: { customShowSort: any }) => item.customShowSort
      ) !== -1 &&
      sortBy.length !== 0
    ) {
      sortCheck = false;
    }

    // These states require the reset button to reset
    return filterCheck && nameSearchCheck && paginationCheck && sortCheck;
  }, [columns.length, rest, searchValue, filterList]);

  return (
    <Box {...rest}>
      <Flex justify="space-between" direction={helperText ? 'column' : 'row'}>
        {title && (
          <Box>
            <A7Header title={title} paddingBottom="4px" {...titleOption} />
            {helperText && (
              <Text fontSize="sm" lineHeight="5" color="A7Gray.600">
                {helperText}
              </Text>
            )}
          </Box>
        )}
        <Flex justify="flex-end" mt="20px">
          {nameSearch && onParamsChange && (
            <InputGroup key="search" w="auto">
              <InputLeftElement
                cursor="pointer"
                onClick={() => {
                  reload();
                }}
              >
                <NImage width="18px" height="18px" src="/icons/search.svg" />
              </InputLeftElement>
              <Input
                size="sm"
                width="320px"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onParamsChange({ search: searchValue });
                  }
                }}
              />
            </InputGroup>
          )}
          {shouldReset && (
            <Box ml="8px">
              <Tooltip label="Reset Filter Conditions" hasArrow placement="top">
                <A7Button
                  type="reset"
                  disabled={isResetDisable}
                  onClick={() => {
                    setSearchValue('');
                    setFilterList([]);
                    setSortBy([]);
                    if (!hidePagination) {
                      pagination.gotoPage(0);
                    }
                    if (onParamsChange) {
                      onParamsChange({ search: '' });
                    }
                  }}
                >
                  Reset
                </A7Button>
              </Tooltip>
            </Box>
          )}
          {toolBar &&
            toolBar.map((item, index) => (
              <Box key={index} ml="8px">
                {item}
              </Box>
            ))}
        </Flex>
      </Flex>
      {isLoading && <TableSkeleton />}
      <Box display={!isLoading ? 'unset' : 'none'}>
        {extra}

        <Box mt="20px">
          <Styles>
            <TableLoading isOpen={isValidating} />

            <Box ref={containerRef} className="table sticky">
              <Table
                ref={tableRef}
                variant="simple"
                {...getTableProps()}
                className="chakra-table-a"
              >
                <Thead height="40px" backgroundColor="A7Gray.50">
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <Tr
                      fontWeight="semibold"
                      fontSize="xs"
                      letterSpacing="5%"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column: any, index) => {
                        let headerProps: any = [];
                        if (column.customShowSort) {
                          headerProps = headerProps.concat(
                            column.getSortByToggleProps()
                          );
                        }
                        if (Object.keys(column.style || {}).length !== 0) {
                          headerProps = headerProps.concat({
                            style: column.style,
                          });
                        }

                        return (
                          <Th
                            key={index}
                            color="A7Gray.800"
                            borderBottom="none"
                            whiteSpace="nowrap"
                            {...column.getHeaderProps(headerProps)}
                            onClick={(e) => {
                              if (
                                (column as any).canFilter ||
                                !(column as any).customShowSort
                              ) {
                                // Do not process label filter for now
                                return;
                              }
                              column
                                .getHeaderProps(column.getSortByToggleProps())
                                .onClick(e);
                              if (pagination && pagination.pageIndex > 0) {
                                pagination.gotoPage(0);
                              }
                            }}
                            p={0}
                          >
                            {(column as any).canFilter ? (
                              <LabelFilter
                                filterList={filterList}
                                setFilter={(e) => {
                                  // after click label filterList, we need to go to page 0;
                                  setFilterList(e);
                                  if (!hidePagination) {
                                    pagination.gotoPage(0);
                                  }
                                }}
                                labelList={(column as any).labelList}
                                tableRef={containerRef}
                              />
                            ) : (
                              <Flex px={6} py={3}>
                                <Box width="fit-content">
                                  {column.render('Header')}
                                </Box>
                                {column.customShowSort && (
                                  <Center ml="4px" w="16px" h="16px">
                                    <Box>
                                      <Box
                                        p="0"
                                        width="8px"
                                        pb="1.33px"
                                        opacity={
                                          sortBy.length &&
                                          sortBy[0]?.id === column.id &&
                                          !sortBy[0]?.desc
                                            ? 1
                                            : 0.5
                                        }
                                      >
                                        <Image
                                          src="/icons/uparrow.svg"
                                          width="8px"
                                          height="full"
                                        />
                                      </Box>
                                      <Box
                                        p="0"
                                        width="8px"
                                        opacity={
                                          sortBy.length &&
                                          sortBy[0]?.id === column.id &&
                                          sortBy[0]?.desc
                                            ? 1
                                            : 0.5
                                        }
                                      >
                                        <Image
                                          src="/icons/downarrow.svg"
                                          width="8px"
                                          height="full"
                                        />
                                      </Box>
                                    </Box>
                                  </Center>
                                )}
                              </Flex>
                            )}
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>
                <Tbody className="chakra-table-body">
                  {rows.map((row: Row<Record<string, unknown>>, index) => {
                    prepareRow(row);
                    return (
                      <Tr
                        {...row.getRowProps()}
                        key={row.id}
                        height="56px"
                        borderBottom="1px #F0F0F0 solid"
                        className="chakra-table-tr"
                        // for ProductTour component select
                        id={index === 0 ? 'table-first-child' : ''}
                      >
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <Td borderBottom="none" {...cell.getCellProps()}>
                            <Box w="max-content" maxW="300px">
                              {cell.render('Cell')}
                            </Box>
                          </Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
            {Boolean(rows.length === 0) && (
              <Box height="200px">
                <TableEmpty description={noDataText} />
              </Box>
            )}
          </Styles>

          {!hidePagination && Boolean(pagination.total) && (
            <A7Pagination
              itemsPerPage={pagination.pageSize}
              total={pagination.total!}
              pageIndex={pagination.pageIndex}
              itemOffset={itemOffset}
              onPageChange={(selectedNumber, newOffset) => {
                pagination.gotoPage(selectedNumber);
                setItemOffset(newOffset);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(A7Table);
