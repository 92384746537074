import { memo } from 'react';

import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { Flex, Box, Center, Image } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { MultiSelect as ReactMultiSelect } from 'react-multi-select-component';

import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type MultiSelectProps = DefaultProps &
  Omit<
    React.ComponentProps<typeof ReactMultiSelect>,
    'options' | 'value' | 'labelledBy'
  > & {
    onRefresh?: () => void;
    refreshLoading?: boolean;
    showFresh?: boolean;
    option: { value: string; label: string }[];
    isClearable?: boolean;
    minW?: string;
  };

const MultiSelect: React.FC<MultiSelectProps> = ({ children, ...rest }) => {
  const { name, option, showFresh, onRefresh, boxOption } = rest;
  const { formField, formLabel, formHelperText } =
    useFormItem<MultiSelectProps>(rest);
  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <Controller
        {...formField}
        rules={{
          validate: (value: string[] | undefined) => {
            if (formField.required) {
              if ((value || []).length === 0) {
                return 'This field is required';
              }
            }
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Flex>
            <Box width="full" id={`${name}`}>
              <ReactMultiSelect
                options={option}
                value={(() => {
                  if (value) {
                    return value?.map((item: string) => ({
                      label: option?.find(
                        (optionItem) => optionItem.value === item
                      )?.label,
                      value: item,
                    }));
                  }
                  return [];
                })()}
                onChange={(e: { label: string; value: string }[]) => {
                  onChange(e.map((item) => item.value));
                }}
                labelledBy="label"
                ArrowRenderer={() => (
                  <Flex w="34px" align="center" justify="center" mr="-10px">
                    <ChevronDownIcon w="20px" h="20px" color="black" />
                  </Flex>
                )}
                ClearSelectedIcon={
                  <Flex w="36px" align="center" justify="center">
                    <CloseIcon
                      w="10px"
                      h="10px"
                      color="A7Gray.400"
                      _hover={{
                        color: 'black',
                        transition: 'none',
                      }}
                    />
                  </Flex>
                }
                {...rest}
              />
            </Box>

            {showFresh && (
              <Center
                width="24px"
                ml="8px"
                onClick={() => onRefresh!()}
                cursor="pointer"
              >
                <Image src="/icons/refresh.svg" className="refresh-icon" />
              </Center>
            )}
          </Flex>
        )}
      />
      {formHelperText}
    </Box>
  );
};

export default memo(MultiSelect);
