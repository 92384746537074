import { Icon, IconProps } from '@chakra-ui/react';

const RecordIcon: React.FC<IconProps> = (props) => (
  <Icon
    className="record-icon"
    viewBox="0 0 24 24"
    w="14px"
    h="14px"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4425 16.5L11.25 12.3075V5.25H12.75V11.685L16.5 15.4425L15.4425 16.5ZM12 1.5C9.17398 1.5004 6.46849 2.64482 4.5 4.6725V1.5H3V7.5H9V6H5.31C8.21324 2.77382 13.0054 2.07918 16.7052 4.34823C20.405 6.61728 21.9584 11.2036 20.399 15.2539C18.8396 19.3043 14.6117 21.6648 10.3455 20.8669C6.07929 20.0691 2.9902 16.3402 3 12H1.5C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5Z"
    />
  </Icon>
);

export default RecordIcon;
