import axios from 'axios';
import useSWR from 'swr';
import { useMemo } from 'react';

import { UserResponse } from 'types/User';
import { SWRConfiguration } from 'swr/_internal';

const useUser = (option?: SWRConfiguration & { disableReq?: boolean }) => {
  const { disableReq = false } = option || {};
  const { data, error, mutate, isLoading } = useSWR<UserResponse>(
    disableReq ? null : '/user/me',
    (url) => axios.get(url).then((res) => res.data.payload),
    option
  );
  const orgId = data?.org_ids?.[0];

  const isSSOUser = useMemo(() => {
    if (!isLoading && data) {
      const userProvider = data.id.split('|')[0];
      if (userProvider === 'auth0') {
        return false;
      }
      return true;
    }
    return false;
  }, [isLoading, data]);

  return {
    data,
    isSSOUser,
    orgId,
    reload: mutate,
    isLoading,
    isError: error,
  };
};

export default useUser;
