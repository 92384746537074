import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '1201px',
  md: '1441px',
  lg: '1921px',
  xl: '1921px',
});

export default breakpoints;
