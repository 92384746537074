import createInfiniteHook from '@/helper/utils/createInfiniteHook';
import useCluster from '@/hooks/useCluster';
import { APISearchResponse } from '@/types/Route';
import { useMemo } from 'react';

const useRoutesInfinite = createInfiniteHook<APISearchResponse>(() => {
  const { clusterId, isClusterReady } = useCluster();

  return useMemo(
    () => (isClusterReady ? `/clusters/${clusterId}/apis/search` : null),
    [isClusterReady]
  );
});

export default useRoutesInfinite;
