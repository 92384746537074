import { memo } from 'react';

import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Flex, Box, Text, Image, Link, Center } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';
import { useRouter } from 'next/router';

import { helperCenterLink } from 'helper/config';
import useOrgs from 'hooks/useOrgs';
import useUser from 'hooks/useUser';

import User from '../user';

export type Plan = 'free' | 'basic' | 'enterprise';

const PLAN_NAME_STYLE: { [key in Plan]: { color: string; bgColor: string } } = {
  free: { color: '#686868', bgColor: '#FCFCFC' },
  basic: { color: '#4197EE', bgColor: '#F7FBFF' },
  enterprise: { color: '#A070FF', bgColor: '#FCFAFF' },
};

const HelpCenter: React.FC = () => (
  <Box justifyContent="center" alignItems="center" d="flex" lineHeight="14px">
    <QuestionOutlineIcon color="A7Gray.800" />
    <Text
      color="#282A2F"
      fontSize="sm"
      width="105px"
      pr="16px"
      textAlign="right"
    >
      <Link isExternal href={helperCenterLink}>
        Help Center
      </Link>
    </Text>
    <Box border="1px solid #E0E0E0" height="14px" />
  </Box>
);

// const Feedback: React.FC = () => (
//   <Box justifyContent="center" alignItems="center" d="flex" lineHeight="14px">
//     <Text
//       color="#282A2F"
//       fontSize="sm"
//       width="105px"
//       px="16px"
//       textAlign="right"
//     >
//       <Link isExternal href={feedbackLink}>
//         <Text w="full" textAlign="center">
//           Feedback
//         </Text>
//       </Link>
//     </Text>
//     <Box border="1px solid #E0E0E0" height="14px" />
//   </Box>
// );

const Header = ({ planName }: { planName: Plan | undefined }) => {
  const { orgId } = useUser();
  const { data = { name: '' }, isLoading } = useOrgs();
  const router = useRouter();
  const { name } = data;
  return (
    <Flex
      as="header"
      width="full"
      align="center"
      backgroundColor="white"
      height="72px"
      borderBottom="1px #eee solid"
      justifyContent="space-between"
    >
      <Flex>
        <Box
          width="67px"
          ml="16px"
          cursor="pointer"
          onClick={() => {
            router.push('/overview');
          }}
        >
          <Image src="/logo.svg" w="full" />
        </Box>
        {Boolean(planName) && (
          <Flex alignItems="center" ml="8px">
            <Flex
              alignItems="center"
              display={planName === 'free' ? 'none' : 'inherit'}
              height="20px"
              p="0px 6px 0px 6px"
              backgroundColor={
                PLAN_NAME_STYLE[planName!]
                  ? PLAN_NAME_STYLE[planName!].bgColor
                  : '#FCFCFC'
              }
              border={`1px solid ${
                PLAN_NAME_STYLE[planName!]
                  ? PLAN_NAME_STYLE[planName!].color
                  : '#686868'
              }`}
              borderRadius="2px"
            >
              <Text
                fontWeight="semibold"
                fontSize="xs"
                textAlign="center"
                width="full"
                data-cy="plan-badge-text"
                color={`${
                  PLAN_NAME_STYLE[planName!]
                    ? PLAN_NAME_STYLE[planName!].color
                    : 'A7Gray.600'
                }`}
              >
                {planName!.replace(planName![0], planName![0].toUpperCase())}
              </Text>
            </Flex>
          </Flex>
        )}
        <Center ml="16px">
          <Box border="1px solid #E0E0E0" height="14px" />
        </Center>
        {Boolean(orgId) && (
          <Center ml="16px">
            {isLoading && <Skeleton width="100px" height="30px" />}
            {!isLoading && (
              <Box>
                <Text
                  fontSize="10px"
                  lineHeight="normal"
                  fontStyle="italic"
                  color="A7Gray.500"
                  fontWeight="600"
                >
                  ORGANIZATION
                </Text>
                <Text
                  className="header-org-name"
                  fontSize="14px"
                  lineHeight="normal"
                  color="A7Gray.800"
                  fontWeight="600"
                >
                  {name}
                </Text>
              </Box>
            )}
          </Center>
        )}
      </Flex>
      <Box display="flex" justifyContent="space-between" zIndex="sticky">
        <HelpCenter />
        {/* <Feedback /> */}
        <User />
      </Box>
    </Flex>
  );
};

export default memo(Header);
