import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { Box, Center } from '@chakra-ui/react';
import Editor, { OnMount, loader } from '@monaco-editor/react';
import { languages, editor, IRange } from 'monaco-editor';

import type { Monaco } from '@monaco-editor/react';
import { fetchVariables } from './service';
import theme from './theme';

loader.config({ paths: { vs: '/monaco-editor/min/vs' } });

// using flag to prevent duplicate suggestion items
let flag = false;

const InLineOptions: editor.IStandaloneEditorConstructionOptions = {
  lineHeight: 18,
  fontSize: 14,
  fontFamily: 'Inter',
  fontWeight: 'normal',
  wordWrap: 'off',
  lineNumbers: 'off',
  lineNumbersMinChars: 0,
  overviewRulerLanes: 0,
  overviewRulerBorder: false,
  hideCursorInOverviewRuler: true,
  lineDecorationsWidth: 0,
  glyphMargin: false,
  folding: false,
  scrollBeyondLastColumn: 0,
  scrollbar: {
    horizontal: 'hidden',
    vertical: 'hidden',
    // avoid can not scroll page when hover monaco
    alwaysConsumeMouseWheel: false,
  },
  // disable `Find`
  find: {
    addExtraSpaceOnTop: false,
    autoFindInSelection: 'never',
  },
  minimap: { enabled: false },
  // see: https://github.com/microsoft/monaco-editor/issues/1746
  wordBasedSuggestions: false,
  // avoid links underline
  links: false,
  // avoid highlight hover word
  occurrencesHighlight: false,
  cursorStyle: 'line-thin',
  // hide current row highlight grey border
  // see: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ieditoroptions.html#renderlinehighlight
  renderLineHighlight: 'none',
  contextmenu: false,
  // default selection is rounded
  roundedSelection: false,
  hover: {
    // unit: ms
    // default: 300
    delay: 100,
  },
  acceptSuggestionOnEnter: 'on',
  // auto adjust width and height to parent
  // see: https://github.com/Microsoft/monaco-editor/issues/543#issuecomment-321767059
  automaticLayout: true,
  // if monaco is inside a table, hover tips or completion may casue table body scroll
  fixedOverflowWidgets: true,
};

const NormalOptions: editor.IStandaloneEditorConstructionOptions = {
  scrollbar: {
    vertical: 'hidden',
    horizontal: 'hidden',
  },
  minimap: { enabled: false },
  fontWeight: 'normal',
  fontFamily: 'Inter',
  fontSize: 14,
  lineHeight: 20,
  lineNumbers: 'off',
  lineNumbersMinChars: 0,
  fixedOverflowWidgets: true,
};

export type CodeEditorProps = {
  name?: string;
  inline: boolean;
  value: string;
  onChange: (text: string) => void;
  readOnly?: boolean;
  height?: string;
  ref?: any;
};

const CodeEditor: React.FC<CodeEditorProps> = ({
  name,
  value,
  inline,
  onChange,
  readOnly,
  height,
  ref,
  ...rest
}) => {
  const onEditorMounted: OnMount = (editors, monaco) => {
    if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = editors;
    }
    editors.createContextKey('condition', inline);
    if (inline) {
      // disable press `Enter` in case of producing line breaks
      editors.addCommand(
        monaco.KeyCode.Enter,
        () => {
          // State: https://github.com/microsoft/vscode/blob/1.56.0/src/vs/editor/contrib/suggest/suggestWidget.ts#L50
          const StateOpen = 3;
          if (
            // @ts-ignore
            // eslint-disable-next-line no-underscore-dangle
            // eslint-disable-next-line no-underscore-dangle
            editors._contentWidgets['editor.widget.suggestWidget'].widget
              ._widget._state === StateOpen
          ) {
            // @ts-ignore
            editors.trigger('', 'acceptSelectedSuggestion');
          }
        },
        'condition'
      );
    }
  };
  const editorWillMount = (monaco: Monaco) => {
    fetchVariables().then((data) => {
      monaco.editor.defineTheme('customTheme', theme);
      monaco.editor.setTheme('customTheme');
      if (inline && !flag) {
        const createDependencyProposals = (range: IRange) =>
          data.map((item) => ({
            label: `${item.name}`,
            kind: monaco.languages.CompletionItemKind.Constant,
            insertText: `${item.name}`,
            range,
          }));

        monaco.languages.registerCompletionItemProvider('plaintext', {
          triggerCharacters: ['$', '{'],
          provideCompletionItems: (model, position) => {
            const word = model.getWordUntilPosition(position);
            const range: IRange = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn,
            };

            return {
              suggestions: createDependencyProposals(range),
            };
          },
        });
        flag = true;
      } else {
        const regexArr = data
          .filter((item) => /^.*_$/.test(item.name))
          .map((item) => `\\$${item.name}`);
        const regexString = regexArr.length
          ? `^(${regexArr.join('|')}).*$`
          : '';

        const schema: any = {
          type: 'object',
          additionalProperties: {
            anyOf: [
              { pattern: regexString },
              {
                type: 'string',
                enum: data.map((item) => `$${item.name}`),
              },
            ],
          },
        };

        const schemaConfig: languages.json.DiagnosticsOptions = {
          validate: true,
          schemas: [
            {
              uri: 'https://api7.cloud',
              fileMatch: ['*'],
              schema,
            },
          ],
          trailingCommas: 'error',
          enableSchemaRequest: false,
        };
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions(schemaConfig);
      }
    });
  };

  return (
    <Center
      w="full"
      h={height || (inline ? '32px' : '207px')}
      border="1px solid #e2e8f0"
      backgroundColor={inline ? '#fff' : ''}
      padding={inline ? '6px 10px 6px 10px' : 0}
      data-cy={name}
      {...rest}
    >
      <Editor
        value={value}
        defaultValue={inline ? '' : '{}'}
        onChange={(text) => {
          if (text) {
            onChange(text);
          } else {
            onChange('');
          }
        }}
        language={inline ? 'plaintext' : 'json'}
        beforeMount={editorWillMount}
        onMount={onEditorMounted}
        options={{ ...(inline ? InLineOptions : NormalOptions), readOnly }}
        loading={
          <Box width="full">
            <Skeleton width="full" height={inline ? '100%' : '207px'} />
          </Box>
        }
      />
    </Center>
  );
};

export default CodeEditor;
