import { ReactNode } from 'react';

import { Flex, Box } from '@chakra-ui/react';

import { TaskController, Plane } from 'components/ProductTour';
import useBreadcrumb, { BreadcrumbContext } from 'hooks/useBreadcrumb';
import useTour, { TourContext } from 'hooks/useTour';

import Breadcrumb from './Breadcrumb';
import Page403 from './Page403';
import SideMenu from './side-menu';

type LayoutProps = {
  children: ReactNode;
  isAllow?: string | boolean;
  showSideMenu?: boolean;
};

const BodyContent = ({
  children,
  isAllow = false,
  showSideMenu = true,
}: LayoutProps) => {
  const { breadcrumbProvide } = useBreadcrumb();
  const TourContextValue = useTour();

  return (
    <Flex
      as="main"
      className="main-body"
      display="flex"
      justify="space-between"
    >
      <TourContext.Provider value={TourContextValue}>
        <TaskController />
        {showSideMenu && <SideMenu />}
        <Box position="relative" w="0" flex={1}>
          <BreadcrumbContext.Provider value={breadcrumbProvide}>
            <Breadcrumb />
            <Flex
              direction="column"
              backgroundColor="A7Gray.50"
              p="16px"
              pt={breadcrumbProvide.breadcrumb.length > 1 ? '0px' : '16px'}
              align="center"
              minH="calc(100vh - 72px)"
            >
              <Box w="full" flex="1">
                {TourContextValue.tourIsOpen && <Plane />}
                {isAllow ? children : <Page403 />}
              </Box>
            </Flex>
          </BreadcrumbContext.Provider>
        </Box>
      </TourContext.Provider>
    </Flex>
  );
};

export default BodyContent;
