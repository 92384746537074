import { ModalFooter, Flex, Text } from '@chakra-ui/react';
import Key from './Key';

const SearchModalFooter = () => (
  <ModalFooter
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderTop="1px"
    borderColor="A7Gray.200"
    p="24px"
  >
    <Flex align="center">
      <Key mr="10px">↵</Key>
      <Text color="A7Gray.600">to select</Text>
    </Flex>
    <Flex mx="20px" align="center">
      <Flex>
        <Key mr="2px">↑</Key>
        <Key mr="10px">↓</Key>
        <Text color="A7Gray.600">to navigate</Text>
      </Flex>
    </Flex>
    <Flex align="center">
      <Key mr="10px">esc</Key>
      <Text color="A7Gray.600">to close</Text>
    </Flex>
  </ModalFooter>
);

export default SearchModalFooter;
