import axios from 'axios';

import { ProductTour } from 'types/User';

type PlanStatusProps = {
  current_api_calls: number;
  current_apis: number;
  current_applications: number;
  current_members: number;
  max_api_calls: number;
  max_applications: number;
  plan_expire_time: string;
  plan_name: string;
};

export const fetchPlanStatus = async ({
  orgId,
}: {
  orgId: string;
}): Promise<PlanStatusProps> =>
  axios({
    method: 'GET',
    url: `/orgs/${orgId}/plan/status`,
  }).then((data) => data.data.payload);

export type ListProps = {
  created_at: string;
  domain: string;
  id: string;
  name: string;
  org_id: string;
  org_name: string;
  region_id: string;
  region_name: string;
  status: number;
  updated_at: string;
  last_seen_time: Date;
  register_time: Date;
};
export type ClusterProps = {
  count: number;
  list: ListProps[];
};

export const fetchClusterJoin = ({
  clusterId,
  type,
}: {
  clusterId: string;
  type: 'kubernetes' | 'docker' | 'linux';
}) =>
  axios({
    method: 'GET',
    url: `/clusters/${clusterId}/join?type=${type}`,
  }).then((data) => data.data.payload);

export const generateCertificate = ({ clusterId }: { clusterId: string }) =>
  axios({
    method: 'GET',
    url: `/clusters/${clusterId}/gateway_certificate`,
  }).then((data) => data.data.payload);

export const deleteAPI = (appId: string, apiId: string) =>
  axios.delete(`/apps/${appId}/apis/${apiId}`);

export const initializeOrg = (name: string) =>
  axios.post('/orgs/initial', { name });

export const updateUserTour = (data: ProductTour) =>
  axios.put('/user/me/product_tour', data);
