const cryptoRandomString = ({
  characters,
  length,
}: {
  characters: string;
  length: number;
}): string => {
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

export default cryptoRandomString;
