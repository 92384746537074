import { Box, Flex, Image } from '@chakra-ui/react';
import { useRouter } from 'next/router';

type Props = {
  text?: string;
  href?: string;
};

const Back: React.FC<Props> = ({ text = 'Back', href }) => {
  const router = useRouter();
  return (
    <Flex
      cursor="pointer"
      mb="30px"
      mt="12px"
      onClick={() => {
        if (href) {
          router.push(href);
        } else {
          router.back();
        }
      }}
    >
      <Image
        src="/icons/arrow.svg"
        style={{
          transform: 'rotate(180deg)',
          width: '7.12px',
          marginRight: '10.68px',
        }}
      />
      <Box>{text}</Box>
    </Flex>
  );
};

export default Back;
