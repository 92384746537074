export const AUTHENTICATION_MAPPER = {
  key_auth: 'Key Auth',
  basic_auth: 'Basic Auth',
  hmac_auth: 'HMAC Auth',
  jwt_auth: 'JWT Auth',
  openid_connect_auth: 'OpenID Connect',
  ldap_auth: 'LDAP Auth',
} as const;

export const SECURITY_PLUGINS = {
  acl: 'ACL',
  cors: 'CORS',
  csrf: 'CSRF',
  'ip-restriction': 'IP Restriction',
};

export const PRODUCT_PLUGINS = {
  'request-id': 'Request ID',
};

export const TRAFFIC_MANAGEMENT_PLUGINS = {
  authentication: 'Authentication',
  'fault-injection': 'Fault Injection',
  'limit-count': 'Limit Count',
  redirect: 'Redirect',
  'proxy-rewrite': 'Proxy Rewrite',
  'response-rewrite': 'Response Rewrite',
};

export const CLOUD_PLUGINS = {
  ...SECURITY_PLUGINS,
  ...PRODUCT_PLUGINS,
  ...TRAFFIC_MANAGEMENT_PLUGINS,
  logging: 'Logging',
  'advanced-acl': 'Advanced ACL',
  degraphql: 'DeGraphQL',
};

export const SERVERLESS_PLUGINS = {
  'serverless-post-function': 'Serverless Post Function',
  'serverless-pre-function': 'Serverless Pre Function',
};

export const APISIX_ORIGIN_PLUGINS = {
  'real-ip': 'Real IP',
  'client-control': 'Client Control',
  'proxy-control': 'Proxy Control',
  zipkin: 'Zipkin',
  skywalking: 'Skywalking',
  opentelemetry: 'OpenTelemetry',
  mocking: 'Mocking',
  'ua-restriction': 'User Agent Restriction',
  'referer-restriction': 'Referer Restriction',
  'uri-blocker': 'URI Blocker',
  'request-validation': 'Request Validation',
  'authz-casbin': 'Authz Casbin',
  'authz-casdoor': 'Authz Casdoor',
  'wolf-rbac': 'Wolf RBAC',
  'ldap-auth': 'LDAP Auth',
  'forward-auth': 'Forward Auth',
  opa: 'Open Policy Agent',
  'authz-keycloak': 'Authz Keycloak',
  'error-log-logger': 'Error Log Logger',
  'proxy-mirror': 'Proxy Mirror',
  'proxy-cache': 'Proxy Cache',
  'api-breaker': 'API Breaker',
  'limit-conn': 'Limit Conn',
  'limit-req': 'Limit Req',
  'node-status': 'Node Status',
  gzip: 'Gzip',
  'kafka-proxy': 'Kafka Proxy',
  'dubbo-proxy': 'Dubbo Proxy',
  'grpc-transcode': 'GRPC Transcode',
  'grpc-web': 'GRPC Web',
  'public-api': 'Public API',
  prometheus: 'Prometheus',
  datadog: 'DataDog',
  echo: 'Echo',
  loggly: 'Loggly',
  'splunk-hec-logging': 'Splunk Hec Logging',
  'skywalking-logger': 'SkyWalking Logger',
  'google-cloud-logging': 'Google Cloud Logging',
  'sls-logger': 'SLS Logger',
  'tcp-logger': 'TCP Logger',
  'rocketmq-logger': 'RocketMQ Logger',
  syslog: 'Syslog',
  'udp-logger': 'UDP Logger',
  'file-logger': 'File Logger',
  'clickhouse-logger': 'ClickHouse Logger',
  'log-rotate': 'Log Rotate',
  'ext-plugin-post-resp': 'Ext Plugin Post Resp',
  'ext-plugin-pre-req': 'Ext Plugin Pre Req',

  'azure-functions': 'Azure Functions',
  'aws-lambda': 'Amazon Lambda',
  openwhisk: 'Apache OpenWhisk',
  'consumer-restriction': 'Consumer Restriction',
  'basic-auth': 'Basic Auth',
  'hmac-auth': 'HMAC Auth',
  'jwt-auth': 'Jwt Auth',
  'key-auth': 'Key Auth',
  'openid-connect': 'OpenID Connect',
  ...SERVERLESS_PLUGINS,
};

export const PLUGIN_NAME_MAPPER = {
  ...CLOUD_PLUGINS,
  ...APISIX_ORIGIN_PLUGINS,
} as const;

export const VANILLA_LIST = [
  {
    plugin_name: 'Real IP',
    apisix_plugin_name: 'real-ip',
  },
  {
    plugin_name: 'Client Control',
    apisix_plugin_name: 'client-control',
  },
  {
    plugin_name: 'Proxy Control',
    apisix_plugin_name: 'proxy-control',
  },
  {
    plugin_name: 'Zipkin',
    apisix_plugin_name: 'zipkin',
  },
  {
    plugin_name: 'Skywalking',
    apisix_plugin_name: 'skywalking',
  },
  {
    plugin_name: 'OpenTelemetry',
    apisix_plugin_name: 'opentelemetry',
  },
  {
    plugin_name: 'Ext Plugin Pre Req',
    apisix_plugin_name: 'ext-plugin-pre-req',
  },
  {
    plugin_name: 'Mocking',
    apisix_plugin_name: 'mocking',
  },
  {
    plugin_name: 'Serverless Pre Function',
    apisix_plugin_name: 'serverless-pre-function',
  },
  {
    plugin_name: 'User Agent Restriction',
    apisix_plugin_name: 'ua-restriction',
  },
  {
    plugin_name: 'Referer Restriction',
    apisix_plugin_name: 'referer-restriction',
  },
  {
    plugin_name: 'URI Blocker',
    apisix_plugin_name: 'uri-blocker',
  },
  {
    plugin_name: 'Request Validation',
    apisix_plugin_name: 'request-validation',
  },
  {
    plugin_name: 'Authz Casbin',
    apisix_plugin_name: 'authz-casbin',
  },
  {
    plugin_name: 'Authz Casdoor',
    apisix_plugin_name: 'authz-casdoor',
  },
  {
    plugin_name: 'Wolf RBAC',
    apisix_plugin_name: 'wolf-rbac',
  },
  {
    plugin_name: 'LDAP Auth',
    apisix_plugin_name: 'ldap-auth',
  },
  {
    plugin_name: 'Forward Auth',
    apisix_plugin_name: 'forward-auth',
  },
  {
    plugin_name: 'Open Policy Agent',
    apisix_plugin_name: 'opa',
  },
  {
    plugin_name: 'Authz Keycloak',
    apisix_plugin_name: 'authz-keycloak',
  },
  {
    plugin_name: 'Error Log Logger',
    apisix_plugin_name: 'error-log-logger',
  },
  {
    plugin_name: 'Proxy Mirror',
    apisix_plugin_name: 'proxy-mirror',
  },
  {
    plugin_name: 'Proxy Cache',
    apisix_plugin_name: 'proxy-cache',
  },
  {
    plugin_name: 'API Breaker',
    apisix_plugin_name: 'api-breaker',
  },
  {
    plugin_name: 'Limit Conn',
    apisix_plugin_name: 'limit-conn',
  },
  {
    plugin_name: 'Limit Req',
    apisix_plugin_name: 'limit-req',
  },
  {
    plugin_name: 'Node Status',
    apisix_plugin_name: 'node-status',
  },
  {
    plugin_name: 'Gzip',
    apisix_plugin_name: 'gzip',
  },
  {
    plugin_name: 'Kafka Proxy',
    apisix_plugin_name: 'kafka-proxy',
  },
  {
    plugin_name: 'Dubbo Proxy',
    apisix_plugin_name: 'dubbo-proxy',
  },
  {
    plugin_name: 'GRPC Transcode',
    apisix_plugin_name: 'grpc-transcode',
  },
  {
    plugin_name: 'GRPC Web',
    apisix_plugin_name: 'grpc-web',
  },
  {
    plugin_name: 'Public API',
    apisix_plugin_name: 'public-api',
  },
  {
    plugin_name: 'Prometheus',
    apisix_plugin_name: 'prometheus',
  },
  {
    plugin_name: 'DataDog',
    apisix_plugin_name: 'datadog',
  },
  {
    plugin_name: 'Echo',
    apisix_plugin_name: 'echo',
  },
  {
    plugin_name: 'Loggly',
    apisix_plugin_name: 'loggly',
  },
  {
    plugin_name: 'Splunk Hec Logging',
    apisix_plugin_name: 'splunk-hec-logging',
  },
  {
    plugin_name: 'SkyWalking Logger',
    apisix_plugin_name: 'skywalking-logger',
  },
  {
    plugin_name: 'Google Cloud Logging',
    apisix_plugin_name: 'google-cloud-logging',
  },
  {
    plugin_name: 'SLS Logger',
    apisix_plugin_name: 'sls-logger',
  },
  {
    plugin_name: 'TCP Logger',
    apisix_plugin_name: 'tcp-logger',
  },
  {
    plugin_name: 'RocketMQ Logger',
    apisix_plugin_name: 'rocketmq-logger',
  },
  {
    plugin_name: 'Syslog',
    apisix_plugin_name: 'syslog',
  },
  {
    plugin_name: 'UDP Logger',
    apisix_plugin_name: 'udp-logger',
  },
  {
    plugin_name: 'File Logger',
    apisix_plugin_name: 'file-logger',
  },
  {
    plugin_name: 'ClickHouse Logger',
    apisix_plugin_name: 'clickhouse-logger',
  },
  {
    plugin_name: 'Log Rotate',
    apisix_plugin_name: 'log-rotate',
  },
  {
    plugin_name: 'Ext Plugin Post Resp',
    apisix_plugin_name: 'ext-plugin-post-resp',
  },
  {
    plugin_name: 'Serverless Post Function',
    apisix_plugin_name: 'serverless-post-function',
  },
  {
    plugin_name: 'Azure Functions',
    apisix_plugin_name: 'azure-functions',
  },
  {
    plugin_name: 'Amazon Lambda',
    apisix_plugin_name: 'aws-lambda',
  },
  {
    plugin_name: 'Apache OpenWhisk',
    apisix_plugin_name: 'openwhisk',
  },
  {
    plugin_name: 'Basic Auth',
    apisix_plugin_name: 'basic-auth',
  },
  {
    plugin_name: 'Hmac Auth',
    apisix_plugin_name: 'hmac-auth',
  },
  {
    plugin_name: 'Jwt Auth',
    apisix_plugin_name: 'jwt-auth',
  },
  {
    plugin_name: 'Key Auth',
    apisix_plugin_name: 'key-auth',
  },
  {
    plugin_name: 'OpenID Connect',
    apisix_plugin_name: 'openid-connect',
  },
  {
    plugin_name: 'Advanced ACL',
    apisix_plugin_name: 'advanced-acl',
  },
  {
    plugin_name: 'DeGraphQL',
    apisix_plugin_name: 'degraphql',
  },
];
