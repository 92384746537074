import { Box, Flex, Text, Image, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { memo, useEffect, useMemo, useState } from 'react';
import { A7Label } from '../api7-components';
import { getRandomColor } from '../api7-components/api7-label';
import { QUERY_PARAM, RESOURCE_PARAM, SEARCH_PAGE_SIZE } from './constants';
import { Highlight } from './Highlight';
import useConsumersInfinite from './hooks/useConsumersInfinite';
import useGatewayInfinite from './hooks/useGatewayInfinite';
import useKeyBoard from './hooks/useKeyBoard';
import useLogCollectionInfinite from './hooks/useLogCollectionInfinite';
import useRecentSearch from './hooks/useRecentSearch';
import useRoutesInfinite from './hooks/useRoutesInfinite';
import useServicesInfinite from './hooks/useServicesInfinite';
import useSetting from './hooks/useSetting';
import useSSLInfinite from './hooks/useSSLsInfinite';
import SearchItem from './SearchItem';
import {
  ResourceType,
  ResourceList,
  ResourceItem,
  ResourceSelectOptions,
} from './types';

const NoSearchResult: React.FC<{ queryText: string }> = ({ queryText }) => (
  <Flex h="120px" justify="center" align="center" color="A7Gray.600">
    <Text>NO RESULTS FOR</Text>
    <Text fontWeight="bold" color="A7Gray.800" ml="8px">
      {`"${queryText}"`}
    </Text>
  </Flex>
);

const SearchList: React.FC<{
  queryText: string;
  currentResourceType: ResourceSelectOptions;
  isLoading: boolean;
  isComposing: boolean;
  onLoaded: () => void;
  onLoading: () => void;
  onClose: () => void;
}> = ({
  queryText,
  currentResourceType,
  isLoading,
  isComposing,
  onLoaded,
  onLoading,
  onClose,
}) => {
  const router = useRouter();

  const { addRecord } = useRecentSearch();

  // Used to determine if the current resource item is selected: [selectedResource, selectedItem]
  const [currentSelect, setCurrentSelect] = useState<[number, number]>([0, 0]);
  const getIsSelected = (resourceIndex: number, index: number) =>
    resourceIndex === currentSelect[0] && index === currentSelect[1];

  // Determine if the resource type is within the display range of the current resource selector
  const getIsResourceShow = (resourceType: ResourceType) =>
    resourceType === currentResourceType || currentResourceType === 'All';

  const servicesData = useServicesInfinite(
    {
      search: queryText,
      page_size: SEARCH_PAGE_SIZE,
    },
    {
      skipRequest: !getIsResourceShow('Services'),
    }
  );

  const consumersData = useConsumersInfinite(
    {
      search: queryText,
      page_size: SEARCH_PAGE_SIZE,
    },
    {
      skipRequest: !getIsResourceShow('Consumers'),
    }
  );

  const sslData = useSSLInfinite(
    {
      search: queryText,
      page_size: SEARCH_PAGE_SIZE,
    },
    {
      skipRequest: !getIsResourceShow('SSL'),
    }
  );

  const routesData = useRoutesInfinite(
    {
      search: queryText,
      page_size: SEARCH_PAGE_SIZE,
      order_by: 'name',
    },
    {
      skipRequest: !getIsResourceShow('Routes'),
    }
  );

  const gatewayData = useGatewayInfinite(
    {
      search: queryText,
      page_size: SEARCH_PAGE_SIZE,
    },
    {
      needManualPaging: true,
      searchField: 'hostname',
      skipRequest: !getIsResourceShow('Gateway Instances'),
    }
  );

  const logCollectionData = useLogCollectionInfinite(
    {
      search: queryText,
      page_size: SEARCH_PAGE_SIZE,
    },
    {
      skipRequest: !getIsResourceShow('Log Collection Plans'),
    }
  );
  const settingData = useSetting(queryText);

  const searchList = useMemo<ResourceList>(
    () => [
      [
        'Gateway Instances',
        {
          list: (gatewayData.list?.flatMap((item) => item.list) || []).map(
            (item) => ({
              name: item.hostname,
              path: ['Gateway Instances', item.hostname],
              basePath: `/overview?${QUERY_PARAM}=${item.id}&${RESOURCE_PARAM}=Gateway Instances`,
            })
          ),
          isLoading: gatewayData.isValidating,
          total: gatewayData.list?.[0].count || 0,
          size: gatewayData.size,
          setSize: gatewayData.setSize,
        },
      ],
      [
        'Services',
        {
          list: (servicesData.list?.flatMap((item) => item.list) || []).map(
            (item) => ({
              name: item.name,
              path: ['Services', item.name],
              basePath: `/api-management/services/${item.id}`,
              desc: item.description,
              extra: (
                <Flex flexWrap="wrap">
                  {item.hosts.map((host, index) => (
                    <A7Label
                      mt="8px"
                      mr="8px"
                      color={getRandomColor(host)}
                      key={index}
                    >
                      <Flex align="center" fontSize="12px" fontStyle="italic">
                        <Text mr="4px">Host:</Text>
                        <Highlight query={queryText}>{host}</Highlight>
                      </Flex>
                    </A7Label>
                  ))}
                  <A7Label
                    mt="8px"
                    mr="8px"
                    color={getRandomColor(item.path_prefix)}
                  >
                    <Flex align="center" fontSize="12px" fontStyle="italic">
                      <Text mr="4px">Path Prefix:</Text>
                      <Highlight query={queryText}>
                        {item.path_prefix}
                      </Highlight>
                    </Flex>
                  </A7Label>
                </Flex>
              ),
            })
          ),
          isLoading: servicesData.isValidating,
          total: servicesData.list?.[0].count || 0,
          size: servicesData.size,
          setSize: servicesData.setSize,
        },
      ],
      [
        'Routes',
        {
          list: (routesData.list?.flatMap((item) => item.list || []) || []).map(
            (item) => ({
              name: item.api.name,
              path: ['Services', item.application.name, item.api.name],
              basePath: `/api-management/services/${item.application.id}/route/${item.api.id}`,
              desc: item.api.description,
            })
          ),
          isLoading: routesData.isValidating,
          total: routesData.list?.[0].count || 0,
          size: routesData.size,
          setSize: routesData.setSize,
        },
      ],
      [
        'Consumers',
        {
          list: (consumersData.list?.flatMap((item) => item.list) || []).map(
            (item) => ({
              name: item.name,
              path: ['Consumers', item.name],
              basePath: `/api-management/consumers/${item.id}`,
              desc: item.description,
            })
          ),
          isLoading: consumersData.isValidating,
          total: consumersData.list?.[0].count || 0,
          size: servicesData.size,
          setSize: consumersData.setSize,
        },
      ],
      [
        'SSL',
        {
          list: (sslData.list?.flatMap((item) => item.list) || []).map(
            (item) => ({
              name: item.snis.join(','),
              path: ['SSL', item.id],
              basePath: `/api-management/ssl/${item.id}`,
            })
          ),
          isLoading: sslData.isValidating,
          total: sslData.list?.[0].count || 0,
          size: sslData.size,
          setSize: sslData.setSize,
        },
      ],
      [
        'Log Collection Plans',
        {
          list: (
            logCollectionData.list?.flatMap((item) => item.list) || []
          ).map((item) => ({
            name: item.name,
            path: ['Log Collection Plans', item.name],
            basePath: `/api-management/log-collection?${QUERY_PARAM}=${item.id}&${RESOURCE_PARAM}=Log Collection Plans`,
            desc: item.description,
          })),
          isLoading: logCollectionData.isValidating,
          total: logCollectionData.list?.[0].count || 0,
          size: logCollectionData.size,
          setSize: logCollectionData.setSize,
        },
      ],
      [
        'Setting',
        {
          list: (settingData.list || []).list.map((item) => ({
            name: item.name,
            path: ['Setting', item.type],
            basePath: `/cluster/settings?${QUERY_PARAM}=${item.type}-${item.name}&${RESOURCE_PARAM}=Setting`,
          })),
          isLoading: settingData.isValidating,
          total: settingData.list.count || 0,
          size: 1,
          setSize: () => {},
        },
      ],
    ],
    [
      servicesData,
      routesData,
      consumersData,
      sslData,
      gatewayData,
      logCollectionData,
      settingData,
    ]
  );

  const filterSearchList = useMemo(
    () =>
      searchList.filter(
        ([resourceType, { list: resourceList }]) =>
          getIsResourceShow(resourceType) && resourceList.length
      ),
    [searchList, getIsResourceShow]
  );

  useEffect(() => {
    onLoaded();
    setCurrentSelect([0, 0]);
    searchList.forEach((item) => {
      item[1].setSize(1);
    });
  }, [queryText]);

  useEffect(() => {
    setCurrentSelect([0, 0]);
  }, [currentResourceType]);

  const searchMap = useMemo(
    () => Object.fromEntries(searchList),
    [searchList]
  ) as Record<ResourceType, ResourceList[number][1]>;

  const isNoResult = useMemo(() => {
    const allResultLength = searchList?.flatMap((item) => item[1].list)?.length;

    return !isLoading && !allResultLength && queryText;
  }, [isLoading, searchList, queryText]);

  // Determine if the LoadMore button is displayed
  const isLoadMoreShow = (resourceType: ResourceType) =>
    searchMap[resourceType].total > searchMap[resourceType].list.length;

  // Determine if the loadMore button is loaded or not
  const isLoadMoreLoading = (resourceType: ResourceType) =>
    searchMap[resourceType].isLoading && searchMap[resourceType].size > 1;

  // Click LoadMore to load the next page of data
  const onLoadMore = (resourceType: ResourceType) => {
    if (searchMap[resourceType].isLoading) {
      return;
    }

    searchMap[resourceType].setSize((size) => size + 1);
  };

  const showResourceDetail = (resourceItem: ResourceItem) => {
    router.push(resourceItem.basePath);
    onClose();
    addRecord({
      name: resourceItem.name,
      resourceType: filterSearchList[currentSelect[0]][0],
      basePath: resourceItem.basePath,
    });
  };

  // As long as there is a resource that is loading, the search input box will be loaded
  useEffect(() => {
    let anyLoading = false;
    filterSearchList.forEach(([, { isLoading }]) => {
      anyLoading = isLoading;
    });

    if (anyLoading) {
      onLoading();
    } else {
      onLoaded();
    }
    return () => {
      onLoaded();
    };
  }, [filterSearchList]);

  const { isCursorShow, showCursor } = useKeyBoard({
    isComposing,
    onArrowDown: () => {
      let [selectedResource, selectedItem] = currentSelect;
      // When the last item of the current resource is selected
      if (
        selectedItem ===
        filterSearchList[selectedResource][1].list.length - 1
      ) {
        // If the current resource is already the last one, no action will be taken
        if (selectedResource === filterSearchList.length - 1) {
          return;
        }
        // Otherwise switch to the previous resource
        selectedResource += 1;
        selectedItem = 0;
      } else {
        selectedItem += 1;
        // Switch to the next item in the current resource
      }
      setCurrentSelect([selectedResource, selectedItem]);
    },
    onArrowUp: () => {
      let [selectedResource, selectedItem] = currentSelect;
      // When the first item of the current resource is selected
      if (selectedItem === 0) {
        // If the current resource is already the first one, no action will be taken
        if (selectedResource === 0) {
          return;
        }
        // Otherwise switch to the previous resource
        selectedResource -= 1;
        selectedItem = filterSearchList[selectedResource][1].list.length - 1;
      } else {
        // Switch to the previous item in the current resource
        selectedItem -= 1;
      }
      setCurrentSelect([selectedResource, selectedItem]);
    },
    onEnter: () => {
      const [selectedResource, selectedItem] = currentSelect;
      showResourceDetail(
        filterSearchList[selectedResource][1].list[selectedItem]
      );
    },
  });

  return (
    <Box onMouseMove={showCursor}>
      {isNoResult ? (
        <NoSearchResult queryText={queryText} />
      ) : (
        <>
          {filterSearchList.map(
            ([resourceType, { list: resourceList }], resourceIndex) => (
              <Box mt="24px" key={resourceType}>
                {/* resource type */}
                <Box mb="12px">
                  <Text fontSize="sm">{resourceType}</Text>
                </Box>
                {resourceList.map((item, index) => {
                  const isSelected = getIsSelected(resourceIndex, index);
                  return (
                    <SearchItem
                      direction="column"
                      isSelected={isSelected}
                      isCursorShow={isCursorShow}
                      key={item.basePath}
                      onClick={() => {
                        showResourceDetail(item);
                      }}
                      onMouseEnter={() => {
                        if (isCursorShow) {
                          setCurrentSelect([resourceIndex, index]);
                        }
                      }}
                    >
                      <Flex justify="space-between" align="center">
                        {/* resource name */}
                        <Highlight query={queryText}>{item.name}</Highlight>

                        {/* resource path */}
                        <Flex align="center">
                          <Text
                            as="span"
                            ml="4px"
                            fontSize="12px"
                            color="A7Gray.400"
                            maxW="240px"
                            noOfLines={1}
                            wordBreak="break-all"
                          >
                            {item.path.join(' > ')}
                          </Text>

                          {/* enter icon */}
                          {isSelected && (
                            <Flex
                              align="center"
                              justify="center"
                              w="32px"
                              h="32px"
                              ml="8px"
                            >
                              <Image src="/icons/enter.svg" w="16px" h="16px" />
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                      {/* extra content */}
                      {item?.extra}
                      {/* resource description */}
                      {item?.desc && (
                        <Highlight
                          query={queryText}
                          fontSize="14px"
                          color="A7Gray.500"
                          mt="8px"
                        >
                          {item.desc}
                        </Highlight>
                      )}
                    </SearchItem>
                  );
                })}
                {isLoadMoreShow(resourceType) && (
                  <Flex
                    align="center"
                    color="A7Gray.600"
                    fontSize="14px"
                    cursor="pointer"
                    onClick={() => onLoadMore(resourceType)}
                  >
                    <Box>View more</Box>
                    {isLoadMoreLoading(resourceType) ? (
                      <Spinner ml="4px" size="xs" />
                    ) : (
                      <Image
                        ml="4px"
                        w="14px"
                        h="14px"
                        src="/icons/arrow-down-line.svg"
                      />
                    )}
                  </Flex>
                )}
              </Box>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default memo(SearchList);
