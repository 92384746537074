import { memo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  Input,
  InputRightElement,
  Box,
  InputProps,
  Flex,
} from '@chakra-ui/react';
import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import { cryptoRandomString } from '@/helper/utils';
import A7Button from '../api7-button/index';

export type A7InputPasswordProps = Omit<InputProps, 'type'> & {
  register: UseFormRegisterReturn;
  showRandom?: boolean;
  showHideBtn?: boolean;
  defaultShow?: boolean;
  randomType?: 'password' | 'uuidv4';
  setRandom?: () => void;
  randomLength?: number;
};

const generateRandomString = {
  password: (length: number) =>
    cryptoRandomString({
      length,
      characters:
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+=][',
    }),
  uuidv4: () => uuidv4(),
};
const A7InputPassword: React.FC<A7InputPasswordProps> = ({
  register,
  showRandom = false,
  randomType = 'password',
  showHideBtn = true,
  defaultShow = false,
  setRandom,
  randomLength = 10,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [show, setShow] = useState(defaultShow);
  const { setValue, trigger } = useFormContext();

  const onClickRandom = () => {
    if (setRandom) {
      setRandom();
    } else {
      setValue(register.name, generateRandomString[randomType](randomLength));
      trigger(register.name);
    }
  };

  return (
    <Flex w="full">
      <InputGroup size="sm" flex={1}>
        <Input
          id={register.name}
          width="100%"
          type={show ? 'text' : 'password'}
          placeholder="Enter password"
          pr="32px"
          onFocusCapture={() => {
            setIsFocused(true);
          }}
          onBlurCapture={() => {
            setIsFocused(false);
          }}
          {...register}
          {...rest}
        />
        {showHideBtn && (
          <InputRightElement
            role="switch"
            w="32px"
            onClick={() => {
              setShow((prevShow) => !prevShow);
            }}
            // Adjust the z-index when the input box is focused to prevent users from being unable to click
            zIndex={isFocused ? '2' : 'auto'}
            height="100%"
          >
            <Box cursor="pointer">
              {show ? (
                <ViewOffIcon data-testid="view-off" />
              ) : (
                <ViewIcon data-testid="view" />
              )}
            </Box>
          </InputRightElement>
        )}
      </InputGroup>

      {showRandom && (
        <A7Button ml="8px" onClick={onClickRandom}>
          Random
        </A7Button>
      )}
    </Flex>
  );
};

export default memo(A7InputPassword);
