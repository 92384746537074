import React, { useContext, memo } from 'react';

import Joyride, { Step } from 'react-joyride';

import { TourContext } from 'hooks/useTour';

import { StepStyle } from '../constant';
import Tooltip from '../Tooltip';

const Task2: React.FC = () => {
  const { isStepRun, stepIndex, defaultHandleCallback } =
    useContext(TourContext);

  const steps: Step[] = [
    {
      target: 'body',
      content: "Now let's try to deploy an API Gateway Instance.",
      title: 'Welcome!',
      disableBeacon: true,
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
    {
      target: '#add-instance',
      content: 'Click this button to open the Add Instance guide.',
      title: 'Step 1',
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '#install-cloudCli',
      content:
        'Install Cloud CLI by copying and running the command in your terminal.',
      title: 'Step 2',
      spotlightClicks: true,
    },
    {
      target: '#configure-cloudCli',
      content: (
        <>
          <p style={{ marginBottom: '30px' }}>
            We need to configure a token to Cloud CLI so that it has permission
            to deploy API Gateway instances.
          </p>
          <p>
            {`So let's create a token and configure it to Cloud CLI by
            running the command.`}
          </p>
        </>
      ),
      title: 'Step 3',
      spotlightClicks: true,
    },
    {
      target: '#deploy-instance',
      content:
        'After you configure the Cloud CLI correctly, try to deploy an API Gateway instance to the desired infrastructure.',
      title: 'Step 4',
      spotlightClicks: true,
    },
    {
      target: 'body',
      content:
        "Thanks for your participation! Now you've known how to deploy an API Gateway instance.",
      title: 'Summary',
      disableBeacon: true,
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
    },
  ];

  return (
    <Joyride
      callback={defaultHandleCallback}
      run={isStepRun}
      steps={steps}
      stepIndex={stepIndex}
      continuous
      disableOverlayClose
      scrollOffset={150}
      styles={StepStyle}
      tooltipComponent={Tooltip}
    />
  );
};

export default memo(Task2);
