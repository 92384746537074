import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const Calendar: ComponentMultiStyleConfig = {
  parts: [
    'popContent',
    'popBody',
    'shortcutButtonGroup',
    'shortcutButton',
    'divider',
    'navigationContainer',
    'navigationButton',
    'navigationLabel',
    'calendarContainer',
    'dayLabelContainer',
    'dayLabel',
    'calendarMatrixContainer',
    'calendarMatrixDay',
    'todayButtonGroup',
    'todayButton',
  ],

  baseStyle: ({ colorScheme = 'black', theme }) => ({
    popContent: {
      _focus: {
        outline: 'none',
      },
      borderRadius: 0,
      height: '340px',
    },

    popBody: {
      shadow: 'lg',
      backgroundColor: '#F7F7F7',
      height: '340px',
    },

    shortcutButton: {
      fontWeight: 'normal',
      border: 'none',
      _focus: {
        outline: 'none',
      },
    },

    navigationContainer: {
      alignItems: 'baseline',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 1,
      marginTop: 1,
    },

    navigationButton: {
      background: 'none',
      _active: {
        shadow: 'none',
      },

      _focus: {
        outline: 'none',
      },
    },

    calendarContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 2,
    },

    dayLabelContainer: {
      display: 'flex',
    },

    dayLabel: {
      alignItems: 'center',
      color: theme.colors.gray[600],
      display: 'flex',
      fontSize: 'sm',
      height: 10,
      justifyContent: 'center',
      width: '100%',
    },

    calendarMatrixContainer: {
      display: 'flex',
    },

    calendarMatrixDay: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 10,
      width: '100%',

      p: {
        'user-select': 'none',
      },

      _hover: {
        backgroundColor: theme.colors.gray[500],
        color: 'white',
        cursor: 'pointer',
        shadow: 'md',
      },

      '&[data-in-range="false"]': {
        color: theme.colors.gray[400],
      },

      '&[data-selected="true"]': {
        backgroundColor: theme.colors[colorScheme],
        color: 'white',
        shadow: 'md',

        _hover: {
          backgroundColor: 'none',
        },

        '&[data-today="true"]': {
          color: 'white',
        },
      },

      _disabled: {
        cursor: 'not-allowed',
      },
    },
    todaySymbol: {
      width: '5px',
      height: '5px',
      backgroundColor: 'black',
      position: 'absolute',
      left: '50%',
      bottom: '10%',
      transform: 'translateX(-50%);',
    },
  }),

  defaultProps: {
    colorScheme: 'black',
  },
};

export default Calendar;
