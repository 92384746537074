import { memo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import A7CodeEditor from 'components/CodeEditor';

import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type CodeEditorProps = DefaultProps & {
  inline: boolean;
  defaultValue?: string;
};

const CodeEditor: React.FC<CodeEditorProps> = ({ children, ...rest }) => {
  const { control } = useFormContext();
  const { name, boxOption } = rest;
  const { restProps, formLabel, formHelperText } =
    useFormItem<CodeEditorProps>(rest);
  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <A7CodeEditor
            name={name}
            value={value}
            onChange={onChange}
            {...restProps}
          />
        )}
      />
      {formHelperText}
    </Box>
  );
};

export default memo(CodeEditor);
