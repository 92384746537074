import createInfiniteHook from '@/helper/utils/createInfiniteHook';
import useCluster from '@/hooks/useCluster';
import { LogCollectionResponse } from '@/types/LogCollection';
import { useMemo } from 'react';

const useLogCollectionInfinite = createInfiniteHook<LogCollectionResponse>(
  () => {
    const { clusterId, isClusterReady } = useCluster({
      dedupingInterval: 30000,
    });

    return useMemo(
      () => (isClusterReady ? `/clusters/${clusterId}/log_collections` : null),
      [isClusterReady]
    );
  }
);

export default useLogCollectionInfinite;
