import React, { useContext, memo } from 'react';

import { TourContext } from 'hooks/useTour';

import Task1 from './Tasks/Task1';
import Task2 from './Tasks/Task2';
import Task6 from './Tasks/Task6';
import Task5 from './Tasks/Task5';
import Task4 from './Tasks/Task4';
import Task3 from './Tasks/Task3';

const TaskController: React.FC = () => {
  const { tourIsOpen, focusIndex } = useContext(TourContext);

  return tourIsOpen ? (
    <>
      {focusIndex === 1 && <Task1 />}
      {focusIndex === 2 && <Task2 />}
      {focusIndex === 6 && <Task6 />}
      {focusIndex === 5 && <Task5 />}
      {focusIndex === 4 && <Task4 />}
      {focusIndex === 3 && <Task3 />}
    </>
  ) : null;
};

export default memo(TaskController);
