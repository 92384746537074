import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import route from 'helper/route';
import useRouterChange from '@/hooks/useSafePush';

const useMenuItem = () => {
  const router = useRouter();

  const { safePush } = useRouterChange();
  const [menuList, setMenuList] = useState(
    route.map((item) => ({
      ...item,
      ...(item.child ? { expand: false } : {}),
    }))
  );

  const [isMenuSelected, setIsMenuSelected] = useState<string>();

  const [currentExpandMenuItem, setCurrentExpandMenuItem] = useState<string>();

  const setMenuItemCollapse = useCallback(
    (path: string, expand: boolean) => {
      setMenuList(
        menuList.map((item) => {
          if (item.path === path) {
            return {
              ...item,
              expand,
            };
          }
          return item;
        })
      );
    },
    [menuList]
  );

  const onMenuItemClick = useCallback(
    (item: typeof menuList[number]) => {
      if (item.child) {
        const isMenuItemExpand = menuList.find(
          (menuItem) => menuItem.path === item.path
        )?.expand;
        setMenuItemCollapse(item.path, !isMenuItemExpand);
        setCurrentExpandMenuItem(
          currentExpandMenuItem === item.name ? undefined : item.name
        );
      } else {
        safePush(item.path);
        setCurrentExpandMenuItem(undefined);
      }
    },
    [menuList, currentExpandMenuItem, safePush]
  );

  useEffect(() => {
    const path = `/${router.pathname.split('/')[1]}`;

    setIsMenuSelected(path);
    if (router.pathname.split('/').length > 2) {
      // get current resource type, like: api-management or cluster
      // Open the corresponding menu according to the different resource

      setMenuItemCollapse(path, true);
    }
  }, [router.pathname]);

  return {
    menuList,
    onMenuItemClick,
    isMenuSelected,
    currentExpandMenuItem,
    setCurrentExpandMenuItem,
  };
};
export default useMenuItem;
