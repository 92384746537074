import { Box } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import A7LabelCreate from '../api7-label-create';
import { CreateLabelProps } from '../api7-label-create/types';
import { DefaultProps } from './types';
import useFormItem from './useFormItem';

type LabelCreateProps = DefaultProps &
  Omit<CreateLabelProps, 'value' | 'onChange'>;

const LabelCreate: React.FC<LabelCreateProps> = ({ children, ...rest }) => {
  const { name, boxOption } = rest;
  const { control } = useFormContext();
  const { formField, restProps, formLabel, formHelperText } =
    useFormItem<LabelCreateProps>(rest);
  return (
    <Box mb="32px" {...boxOption}>
      {formLabel}
      <Controller
        {...formField}
        render={({ field: { onChange, value } }) => (
          <A7LabelCreate
            name={name}
            control={control}
            value={value?.map((item: string) => ({
              label: item,
              value: item,
            }))}
            onChange={(e: { label: string; value: string }[]) => {
              onChange(e.map((item) => item.value));
            }}
            {...restProps}
          />
        )}
      />
      {formHelperText}
    </Box>
  );
};

export default LabelCreate;
