import { Styles } from 'react-joyride';

export const StepStyle: Styles = {
  buttonNext: {
    backgroundColor: '#5E5CE6',
    borderRadius: '2px',
    width: '68px',
    height: '40px',
  },
  buttonBack: {
    borderRadius: '2px',
    width: '98px',
    height: '40px',
    backgroundColor: '#FFFFFF',
    color: '#2C2C2C',
    border: '1px solid #F0F0F0',
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  tooltipContent: {
    padding: '16px 0',
    fontSize: '16px',
    fontWeight: '500',
  },
  tooltipTitle: {
    fontWeight: '700',
    fontSize: '22px',
    color: '#232323',
  },
};
