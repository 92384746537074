import React, { useContext, memo } from 'react';

import Joyride, { CallBackProps, Step, ACTIONS } from 'react-joyride';
import { useRouter } from 'next/router';

import { TourContext } from 'hooks/useTour';
import { A7Code } from 'components/api7-components';

import { StepStyle } from '../constant';
import Tooltip from '../Tooltip';

const Task4: React.FC = () => {
  const {
    isStepRun,
    stepIndex,
    defaultHandleCallback,
    focusIndex,
    setIsStepRun,
    setStepIndex,
    formInstance,
  } = useContext(TourContext);
  const route = useRouter();

  const steps: Step[] = [
    {
      target: 'body',
      title: 'Welcome!',
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
      content: "After you create the Service, let's add an Route for it.",
    },
    {
      target: '#table-first-child', // Todo: select the app after create
      title: 'Step 1',
      content: 'Click on My HTTPBIN to enter its detail page.',
      spotlightClicks: true,
    },
    {
      target: '#create-api',
      title: 'Step 2',
      content: 'Click here to add a new Route.',
      spotlightClicks: true,
    },
    {
      target: '#api-create-form',
      title: 'Step 3',
      content: (
        <>
          <p style={{ marginBottom: '30px' }}>
            An Route also contains essential attributes like the path and
            accepted HTTP methods.
          </p>
          <p>
            Try to set the Route Name to <A7Code>get</A7Code>, set Path to{' '}
            <A7Code>/get</A7Code>, click on the{' '}
            <A7Code>Strip Service Path Prefix</A7Code> option, and set Method to
            <A7Code>GET</A7Code>.
          </p>
        </>
      ),
      spotlightClicks: true,
      placement: 'left',
      styles: {
        tooltip: {
          width: '500px',
        },
      },
    },
    {
      target: '#create-api-button',
      title: 'Step 4',
      content: 'Now you can click this button to create Route !',
      spotlightClicks: true,
    },
    {
      target: 'body',
      title: 'Summary',
      placement: 'center',
      styles: {
        tooltip: {
          width: '616px',
        },
      },
      content:
        "Thanks for your participation! Now you've known how to create an Route.",
    },
  ];

  const handleCallback = async (data: CallBackProps) => {
    const { action, lifecycle } = data;

    if (
      action === ACTIONS.PREV &&
      stepIndex === 2 &&
      focusIndex === 4 &&
      lifecycle === 'complete'
    ) {
      route.back();
      setStepIndex(1);
      setIsStepRun(false);
      return;
    }

    if (
      action === ACTIONS.PREV &&
      stepIndex === 3 &&
      focusIndex === 4 &&
      lifecycle === 'complete'
    ) {
      route.back();
      setStepIndex(2);
      setIsStepRun(false);
      return;
    }

    if (action === ACTIONS.NEXT && stepIndex === 3 && focusIndex === 4) {
      const result = await formInstance?.trigger();
      if (!result) {
        setIsStepRun(false);
        setStepIndex(3);
        setIsStepRun(true);
        return;
      }
    }
    defaultHandleCallback(data);
  };

  return (
    <Joyride
      callback={handleCallback}
      run={isStepRun}
      steps={steps}
      stepIndex={stepIndex}
      continuous
      scrollOffset={150}
      scrollToFirstStep
      disableOverlayClose
      styles={StepStyle}
      tooltipComponent={Tooltip}
    />
  );
};

export default memo(Task4);
