import axios from 'axios';

const fetchAll = <ListType extends any[]>({
  url,
}: {
  url: string;
}): Promise<{ list: ListType; count: number }> => {
  const DEFAULT_PAGE_SIZE = 10;
  return axios
    .get(`${url}?page=1&page_size=${DEFAULT_PAGE_SIZE}`)
    .then((data) => {
      const { payload } = data.data;
      if (payload.count > 10) {
        return axios
          .get(`${url}?page=1&page_size=${payload.count}`)
          .then((allData) => allData.data.payload);
      }
      return data.data.payload;
    });
};

export default fetchAll;
