import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

import customTheme from '@/styles/customTheme';

const toast = createStandaloneToast({ theme: customTheme });

// Tips: The toast text should ensure that all words are capitalized except for prepositions
// For example: "Create Canary Release Rule Successfully"

const toastCacheMap: { [key: string]: boolean } = {};

const isDuplicate = (title: string) => {
  if (!toastCacheMap[title]) {
    toastCacheMap[title] = true;
    const timeout = setTimeout(() => {
      delete toastCacheMap[title];
      clearTimeout(timeout);
    }, 300);
    return false;
  }
  return true;
};

const showToast = ({ ...rest }: UseToastOptions) => {
  if (!isDuplicate(rest.title as string)) {
    toast({
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
      variant: 'subtle',
      ...rest,
    });
  }
};

export default showToast;
