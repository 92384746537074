import { ModalProps } from '@chakra-ui/react';

import A7Modal from '../api7-modal';

export type A7DeleteModalProps = {
  isLoading: boolean;
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  onConfirm: () => void;
  deleteText?: string;
} & ModalProps;

const ConfirmModal: React.FC<A7DeleteModalProps> = ({
  isOpen,
  onClose,
  title = '',
  isLoading = false,
  onConfirm,
  children,
  deleteText = 'Delete',
  ...rest
}) => (
  <A7Modal
    isOpen={isOpen}
    onClose={onClose}
    title={title}
    loading={isLoading}
    onOk={onConfirm}
    okText={deleteText}
    okTheme="red"
    size="xl"
    {...rest}
  >
    {children}
  </A7Modal>
);

export default ConfirmModal;
