import { useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Divider,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  ModalProps,
  Flex,
  ModalCloseButton,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import A7Button, { A7ButtonProps } from '../api7-button';
import ModalBody from './ModalBody';

type A7ModalProps = {
  isOpen: boolean;
  title: string;
  okText?: string;
  okTheme?: A7ButtonProps['theme'];
  cancelText?: string;
  okDisabled?: boolean;
  onClose: () => void;
  onOk?: () => void;
  loading?: boolean;
  closeOnOverlayClick?: boolean;
  showCancel?: boolean;
  showAlert?: boolean;
  alertText?: string;
  showDivider?: boolean;
  showCloseIcon?: boolean;
  showFooter?: boolean;
} & ModalProps;

const A7Modal: React.FC<A7ModalProps> = ({
  isOpen,
  title = '',
  okText = 'OK',
  okTheme = 'dark',
  cancelText = 'Cancel',
  onClose,
  okDisabled = false,
  onOk,
  loading = false,
  showAlert = false,
  alertText = '',
  scrollBehavior = 'inside',
  showDivider = true,
  showCloseIcon = true,
  showCancel = true,
  showFooter = true,
  children,
  ...rest
}) => {
  useEffect(() => {
    // Prevent chakra modal and react-select from changing the right margin of the page at the same time
    document.body.style.marginRight = '0px';
  }, [isOpen]);
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick
      isCentered
      scrollBehavior={scrollBehavior}
      trapFocus={false}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent width="588px" borderRadius="4px" position="relative">
        <Flex align="center" justify="space-between" p="16px">
          <ModalHeader
            color="A7Gray.700"
            fontWeight="medium"
            fontSize="lg"
            flex="1"
            p="0"
          >
            {title}
          </ModalHeader>

          {showCloseIcon && (
            <ModalCloseButton
              className="chakra-modal__close-btn"
              onClick={onClose}
            />
          )}
        </Flex>

        {showDivider && <Divider />}
        {showAlert && (
          <motion.div
            animate={{ scale: [0, 1], y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Box>
              <Alert status="error" backgroundColor="A7Red.50">
                <AlertIcon boxSize="20px" />
                <AlertDescription color="A7Gray.700">
                  {alertText}
                </AlertDescription>
              </Alert>
            </Box>
          </motion.div>
        )}
        <ModalBody scrollBehavior={scrollBehavior} showFooter={showFooter}>
          {children}
        </ModalBody>

        {showDivider && showFooter && <Divider />}
        {showFooter && (
          <ModalFooter h="73px">
            {showCancel && (
              <A7Button
                onClick={onClose}
                size="md"
                data-cy="modal-cancel-button"
              >
                {cancelText}
              </A7Button>
            )}
            {onOk && (
              <A7Button
                ml="12px"
                onClick={onOk}
                theme={okTheme}
                size="md"
                isDisabled={okDisabled}
                isLoading={loading}
                isDebounce
                data-cy="modal-ok-button"
              >
                {okText}
              </A7Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default A7Modal;
