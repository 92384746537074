export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => num >= i.value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

export const filterInternalKey = (obj: { [x: string]: any }) => {
  if (Object.prototype.toString.call(obj) !== '[object Object]') {
    return {};
  }

  const filteredKeys = Object.keys(obj).filter(
    (item) => !(item as string).startsWith('_')
  );
  const filterData: { [x: string]: any } = {};
  filteredKeys.forEach((key) => {
    if (obj[key] === '') {
      return;
    }
    filterData[key] = obj[key];
  });

  return filterData;
};
