import React from 'react';

import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

type CompleteProps = {
  onCloseClick: () => void;
  onOpen: () => void;
};

const Complete: React.FC<CompleteProps> = ({ onCloseClick, onOpen }) => (
  <Box
    w="398px"
    h="319px"
    position="fixed"
    right="20px"
    bottom="70px"
    boxShadow="0px 4px 16px rgba(0, 0, 0, 0.12)"
    borderRadius="4px"
    overflow="hidden"
    zIndex={2000}
    bgColor="#ffffff"
  >
    <Flex
      alignItems="center"
      justify="space-between"
      w="full"
      h="48px"
      bgColor="A7Indigo.500"
      px="20px"
    >
      <Text fontWeight="700" color="#ffffff" fontSize="22px">
        Congratulations!
      </Text>
      <CloseIcon
        w="14px"
        h="14px"
        color="#ffffff"
        cursor="pointer"
        onClick={onCloseClick}
      />
    </Flex>
    <Flex mt="27px" direction="column" alignItems="center">
      <Image src="/icons/tour-complete.svg" />
      <Text
        mt="22px"
        w="313px"
        color="A7Gray.900"
        fontWeight="700"
        fontSize="18px"
        align="center"
      >
        You completed the product tour for API7 Cloud!
      </Text>
    </Flex>
    <Button
      size="sm"
      bgColor="#ffffff"
      _hover={{ bgColor: '#ffffff' }}
      _active={{ bgColor: '#ffffff' }}
      color="A7Gray.600"
      fontSize="14px"
      position="absolute"
      right="23px"
      bottom="11px"
      onClick={() => {
        onOpen();
      }}
    >
      Dismiss
    </Button>
  </Box>
);

export default Complete;
