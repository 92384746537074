import { editor } from 'monaco-editor';

export default {
  base: 'vs',
  inherit: true,
  rules: [
    {
      token: 'string.value.json',
      foreground: '000000',
    },
    {
      token: 'string.key.json',
      foreground: '000000',
    },
  ],
  colors: {
    'editor.foreground': '#000000',
  },
} as editor.IStandaloneThemeData;
