import React, { memo } from 'react';

import { Switch, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

export type A7SwitchProps = {
  name: string;
  describe?: string;
  enabledText?: string;
  disabledText?: string;
  defaultVale?: boolean;
};

const A7Switch: React.FC<A7SwitchProps> = ({
  name,
  describe = '',
  enabledText = 'enabled',
  disabledText = 'disabled',
  defaultVale = false,
}) => {
  const { control, watch } = useFormContext();
  const isCheckedWatch = watch(name);

  return (
    <>
      <Controller
        control={control}
        shouldUnregister
        defaultValue={defaultVale}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Switch
            height="20px"
            width="32px"
            name={name}
            colorScheme="A7Green"
            borderRadius="9999px"
            isChecked={value}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          />
        )}
      />

      <Text color="#969696" fontSize="10px" mt="8px">
        {`${describe} ${isCheckedWatch ? enabledText : disabledText}`}
      </Text>
    </>
  );
};

export default memo(A7Switch);
