import axios from 'axios';
import { mutate } from 'swr';

import router from 'next/router';
import { showToast } from './utils';
import { API_BASE_URL } from './config';

export const setupRequest = () => {
  axios.defaults.baseURL = API_BASE_URL;
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    (request) => request,
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        // when code equal 10 and error equal organization was deleted, it mean that organization has been removed.
        if (
          error.response.data?.status?.code === 10 &&
          error.response.data?.error === 'organization was deleted'
        ) {
          return Promise.reject(error.response.data.status);
        }

        // when the cluster is not ready, the route push to the overview page
        if (
          error.response.data?.status?.code === 7 &&
          error.response.data?.error === 'cluster not ready'
        ) {
          if (router.pathname !== '/overview') {
            router.push('/overview');
          }
          return Promise.reject(error.response.data.status);
        }

        switch (error.response.status) {
          case 401: {
            const currentUrl = window.location.href;
            window.location.href = `${API_BASE_URL}/user/login?redirect_uri=${currentUrl}`;
            break;
          }
          case 403: {
            if (
              error.response.data.error === 'unauthorized organization access'
            ) {
              showToast({
                title: 'Unauthorized Organization Access.',
                status: 'error',
              });
            } else {
              showToast({
                title: 'Permission Denied.',
                status: 'error',
              });
            }
            mutate('/user/me');
            break;
          }
          case 429: {
            showToast({
              title: 'Requests are too frequent, please try again later.',
              status: 'error',
            });
            break;
          }
          case 500: {
            showToast({
              title: 'System Error. Please Contact the Administrator.',
              status: 'error',
            });
            break;
          }
          default: {
            showToast({ title: error.response.data.error, status: 'error' });
          }
        }
      }
      return Promise.reject(error);
    }
  );
  return null;
};
