import { Box, BoxProps } from '@chakra-ui/react';

const KeyContainer: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Box
    borderRadius="6px"
    border="1px"
    borderColor="A7Gray.200"
    bg="white"
    fontWeight="700"
    fontSize="12px"
    px="4px"
    py="2px"
    {...rest}
  >
    {children}
  </Box>
);

export default KeyContainer;
