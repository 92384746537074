import _ from 'lodash';

const sortByField = (
  array: any[],
  field: string,
  direction: 'ASC' | 'DESC'
): any[] => {
  const factor: number = direction === 'DESC' ? -1 : 1;
  const clonedArr = _.cloneDeep(array);
  return clonedArr.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1 * factor;
    }
    if (a[field] > b[field]) {
      return 1 * factor;
    }
    return 0;
  });
};

export default sortByField;
