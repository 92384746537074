/* eslint no-bitwise: ["error", { "allow": ["<<", "|="] }] */
import { Tag, TagLabel, TagProps } from '@chakra-ui/react';

export const colorMap = {
  green: {
    bgColor: 'A7Green.100',
    color: 'A7Green.800',
  },
  red: {
    bgColor: 'A7Red.100',
    color: 'A7Red.800',
  },
  yellow: {
    bgColor: 'A7Yellow.100',
    color: 'A7Yellow.800',
  },
  orange: {
    bgColor: 'A7Orange.100',
    color: 'A7Orange.800',
  },
  gray: {
    bgColor: 'A7Gray.100',
    color: 'A7Gray.800',
  },
  grayBlod: {
    bgColor: 'A7Gray.300',
    color: 'A7Gray.800',
  },
  blue: {
    bgColor: 'A7Blue.100',
    color: 'A7Blue.800',
  },
  cyan: {
    bgColor: 'A7Cyan.100',
    color: 'A7Cyan.800',
  },
  indigo: {
    bgColor: 'A7Indigo.100',
    color: 'A7Indigo.800',
  },
  purple: {
    bgColor: 'A7Purple.100',
    color: 'A7Purple.800',
  },
  pink: {
    bgColor: 'A7Pink.100',
    color: 'A7Pink.800',
  },
  black: {
    bgColor: 'A7Black.100',
    color: 'A7White.100',
  },
} as const;

export type LabelColor = keyof typeof colorMap;

export type A7LabelProps = Omit<TagProps, 'color'> & {
  readonly color: LabelColor;
};

// No need to include black when generating random colors
const colorList = Object.keys(colorMap).filter(
  (color) => !['black', 'gray', 'grayBlod'].includes(color)
);

export const getRandomColor = (str: string) => {
  let hash = 0;
  let chr;
  if (str.length === 0) {
    return colorList[0];
  }
  for (let index = 0; index < str.length; index += 1) {
    chr = str.charCodeAt(index);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  const colorIndex = Math.abs(hash) % colorList.length || 0;
  // Color in the label Remove gray, which is duplicated with the background
  if (colorIndex === 4) {
    return colorList[5];
  }
  return colorList[colorIndex];
};

const A7Label: React.FC<A7LabelProps> = ({
  color,
  fontSize,
  children,
  ...rest
}) => {
  const style = colorMap[color];
  return (
    <Tag
      size="md"
      variant="solid"
      borderRadius="20px"
      bgColor={style.bgColor}
      fontSize={fontSize || '14px'}
      {...rest}
    >
      <TagLabel
        color={style.color}
        lineHeight="5"
        userSelect="all"
        className="A7Label"
      >
        {children}
      </TagLabel>
    </Tag>
  );
};

export default A7Label;
