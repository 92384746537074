/* eslint-disable vars-on-top */
/* eslint-disable react/jsx-props-no-spreading */
import { ChakraProvider } from '@chakra-ui/react';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider, Global, css } from '@emotion/react';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';

import { setupRequest } from 'helper/request';
import Layout from 'components/layout';
import '@fontsource/lexend/latin.css';
import 'inter-ui/inter.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'focus-visible/dist/focus-visible';
import createEmotionCache from 'styles/createEmotionCache';
import customTheme from 'styles/customTheme';
import useUser from '@/hooks/useUser';
import { INTERCOM_APP_ID } from '@/helper/config';

import { startWorker } from '../mock/mock';
import defaultSEOConfig from '../../next-seo.config';

import 'styles/globals.css';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  startWorker();
}

setupRequest();

const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) => {
  const { pathname } = useRouter();
  const isNoLoginRequired = [
    '/signup',
    '/reset-password',
    '/email-verify',
    '/email-unverify',
  ].includes(pathname);
  const { data } = useUser({
    disableReq: isNoLoginRequired,
  });

  useEffect(() => {
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/e1xdk00z'
    (function intercomMain() {
      const w = window as any;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        // eslint-disable-next-line no-var
        var i = function intercom1() {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        } as any;
        i.q = [];
        i.c = function intercom2(args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function intercom3() {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/e1xdk00z';
          const x = d.getElementsByTagName('script')[0];
          (x as any).parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();

    if (!isNoLoginRequired && data) {
      (window as any).intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_APP_ID,
        name: `${data?.first_name} ${data?.last_name}`,
        email: data?.email,
        created_at: data?.created_at,
      };
    } else {
      (window as any).intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_APP_ID,
      };
    }
  }, [data, INTERCOM_APP_ID, isNoLoginRequired]);

  return (
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={customTheme}>
        <Global styles={{ ...GlobalStyles }} />

        <Script
          id="google-analytics"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            // NOTE: Initialize the gtag function to avoid errors when calling
            window.gtag = function () {}
            setTimeout(() => {
              const ga = document.createElement('script');
              ga.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-G6RJG6WY9E');
              document.body.appendChild(ga);
              window.dataLayer = window.dataLayer || [];
              window.gtag = function () {window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-G6RJG6WY9E');
            }, 1000);
            `,
          }}
        />
        <Head>
          <title>API7 Cloud Console</title>
          <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
          />
        </Head>
        <DefaultSeo {...defaultSEOConfig} />
        {isNoLoginRequired ? (
          <Component {...pageProps} />
        ) : (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        )}
      </ChakraProvider>
    </CacheProvider>
  );
};

export default MyApp;
